//https://v4qyqfxxqauahcjiax7wfzzj4m0hnsjs.lambda-url.eu-west-2.on.aws
import {
    getDeployedEnv,
} from "../config/walletConfig";
export function getCurApiVer(): string {
    return "v1"
}
export function buildApiBaseUrl(version: string, endPoint?: string, options: { isMock: boolean } = { isMock: false }): string {
    let hostname = "https://v4qyqfxxqauahcjiax7wfzzj4m0hnsjs.lambda-url.eu-west-2.on.aws" //options.isMock ? import.meta.env.VITE_BASE_MOCK_ENDPOINT_URL : import.meta.env.VITE_BASE_ENDPOINT_URL;
    hostname =
        getDeployedEnv() === "TESTNET"
            ? `https://83fqkin2e9.execute-api.us-east-2.amazonaws.com/${version}/${endPoint || ""}`
            : `https://l9s0nukb8b.execute-api.us-east-2.amazonaws.com/${version}/${endPoint || ""}`;
    return `${hostname}`;
}
export function buildMockUrl(endPoint: string, id?: string): string {
    let url = `/${endPoint}`;
    if (id) {
        url = `/${endPoint}/${id}`;
    }
    return url;
}

export function buildBaseUrl(): string {
    const { protocol, host } = window.location;
    return `${protocol}//${host}`;
}

export function buildAppUrl(path: string): string {
    return `${buildBaseUrl()}${path}`;
}
