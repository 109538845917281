export const baseColors = {
    black: "#1B1C20",
    white: "#FFFFFF",
    grey: "#6C757D",
    aqua: "#00FFF4",
    blue: "#2A85FF",
    primary: '#00FFF4',
    success: '#05FF00',
    error: "#FF0000",
    accessible: "#FFC80A",
    positive: '#00F463',
    negative: '#FF6A55',
    even: '#FC9803'
}
export const gray = {
    g300: "#DEE2E6",
    g500: "#ADB5BD",
    g600: "#8A919E",
    g700: "#495057",
    g900: "#212529",
}
export const gradients = {
    buttonBlue: "linear-gradient(92.12deg, #00FFF4 4.49%, #2A85FF 108.15%)",
    inverseBubblegum: "linear-gradient(139.73deg, #F3EFFF 0%, #E5FDFF 100%)",
    cardHeader: "linear-gradient(111.68deg, #F2ECF2 0%, #E8F2F6 100%)",
    violet: "linear-gradient(180deg, #E2C9FB 0%, #CDB8FA 100%)",
}
export const pieChartColorPalette = {
    yellow: { h: 38, s: 100, l: 55 },
    purple: { h: 262, s: 75, l: 59 },
    blue: { h: 214, s: 100, l: 58 },
    aqua: { h: 177, s: 100, l: 50 },
    yellowGradient: "linear-gradient(104.57deg, #FFAA1B 22.03%, #DC9113 74.7%)",
    purpleGradient: "linear-gradient(193.35deg, #8247E5 12.88%, #5E1CCD 89.65%)",
    blueGradient: "linear-gradient(180deg, #2775C9 8.64%, #5F97D5 91.36%)",
    aquaGradient: "linear-gradient(249.78deg, #05BDBD 6.65%, #00FFFF 82.34%)",
}
export const lightColors = {
    background: '#F7F7F7',
    contentBackground: '#FFFFFF',
    border: "#CED4DA",
    buttonBorderDisabled: gray.g600,
    buttonBorder: "#CED4DA",
    textPrimary: '#1B1C20',
    textSecondary: "#6C757D",
    textDisabled: gray.g700,
    percentageRateChange: '#E9ECEF',
    dropdownBackground: gray.g300,

}
export const darkColors = {
    background: '#000',
    contentBackground: '#181B20',
    secondaryContentBackground:'#0F1114',
    border: '#343A40',
    buttonBorderDisabled: gray.g300,
    buttonBorder: '#4B4B4B',
    textPrimary: '#FFFFFF',
    textSecondary: "#6C757D",
    textDisabled: gray.g500,
    percentageRateChange: '#272B30',
    dropdownBackground: gray.g900,
};

export const inputColor = {
    error: '#DC2C2C',
    success: '#05AA48',
    default: '#404040',
}