export const DISCONNECT_WALLET = "DISCONNECT_WALLET"
export const CONNECT_WALLET = " CONNECT_WALLET"
export const WALLET_CONNECTED = "WALLET_CONNECTED"
export const REFRESH_WALLET_INFO = 'REFRESH_WALLET_INFO'
export const REFRESH_BALANCES = "REFRESH_BALANCES"
export const WALLET_DISCONNECTED = "WALLET_DISCONNECTED"
export const WALLET_NOTIFICATION_SHOWN = "WALLET_NOTIFICATION_SHOWN"
export const WALLET_ON_LOAD = "WALLET_ON_LOAD"
export const SET_LFI_PRICE = "SET_LFI_PRICE"
export const ADD_LFI_INJECTED = "ADD_LFI_INJECTED"
export const WALLET_TRANSFER = "WALLET_TRANSFER"
export const TRANSFER_BASE_TOKEN = "TRANSFER_BASE_TOKEN"


export const FORTMATIC_OPERATION = "FORTMATIC_OPERATION"

export const TOKEN_UPGRADE = "TOKEN_UPGRADE"
export const TOKEN_UPGRADE_APPROVE = "TOKEN_UPGRADE_APPROVE"
