import {
  DISCONNECT_WALLET,
  WALLET_CONNECTED,
  REFRESH_WALLET_INFO,
  WALLET_DISCONNECTED,
  WALLET_NOTIFICATION_SHOWN,
  SET_LFI_PRICE
} from "./actionTypes"

const initialState = {
  error: "",
  connected: false,
  notificationShown: false,
  lfiPrice: '0',
  walletBalanceInfo: {}
}

const wallet = (state = initialState, action) => {
  switch (action.type) {
    case DISCONNECT_WALLET:
      state = {
        ...state,
        connected: false,
        notificationShown: false,
        walletBalanceInfo: {}
      }
      break
    case REFRESH_WALLET_INFO:
      state = {
        ...state,
        walletBalanceInfo: action.payload.walletBalanceInfo
      }
      break
    case WALLET_CONNECTED:
      state = {
        ...state,
        connected: true,
        walletBalanceInfo: action.payload.walletBalanceInfo
      }
      break
    case WALLET_DISCONNECTED:
      state = {
        ...state,
        connected: false,
        notificationShown: false,
        walletBalanceInfo: {}
      }
      break
    case WALLET_NOTIFICATION_SHOWN:
      state = {
        ...state,
        notificationShown: true
      }
      break
    case SET_LFI_PRICE:
      state = {
        ...state,
        lfiPrice: action.payload.price
      }
      break
    default:
      state = { ...state }
  }
  return state
}

export default wallet
