import {
    fork,
    take,
    put,
    all,
    call
  } from "redux-saga/effects"
  
  import { getFarmItems } from '../../services/blockchainInterface'
  
  import { GET_FARM_DATA } from "./actionTypes";
  
  import { farmUpdateCallStatus } from "./actions";
  import ActivityStatus from "../../models/enums/ActivityStatus";
  
  function* FarmSaga() {
    yield all([
      fork(getFarmDataWatcher)
    ])
  }
  
  const createWatcher = (worker, type) => {
    return function* () {
      while (true) {
        const action = yield take(type)
        yield fork(worker, action)
      }
    }
  }
  
  function* getFarmDataWorker(payload) {
    try {
      yield put(farmUpdateCallStatus({
        currentCall: 'GET_FARM_DATA',
        callStatus: ActivityStatus.INITIATED
      }))
      let farmInfo = yield call(getFarmItems)
      
      if (farmInfo) {
        yield put(farmUpdateCallStatus({
          currentCall: 'GET_FARM_DATA',
          callStatus: ActivityStatus.COMPLETED,
          data: { farmInfo }
        }))
      }
      else {
        yield put(farmUpdateCallStatus({
          currentCall: 'GET_FARM_DATA',
          callStatus: ActivityStatus.FAILED
        }))
      }
    } catch (e) {
      yield put(farmUpdateCallStatus({
        currentCall: 'GET_FARM_DATA',
        callStatus: ActivityStatus.FAILED
      }))
    } finally {
      yield put(farmUpdateCallStatus({
        currentCall: 'GET_FARM_DATA',
        callStatus: ActivityStatus.NOT_INITIATED
      }))
    }
  }
  
  export const getFarmDataWatcher = createWatcher(
    getFarmDataWorker,
    GET_FARM_DATA
  )
  
 
  
  export default FarmSaga