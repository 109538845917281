import {
    SET_CONNECT_MODAL,
    SET_DISCONNECT_MODAL,
    SET_MAGIC_MODAL,
    SET_MAGIC_LOGIN,
    SET_TRANSACTION_MODAL,
    SET_WALLET_UNSUPPORTED_CHAIN,
    SET_WRONG_NETWORK_MODAL,
    DARK_MODE,
} from "./actionTypes"

export const setConnectModal = (
    payload
) => {
    return {
        type: SET_CONNECT_MODAL,
        payload,
    }
}

export const setDisconnectModal = (
    payload
) => {
    return {
        type: SET_DISCONNECT_MODAL,
        payload,
    }
}

export const setMagicModal = (
    payload
) => {
    return {
        type: SET_MAGIC_MODAL,
        payload,
    }
}

export const setMagicLogin = (
    payload
) => {
    return {
        type: SET_MAGIC_LOGIN,
        payload,
    }
}

export const setTransactionModal = (
    payload
) => {
    return {
        type: SET_TRANSACTION_MODAL,
        payload,
    }
}


export const setWalletUnsupportedChain = (
    payload
) => {
    return {
        type: SET_WALLET_UNSUPPORTED_CHAIN,
        payload,
    }
}

export const setWrongNetworkModal = (
    payload
) => {
    return {
        type: SET_WRONG_NETWORK_MODAL,
        payload,
    }
}

export const isDarkMode = (
    payload
) => {
    return {
        type: DARK_MODE,
        payload,
    }
}
