export const FARM_INVEST = 'FARM_INVEST'
export const FARM_APPROVE = 'FARM_APPROVE'
export const FARM_WITHDRAW = 'FARM_WITHDRAW'
export const FARM_WITHDRAW_AND_HARVEST = 'FARM_WITHDRAW_AND_HARVEST'
export const FARM_HARVEST = 'FARM_HARVEST'
export const FARM_HARVEST_ALL = 'FARM_HARVEST_ALL'

export const GET_FARM_DATA = "GET_FARM_DATA"
export const FARM_UPDATE_CALL_STATUS = "FARM_UPDATE_CALL_STATUS"

export const DEPRECATED_FARM_WITHDRAW = "DEPRECATED_FARM_WITHDRAW"
export const DEPRECATED_FARM_HARVEST = "DEPRECATED_FARM_HARVEST"
export const DEPRECATED_FARM_INVEST = "DEPRECATED_FARM_INVEST"
export const DEPRECATED_FARM_APPROVE = "DEPRECATED_FARM_APPROVE"