import { all } from "redux-saga/effects"

import WalletSaga from "./wallet/saga"
import PoolsSaga from "./pools/saga"
import FarmSaga from "./farms/saga"
import ModalsSaga from "./modals/saga"
import GovernanceSaga from "./governance/saga"

export default function* rootSaga() {
  yield all([
    //public
    WalletSaga(),
    PoolsSaga(),
    FarmSaga(),
    ModalsSaga(),
    GovernanceSaga()
  ])
}
