import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux"
import TxnModal from '../components/modals/transaction-modal';

export function ToastContainer() {
    const { transactions } = useSelector((state: any) => state.Pools)
    const { transactionModalOpen } = useSelector((state: any) => state.Modals)
    const [latestTxn, setLatestTxn] = useState({})

    useEffect(() => {
        if (transactions.length > 0) {
            setLatestTxn(transactions[transactions.length - 1])
        }
    }, [JSON.stringify(transactions)])
    return (
        latestTxn ? <Box>
            <TxnModal open={transactionModalOpen} transaction={latestTxn} />
        </Box> : <Box></Box>
    )
}

export default ToastContainer;