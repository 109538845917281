import { IWallet } from '../models/Interface'
import WalletConnection from '../models/enums/WalletConnections'

export const supportedWallets = () : IWallet[] => {
    return [
        {   
            key:0,
            name: "MetaMask",
            connection: WalletConnection.BROWSER_EXTENSION,
            iconPath: "/assets/wallet/MetaMask.png",
            img:'/img/wallets/metamask.svg',
            isMobile: true,
            isDesktop: true
        },
        {
            key:1,
            name: "Coinbase Wallet",
            connection: WalletConnection.BROWSER_EXTENSION,
            iconPath: "/assets/wallet/Coinbase.png",
            img:'/img/wallets/coinbase.svg',
            isMobile: true,
            isDesktop: false
        },
        {
            key:2,
            name: "Wallet Connect",
            connection: WalletConnection.WALLET_CONNECT,
            iconPath: "/assets/wallet/WalletConnect.png",
            img:'/img/wallets/wallet-connect.svg',
            isMobile: true,
            isDesktop: true
        },
        // {   
        //     key:3,
        //     name: "Magic Link",
        //     connection: WalletConnection.MAGIC_LINK,
        //     iconPath: "/assets/wallet/MagicLink.png",
        //     img:'/img/wallets/magic.svg',
        //     isMobile: true,
        //     isDesktop: true
        // },
        {   key:3,
            name: "Fortmatic",
            connection: WalletConnection.FORTMATIC,
            iconPath: "/assets/wallet/FortMatic.png",
            img: "/assets/wallet/FortMatic.png",
            isMobile: true,
            isDesktop: true
        }
    ]
}
