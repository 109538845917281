interface config {
    [key: string]: any
}

const poolConfig: config = {
    LFI: {
        name: "HBTS",
        ticker: "HBTS",
        img:"/img/coin/lfi.svg",
        color:"#00FFF4",
    },
    WBTC: {
        name: "Wrapped Bitcoin",
        ticker: "WBTC",
        img:"/img/coin/btc.svg",
        color:"#FFAA1B",
    },
    WETH: {
        name: "Wrapped Ethereum",
        ticker: "WETH",
        img:"/img/coin/eth.svg",
        color:"#8247E5",
    },
    USDC: {
        name: "USDC",
        ticker: "USDC",
        img:"/img/coin/usdc.svg",
        color:"#2A85FF",
    },
}

export default poolConfig
