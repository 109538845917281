import React, { useEffect } from "react";
import ReactGA from 'react-ga'

export default function IdoGameContainer(props: any) {
    ReactGA.pageview(window.location.pathname);

    useEffect(() => {
        window.location.replace('https://www.Housebets.io');
    }, []);

    return (
        <div style={{ height: '100vh' }}>
             
        </div>
    );
}
