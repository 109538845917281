import { Box } from '@mui/system';
import { Typo } from '../Typo'
import { baseColors } from '../../theme/colors'
import { Input } from '../Input'

interface ICustomInputProps {
    maxBtn?: boolean;
    placeHolder?: string;
    type: string;
    handleChange: (e: string) => void;
    maxInput?: string;
    value?: string;
}

function isValid(value: string | number): boolean {
    return ((value === '.') ||
        ((value != null) &&
        (value !== '') &&
        (value !== 'e') &&
        !isNaN(Number(value.toString()))));
}


export function CustomInput(props: ICustomInputProps) {
    const maxDisplay = props.maxBtn ? 'flex' : 'none'
    const placeHolder = props.placeHolder ? props.placeHolder : '0.00'
    const autoComplete = props.type === 'number' ? 'off' : 'on'

    function isValid(value: string | number): boolean {
        return ((value === '.') ||
            (value != null) &&
            (value !== '') &&
            (value !== '-') &&
            (value !== 'e') &&
            !isNaN(Number(value.toString())));
    }

    const maxStyle = {
        position: 'absolute',
        width: '47px',
        height: '21px',
        borderRadius: '15px',
        border: '1.5px solid ' + baseColors.aqua,
        margin: '12px 18px',
        right: '0',
        top:'0',
        display: maxDisplay
    } as const

    return (
        <div style={{position:'relative'}}>
            <Input type={props.type}
                complete={autoComplete}
                placeholder={placeHolder}
                value={props.value}
                onKeyPress={(e: any) => { if (props.type === 'number' && !isValid(e.key)) { e.preventDefault(); } }}
                handleChange={props.handleChange}
                inputProps={{ inputProps: { min: 0 } }}
            />
            <Box sx={maxStyle} onClick={() => { props.maxInput && props.handleChange(props.maxInput) }}>
                <Typo aqua sx={{ display:'relative', marginLeft:'6px' }}>Max</Typo>
            </Box>
        </div>
    );
}

export default CustomInput;
