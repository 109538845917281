import { combineReducers } from "redux"

import Wallet from "./wallet/reducer";
import Pools from "./pools/reducer"
import Modals from "./modals/reducer"
import Farms from "./farms/reducer"
import Governance from "./governance/reducer"
const rootReducer = combineReducers({
  Wallet,
  Pools,
  Modals,
  Farms,
  Governance
})

export default rootReducer
