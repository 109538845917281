
import { useSelector } from 'react-redux'
import { inputColor, lightColors, darkColors, gradients } from '../../theme/colors'
import OutlinedInput from '@mui/material/OutlinedInput';
import { Typo } from '../Typo';
import error from './icon/error.svg';
import success from './icon/success.svg';

export default function Input(props: any) {

    const { darkMode } = useSelector((state: any) => state.Modals)
    const bgColor = darkMode ? darkColors.background : lightColors.background
    const borderColor = props.error ? inputColor.error : (props.success ? inputColor.success : inputColor.default)
    const inputStyle = {
        borderRadius: '5px',
        padding: "1px",
        backgroundImage: "linear-gradient(to bottom, " + "#181B20" + " 100%, " + "#181B20" + " 100%), linear-gradient(92.12deg, " + borderColor + " 4.49%, " + borderColor + " 108.15%)",
        backgroundClip: "content-box, padding-box",
        width: '100%',
        height: '44px',
        fontStyle: 'Inter',
        fontWeight: '500',
        fontSize: '14px',
        color: darkMode ? darkColors.textPrimary : lightColors.textPrimary,
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        },
        "&.Mui-focused": {
            backgroundImage: "linear-gradient(to bottom, " + "#181B20" + " 100%, " + "#181B20" + " 100%), " + gradients.buttonBlue,
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
            }
        }
    } as const
    const labelStyle = {
        display: 'block',
        textTransform: 'uppercase',
        marginLeft: '1px',
        marginBottom: '5px',
    }
    const messageStyle = {
        display: 'block',
        marginLeft: '1px',
        marginTop: '5px',
        color: props.success ? inputColor.success : (props.error ? inputColor.error : inputColor.default)
    }
    const iconStyle = {
        height: '13px',
        width: '13px',
        marginBottom: '-2px',
        marginRight: '5px'
    }
    return (
        <div>
            <Typo legend sx={labelStyle}>{props.label}</Typo>
            <OutlinedInput
                id="outlined-adornment-amount"
                sx={{...inputStyle,...props.sx}}
                type={props.type}
                inputProps={props.inputProps}
                autoComplete={props.complete}
                placeholder={props.placeHolder}
                value={props.value}
                onKeyPress={props.onKeyPress}
                onChange={(e) => props.handleChange(e.target.value)}
            />
            <Typo sx={messageStyle}>
                {props.success && <img src={success} style={iconStyle} />}
                {props.error && <img src={error} style={iconStyle} />}
                {props.message}
            </Typo>
        </div>

    )
}