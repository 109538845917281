import {
  GOVERNANCE_UPDATE_CALL_STATUS,
  SET_LFI_PRICE_CHART
} from "./actionTypes"

import ActivityStatus from "../../models/enums/ActivityStatus"

const initialState = {
  currentCall: 'null',
  callStatus: ActivityStatus.NOT_INITIATED,
  LFIToken: {
    vLFIWalletBalance: 0,
    vLFIMyLiquidity: 0,
    allowance: 1,
    LFIwalletBalance: 0,
    pendingHarvest: 0,
    dailyLfiRewards: 0,
    APR: 0
  },
  depositTx: '',
  withdrawTx: '',
  approveTx: '',
  LFIPriceData: {
    day: [],
    week: [],
    month: [],
    year: []
  }
}

const governance = (state = initialState, action) => {
  switch (action.type) {
    case GOVERNANCE_UPDATE_CALL_STATUS:
      state = {
        ...state,
        currentCall: action.payload.callStatusInfo.currentCall,
        callStatus: action.payload.callStatusInfo.callStatus,
        ...action.payload.callStatusInfo.data
      }
      break
    case SET_LFI_PRICE_CHART:
      state = {
        ...state,
        LFIPriceData: action.payload,
      }
      break
    default:
      state = { ...state }
  }
  return state
}

export default governance
