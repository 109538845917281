export const contracts = {
    LFI: {
        137: '0x77d97db5615dfe8a2d16b38eaa3f8f34524a0a74',
        80001: '0x5f622E3588cFdBE3fB64e61CFc863d21A91b2d52',
        416: '',
        647: '0xbB83b5B6f9D0aCfb1bBC387C2F939815b695f2f6',
    },
    deprecatedLFI: {
        137: '0xCa7BF3C514d412AC12D10Eff302301A81153F557',
        80001: '0x2F8AE62d9b0ED1CD76bd90d348cB1CEF6C3C8a4e',
        416: '',
        647: '0x17cce3286aFd6C2504f96f028eFD59e2aC0D78cf',
    },
    VLFI: {
        137: "0x866C31767d6FeF1bbcD442E3270E6cB741b44a04", //'0xfc604b6fD73a1bc60d31be111F798dd0D4137812',
        80001: '0xD9d45ad6E38917855B71307a319A1481fcd3915B',
        416: '',
        647: '0x0d548Bc6dF07346aE2C4B5e79C4F3F5dfDA59371',
    },
    
    USDC: {
        137: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
        80001: '0xE8F6F19f030921860765975cf99bcF513832b285',
        416: '',
        647: '0xe108Cc37529dC3999C62adcce1f5ADEea7BA890A',
    },
    USDCHousePool: {
        137: '0x14e849B39CA7De7197763b6254EE57eDBE0F3375',
        80001: '0xF282517Ab8dDF4c265BD7866310C39DE7CE3AE8D',
        416: '',
        647: '0x8478853F2191dF682520A7e640d705A54c8B360b',
    },
    WBTC: {
        137: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
        80001: '0xB568bd9F4572cdb62099ab2e70a25277c5118b15',
        416: '',
        647: '0xd4c6d0a12270B70Ce2E0f87498a9decA0d3d6b42',
    },
    WBTCHousePool: {
        137: '0x1311DCADf3330dD0AEB4d03177F9568880Febb34',
        80001: '0xC23bbF65AffB065DAAE373ddD2ADe279535d67dc',
        416: '',
        647: '0x203418F05827337CdFD1bec7d91415B3951fD32B',
    },
    WETH: {
        137: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
        80001: '0xD92f1A998A1F76913d1Aad3923fDf9dFAD73F013',
        416: '',
        647: '0x88d3f3c750d605EbCAf9A04ab9523AF946574D90',
    },
    WETHHousePool: {
        137: '0x2Bb91032F277BDc0DA7De271Ba03B3341B73b4c1',
        80001: '0xfC5D92C36Ce850Bc00095033F856BB2D8DBf341B',
        416: '',
        647: '0xF3cc9f19C39D14f7C8B12b568f6D222Af31B668b',
    },
    farms: {
        137: '0x4175Acd3d7f128cF41d42826cCe2185A5aDe7C82',
        80001: '0xe93dcdEb968D5C51afEd2886BFF41D459C9Ea594',
        416: '',
        647: '0x481fAa5b37D57Cb894f4f088aF1423F115Caf2dc',
    },
    deprecatedLFIWETH: {
        137: '0x72CF5ee9ee918a529b25BBcB0372594008178535',
        80001: '0x3474F2c7bc480Cb3f3f88E888bEcD0C5cE6aA434',
        416: '',
        647: '',
    },
    LFIWETH: {
        137: '0xE3108CDCfb18E7B3e558b37bfD4473CBDE1Fd05c',
        80001: '0xcdeD33dCA02a60A2e7282E4212734F3E9131590D',
        416: '',
        647: '0x6c4C0BBCC3C37c738713d09785751b418A74b10e',
    },
    tokenUpgrade: {
        137: '0x6416Cb74d4e84433346bb1DB4b5a8B0bb40a2132',
        80001: '0x687215d916624ba3E2F189c545a63FcF258722F4',
        416: '',
        647: '0xEeC19A76cBc593EfBc8746eB65cb0d26f4AD6684',
    },
    dexRouter: {
        137: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff',
        80001: '0x8954afa98594b838bda56fe4c12a09d7739d179b',
        416: '',
        647: '0x77cA62F431241e4D3329a5FD74eD986Aaa60dc20',
    },
    sportsbook: {
        137: '0x58edA02E51dBBFD5190F779573BB3eFBD41AFc68',
        80001: '0x58edA02E51dBBFD5190F779573BB3eFBD41AFc68',
        416: '',
        647: '0x58edA02E51dBBFD5190F779573BB3eFBD41AFc68',
      }
}