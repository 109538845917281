import React, { useEffect, useState } from "react";
import ReactGA from 'react-ga'
import { setDisconnectModal } from "../store";
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from "../lunafi-uikit/components/Button";
import MagicDeposit from "../components/wallet/deposit";
import MagicWithdraw from "../components/wallet/withdraw";

export default function MagicWalletContainer(props: any) {
    ReactGA.pageview(window.location.pathname);
    const [isDeposit, setIsDeposit] = useState(true)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setDisconnectModal(false))
    }, [])
    return (
        <div>
            <Box sx={{ overflowX: "hidden", padding: { lg: '24px', md: '24px', sm: '24px', xs: '24px' } }}>
                <Box sx={{ display: 'inline-block' }}>
                    <Button onClick={() => setIsDeposit(!isDeposit)}>Deposit</Button>
                </Box>
                <Box sx={{ display: 'inline-block' }}>
                    <Button onClick={() => setIsDeposit(!isDeposit)}> Withdraw</Button>
                </Box>
                {isDeposit && <MagicDeposit />}
                {!isDeposit && <MagicWithdraw />}

            </Box >
        </div >
    );
}
