import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "../containers/toast-container";
import MagicLoginModal from "./modals/magic-login-modal";
import { LayoutContainer, LayoutWrapper } from "lunafi-ui";
import { FooterConfig } from "../config/footerConfig";
import { MenuLink } from "../config/routersConfig";
import { getToolBarName } from "../services/utils/commonUtils";
import { useLocation, useNavigate } from "react-router-dom";
import { addLfiInjected } from "../store";
import { getChainIds } from "../config/walletConfig";
import { switchToSelectedNetwork } from "../services/blockchainInterface";
import { connectWallet, disconnectWallet } from "../store/wallet/actions";
import {
	getCoingeckoForLunaFi,
	getCurrentChain,
} from "../services/blockchainInterface";
import { walletOptions } from "../config/walletConfig";
import { isMobileDevice } from "../services/utils/commonUtils";
import { IWallet } from "../models/Interface";
import WalletConnection from "../models/enums/WalletConnections";
import { useEffect, useState } from "react";
import { vevetApp } from "../helper/vevet";

interface INavigationProps {
	children?: React.ReactNode;
	isConnected: boolean;
	walletBalanceInfo: any;
	isModalOpen: boolean;
	switch: any;
}

export function DrawerNavigation(props: INavigationProps) {
	const [isLayoutReady, setIsLayoutReady] = useState(false);
	const [isContentReady, setIsContentReady] = useState(false);

	useEffect(() => {
		const promise = vevetApp.onPageLoaded();
		promise.then(() => setIsLayoutReady(true)).catch(() => undefined);
		return () => promise.cancel();
	}, []);

	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [currentPath, setCurrentPath] = React.useState(
		getToolBarName(location.pathname)
	);
	React.useEffect(() => {
		setCurrentPath(getToolBarName(location.pathname));
		let menuLink = MenuLink;
		menuLink.forEach((item) => {
			if (item.icon == currentPath) {
				item.isActive = true;
			} else {
				item.isActive = false;
			}
		});
	}, [location]);

	const [LFIData, setLFIData] = React.useState({
		current_price: 0,
		fully_diluted_valuation: 0,
		burnt_supply: 0,
		circulating_supply: 0,
		market_cap: 0,
	});
	React.useEffect(() => {
		getCoingeckoForLunaFi().then((data: any) => {
			if (data) {
				setLFIData({
					current_price: data.current_price,
					fully_diluted_valuation: data.fully_diluted_valuation,
					burnt_supply: 0,
					circulating_supply: data.circulating_supply,
					market_cap: data.market_cap,
				});
			}
		});
	}, []);

	const handleAnchorClick = (el: HTMLAnchorElement, evt: MouseEvent) => {
		if (!el.target) {
			evt.preventDefault();
			try {
				const currentURL = new URL(window.location.href);
				const url = new URL(el.href, window.location.href);
				if (currentURL.host === url.host) {
					navigate(url.pathname);
				} else {
					window.location.href = url.href;
				}
			} catch (e) { }
		}
	};

	const chainIdList = getChainIds().staticSupportedNetworks;
	const { walletBalanceInfo, connected } = useSelector(
		(state: any) => state.Wallet
	);
	const [networkKey, setNetworkKey] = React.useState(
		chainIdList.indexOf(getCurrentChain())
	);
	const [wrongNetwork, setWrongNetwork] = React.useState<false | any | {}>(
		false
	);
	React.useEffect(() => {
		if (connected) {
			setNetworkKey(chainIdList.indexOf(getCurrentChain()));
			if (
				getChainIds().supportedNetworks[0] === getCurrentChain() ||
				getCurrentChain() === null
			) {
				// if connected but network === null means its still loading
				setWrongNetwork(false);
			} else if (
				getChainIds().sxChainId === getCurrentChain() &&
				currentPath === "token-upgrade"
			) {
				// do nothing
			} else {
				setWrongNetwork(WrongNetworkModal);
			}
		} else {
			setNetworkKey(0);
			setWrongNetwork(false);
		}
	}, [getCurrentChain(), connected]);

	const WrongNetworkModal = {
		modal: {
			buttonName: "Switch Network",
			desc: "To use our blockchain features, you need to be on the Polygon network",
			onButtonClick: () => {
				swicthNetwork(0);
				setWrongNetwork({});
			},
			title: "Wrong network",
		},
	};
	const swicthNetwork = (key: any) => {
		if (key === 0) {
			switchToSelectedNetwork(chainIdList[0]);
			setNetworkKey(key);
		} else if (key === 1) {
			switchToSelectedNetwork(chainIdList[1]);
			setNetworkKey(key);
		} else {
			console.error("unknow network!");
		}
	};

	const swapItems = [
		{
			href: "https://quickswap.exchange/#/swap?outputCurrency=0x77d97db5615dfe8a2d16b38eaa3f8f34524a0a74&inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
			icon: "/img/general/quickswap.png",
			name: "Buy LFI",
		},
		{
			href: "https://quickswap.exchange/#/swap?outputCurrency=0x77d97db5615dfe8a2d16b38eaa3f8f34524a0a74&inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
			icon: "/img/general/quickswap.png",
			name: "Buy USDC",
		},
		{
			href: "https://quickswap.exchange/#/swap?outputCurrency=0x77d97db5615dfe8a2d16b38eaa3f8f34524a0a74&inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
			icon: "/img/general/quickswap.png",
			name: "Buy BTC",
		},
		{
			href: "https://quickswap.exchange/#/swap?outputCurrency=0x77d97db5615dfe8a2d16b38eaa3f8f34524a0a74&inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
			icon: "/img/general/quickswap.png",
			name: "Buy ETH",
		},
	];

	const [walletKey, setWalletKey] = React.useState(-1);
	React.useEffect(() => {
		if (walletKey !== -1) {
			const wallet = connectOptions.find((wallet) => wallet.key === walletKey);
			dispatch(
				connectWallet({ walletConnection: wallet!.connection, creds: "" })
			);
		}
	}, [walletKey]);
	React.useEffect(() => {
		if (!connected || walletKey !== -1) setWalletKey(-1);
	}, [connected]);
	let connectOptions: IWallet[] = [];
	if (isMobileDevice()) {
		connectOptions = walletOptions.filter((wallet) => wallet.isMobile);
	} else {
		connectOptions = walletOptions.filter((wallet) => wallet.isDesktop);
	}
	if (!window.ethereum) {
		connectOptions = connectOptions.filter(
			(wallet) =>
				wallet.connection === WalletConnection.WALLET_CONNECT ||
				wallet.connection === WalletConnection.FORTMATIC
		);
	}

	const [menuLink, setMenuLink] = useState(MenuLink)
	useEffect(() => {
		setMenuLink(menuLink.map((item) => {
			if (item.href === window.location.pathname) {
				item.isActive = true
				return item;
			} else if (item.href !== "/" && window.location.pathname.includes(item.href)) {
				item.isActive = true
				return item;
			} else {
				item.isActive = false
				return item;
			}
		}))
	}, [window.location.pathname])
	return (
		<div>
			<MagicLoginModal />
			<ToastContainer />
			<LayoutContainer
				onAnchorClick={handleAnchorClick}
				asideChildren={<></>}
				footer={FooterConfig}
				menuLinks={menuLink}
				pageTitle={currentPath}
				storeProps={{
					addToMetamaskCallback: () => dispatch(addLfiInjected()),
					authorized: connected,
					buy: { items: swapItems },
					connectNetwork: {
						items: [
							{
								color: "#627EEA",
								icon: "/img/network/eth_icon.svg",
								key: 0,
								name: "Ethereum",
								preview: "/img/network/eth_preview.svg",
								isDisabled: false,
							},
							/*{
								color: "#627eea",
								icon: "/img/network/sx_icon.png",
								key: 1,
								name: "SX",
								preview: "/img/network/sx_preview.svg",
								isDisabled: currentPath !== "token-upgrade",
							},*/
						],
						onSelect: (network: any) => {
							network && swicthNetwork(network.key);
						},
						selectedKey: networkKey === -1 ? 0 : networkKey,
						wrongNetwork: wrongNetwork,
					},
					connectWallet: {
						selectedKey: walletKey,
						onSelect: async (wallet: any) => {
							if (wallet.key !== walletKey) {
								setWalletKey(wallet.key);
							}
						},
						wallets: connectOptions,
					},
					lfi: {
						explorerHref:
							"https://polygonscan.com/token/0x77d97db5615dfe8a2d16b38eaa3f8f34524a0a74",
						info: [
							{
								key: 0,
								name: "Fully diluted supply",
								value: Math.round(LFIData.fully_diluted_valuation * 100) / 100,
								numberFormat: {
									prefix: "$",
									rightFixed: 0,
								},
							},
							{
								key: 1,
								name: "Burnt Supply",
								value: Math.round(LFIData.burnt_supply * 100) / 100,
								numberFormat: {
									rightFixed: 0,
								},
							},
							{
								key: 2,
								name: "Circulating Supply",
								value: Math.round(LFIData.circulating_supply * 100) / 100,
								numberFormat: {
									rightFixed: 0,
								},
							},
							{
								key: 3,
								name: "Market Cap",
								value: Math.round(LFIData.market_cap * 100) / 100,
								numberFormat: {
									prefix: "$",
									rightFixed: 0,
								},
							},
						],
						price: Math.round(LFIData.current_price * 1000) / 1000,
					},
					links: {
						privacy: "https://housebets.com/terms",
						terms: "https://housebets.io/terms",
					},
					user: {
						address: walletBalanceInfo.address,
						disconnectCallback: () => dispatch(disconnectWallet()),
						explorerHref: `https://polygonscan.com/address/${walletBalanceInfo.address}`,
						network: "MetaMask",
					},
					// upgradeTokenHref: '/token-upgrade',
					states: {
						isLayoutReady,
						onLayoutReady() {
							setIsContentReady(true);
						},
						isContentReady,
					},
				}}
			>
				{props.children}
			</LayoutContainer>
		</div>
	);
}

DrawerNavigation.propTypes = {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window: PropTypes.func,
};

export default DrawerNavigation;
