import { useDispatch, useSelector } from 'react-redux';
import { RegularBox } from '../../lunafi-uikit/components/Box';
import { Button } from '../../lunafi-uikit/components/Button';
import { Typo } from '../../lunafi-uikit/components/Typo';
import { submitTransaction } from '../../store';
import { CustomInput } from '../../lunafi-uikit/components/Input';
import { useState } from 'react';
import { MenuItem, Select } from '@mui/material';
import { TRANSFER_BASE_TOKEN, WALLET_TRANSFER } from '../../store/wallet/actionTypes';

function MagicWithdraw() {
    const [address, setAddress] = useState('')
    const [token, setToken] = useState('LFI');
    const [amount, setAmount] = useState('')

    const dispatch = useDispatch()
    const dispatchSubmitTransaction = (e: any) => {
        if(token === 'MATIC') {
            dispatch(submitTransaction({ currentCall: TRANSFER_BASE_TOKEN, amount: amount, destination: address }))
        } else {
            dispatch(submitTransaction({ currentCall: WALLET_TRANSFER, poolType: token, amount: amount, destination: address }))
        }
    }

    const style = {
        width: '100%',
        display: 'block',
        textAlign: 'center',
        padding: '10px',
        height: '100%'
    }

    const handleChange = (e: string) => {
        setAddress(e)
    }

    const handleAmountChange = (e: string) => {
        setAmount(e)
    }

    const handleTokenChange = (event: any) => {
        setToken(event.target.value);
    };

    return (
        <RegularBox sx={style}>
            <Typo>
                This is a withdrawal page. put some information about the various places a user can withdraw here.
            </Typo>
            <Typo>be careful about the withdrawal address please</Typo>
            <div style={{ height: 'fit-content', margin: 'auto', width: '25%', marginTop: '0px', display: 'flex', justifyContent:'space-between' }}>
                <Select
                    labelId="simple-select-label"
                    id="simple-select"
                    value={token}
                    label="Token"
                    onChange={handleTokenChange}
                    sx={{width:'100px', color:'white'}}
                >
                    <MenuItem value={'WETH'}>WETH</MenuItem>
                    <MenuItem value={'WBTC'}>WBTC</MenuItem>
                    <MenuItem value={'USDC'}>USDC</MenuItem>
                    <MenuItem value={'LFI'}>LFI</MenuItem>
                    <MenuItem value={'MATIC'}>MATIC</MenuItem>
                </Select>
                <div style={{display:'flex'}}>
                    <Typo sx={{margin:'auto 10px auto auto'}}>Amount:</Typo>
                    <CustomInput
                    handleChange={handleAmountChange}
                    placeHolder=''
                    type='number'
                />
                </div>
                <div style={{display:'flex'}}>
                    <Typo sx={{margin:'auto 10px auto auto'}}>To:</Typo>
                    <CustomInput
                    handleChange={handleChange}
                    placeHolder=''
                    type='email'
                />
                </div>
            </div>
            <div style={{ height: 'fit-content', margin: 'auto', width: '25%', marginTop: '0px', display: 'flex' }}>
                <Button onClick={(e: any) => {dispatchSubmitTransaction(e)}}>Transfer</Button>
            </div>

        </RegularBox>
    );

}
export default MagicWithdraw;