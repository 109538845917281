import {
  UPDATE_CALL_STATUS,
  REFRESH_POOL_INFO,
  ADD_NEW_TRANSACTION,
  UPDATE_TRANSACTION_STATUS,
  CLEAR_CHART_DATA,
  USDC_GRAPH,
  GET_GLOBAL_VALUES,
  SET_GLOBAL_VALUES,
  GET_VOLUME,
  SET_VOLUME,
  GET_SPORTSBOOK_FUNDS,
  SET_SPORTSBOOK_FUNDS,
} from "./actionTypes"

import ActivityStatus from "../../models/enums/ActivityStatus"

const defaultData = [{ tooltipKey: "00:00", label: "00", value: 0, tValue: "0" }, { tooltipKey: "00:00", label: "01", value: 0, tValue: "0" }]
const defaultGraphInfo = {
  USDC: { Day: defaultData, "Week": defaultData, "Month": defaultData, "Year": defaultData },
  // LFI: { Day: defaultData, "Week": defaultData, "Month": defaultData, "Year": defaultData },
  // WBTC: { Day: defaultData, "Week": defaultData, "Month": defaultData, "Year": defaultData },
  WETH: { Day: defaultData, "Week": defaultData, "Month": defaultData, "Year": defaultData },
}
const defaultCoinInfo = { USDC: defaultData, LFI: defaultData }
// const defaultCoinInfo = { USDC: defaultData, LFI: defaultData, WBTC: defaultData, WETH: defaultData }
const defaultGlobalInfoData = {
  "TVL": "0.0",
  "24ChangePercent": 0.0,
  "Liquidity": "0.0",
  "PendingStakes": 0,
  "EV": 0,
  "ME": 0,
  "Earnings7Day": 0,
  "Earnings30Day": 0,
  "Rewards": "0.0",
  "APR": "50.36",
  "TokenPrice": 0.0,
  "LPPrice": 0.0,
  "Dollar": {
    "TVL": 0,
    "Liquidity": 0,
    "PendingStakes": 0,
    "EV": "0",
    "ME": "0"
  }
}
const defaultPoolInfo = {

  USDC: defaultGlobalInfoData,
  LFI: defaultGlobalInfoData,
  WBTC: defaultGlobalInfoData,
  WETH: defaultGlobalInfoData,
}

const defaultGlobalValues = {
  TVL: "0.00",
  Liquidity: "0.00",
  PendingStakes: "0.00",
  EV: "0.0",
  ME: "0.0",
  Rewards: "0.00",
  APR: 0,
  Earnings7Day: 0,
  Earnings30Day: 0,
  TokenPrice: 0.00,
  Dollar: {
    Liquidity: 0,
    TVL: 0,
    PendingStakes: 0
  }
}

const defaultVolume = {
  "volume": [],
  "24hour": "0",
  "total": 0
}

const defaultSportsbookFunds = {
  total: 0,
  Dollar: {
    total: 0
  }
}

const initialState = {
  transactions: [],
  txnIndex: 0,
  currentCall: 'null',
  callStatus: ActivityStatus.NOT_INITIATED,
  notificationShown: false,
  graph: {
    graphTVLData: defaultGraphInfo,
    graphMEData: defaultGraphInfo,
    graphPNL7DaysData: defaultCoinInfo,
    graphPNL30DaysData: defaultCoinInfo,
    graphLiquidityData: defaultGraphInfo,
    graphPendingStakesData: defaultGraphInfo,
  },
  poolInfo: {
    80001: defaultPoolInfo,
  },
  globalValues: {
    globalValues: {
      USDC: defaultGlobalValues,
      LFI: defaultGlobalValues,
      WBTC: defaultGlobalValues,
      WETH: defaultGlobalValues,
    }
  },
  depositTx: '',
  withdrawTx: '',
  approveTx: '',
  volume: {
    "WETH": defaultVolume,
    "WBTC": defaultVolume,
    "LFI": defaultVolume,
    "USDC": defaultVolume
  },
  sportsbookFunds: {
    "WETH": defaultSportsbookFunds,
    "WBTC": defaultSportsbookFunds,
    "LFI": defaultSportsbookFunds,
    "USDC": defaultSportsbookFunds
  }
}

const pools = (state = initialState, action) => {
  switch (action.type) {
    case USDC_GRAPH:
      state = {
        ...state,
        graph: {
          ...state.graph,
          [action.payload.data.graphIndicator]: {
            ...state.graph[action.payload.data.graphIndicator],
            [action.payload.data.graphToken]: action.payload.data.graphDataPoints
          }
        }
      }
      break
    case SET_GLOBAL_VALUES:
      state = {
        ...state,
        globalValues: action.payload.data.globalValues
      }
      break
    case SET_VOLUME:
      state = {
        ...state,
        volume: action.payload.data.volume
      }
      break
    case UPDATE_CALL_STATUS:
      state = {
        ...state,
        currentCall: action.payload.callStatusInfo.currentCall,
        callStatus: action.payload.callStatusInfo.callStatus,
        ...action.payload.callStatusInfo.data
      }
      break
    case SET_SPORTSBOOK_FUNDS:
      state = {
        ...state,
        sportsbookFunds: action.payload?.data?.sportsbookFunds
      }
      break
    case REFRESH_POOL_INFO:
      // console.log('refresh pool info called with action.payload', action.payload)
      state = {
        ...state,
        ...action.payload.data
      }
      break
    case ADD_NEW_TRANSACTION:
      state = {
        ...state,
        txnIndex: state.txnIndex + 1,
        transactions: state.transactions.concat(action.payload.txnInfo)
      }
      break
    case UPDATE_TRANSACTION_STATUS:
      const tempArr = state.transactions
      tempArr[action.payload.txnInfo.id] = action.payload.txnInfo
      state = {
        ...state,
        transactions: tempArr
      }
      break
    case CLEAR_CHART_DATA:
      state = {
        ...state,
        chartData: []
      }
      break
    default:
      state = { ...state }
  }
  return state
}

export default pools
