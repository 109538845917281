import { fork, take, put, all } from "redux-saga/effects"
import { setWrongNetworkModal } from "../actions"
import { SET_WALLET_UNSUPPORTED_CHAIN } from './actionTypes'

function* ModalsSaga() {
    yield all([
        fork(getUnsupportedChainWatcher),
    ])
}

const createWatcher = (worker, type) => {
    return function* () {
        while (true) {
            const action = yield take(type)
            yield fork(worker, action)
        }
    }
}

export const getUnsupportedChainWatcher = createWatcher(
    getUnsupportedChainWorker,
    SET_WALLET_UNSUPPORTED_CHAIN
)

function* getUnsupportedChainWorker(payload) {
    if (payload.payload) { 
        yield put(setWrongNetworkModal(true)) 
    }
}

export default ModalsSaga