export const GET_LIQUIDITY = "GET_LIQUIDITY"
export const GET_ALLOWANCE = "GET_ALLOWANCE"
export const REFRESH_POOL_INFO = 'REFRESH_POOL_INFO'

export const SUBMIT_TRANSACTION = "SUBMIT_TRANSACTION"

export const UPDATE_CALL_STATUS = "UPDATE_CALL_STATUS"
export const ADD_NEW_TRANSACTION = "ADD_NEW_TRANSACTION"
export const UPDATE_TRANSACTION_STATUS = "UPDATE_TRANSACTION_STATUS"

export const POOL_INVEST = 'POOL_INVEST'
export const POOL_APPROVE = 'POOL_APPROVE'
export const POOL_WITHDRAW = 'POOL_WITHDRAW'
export const POOL_HARVEST = 'POOL_HARVEST'
export const POOL_WITHDRAW_MAX = 'POOL_WITHDRAW_MAX'

export const GET_CHART_DATA = 'GET_CHART_DATA'
export const CLEAR_CHART_DATA = 'CLEAR_CHART_DATA'

export const SET_POOL = 'SET_POOL'
export const SET_GRAPH = 'SET_GRAPH'

// Get USDC Graph
export const USDC_GRAPH = 'USDC_GRAPH'

// Get Global Values
export const GET_GLOBAL_VALUES = 'GET_GLOBAL_VALUES'

// Set Global Values
export const SET_GLOBAL_VALUES = 'SET_GLOBAL_VALUES'

// Get Volume
export const GET_VOLUME = 'GET_VOLUME'

// Set Volume
export const SET_VOLUME = 'SET_VOLUME'

// Get sportsbook funds
export const GET_SPORTSBOOK_FUNDS = 'GET_SPORTSBOOK_FUNDS'

// Set sportsbook funds
export const SET_SPORTSBOOK_FUNDS = 'SET_SPORTSBOOK_FUNDS'
