import {
    SET_CONNECT_MODAL,
    SET_DISCONNECT_MODAL,
    SET_MAGIC_MODAL,
    SET_MAGIC_LOGIN,
    SET_TRANSACTION_MODAL,
    SET_WALLET_UNSUPPORTED_CHAIN,
    SET_WRONG_NETWORK_MODAL,
    DARK_MODE,
} from "./actionTypes"

const initialState = {
    disconnectModalOpen: false,
    connectModalOpen: false,
    magicModalOpen: false,
    magicLogin: '',
    transactionModalOpen: false,
    walletUnsupportedChain: false,
    wrongNetworkModalOpen: false,
    darkMode: true,
}

const modals = (state = initialState, action) => {
    switch (action.type) {
        case SET_CONNECT_MODAL:
            state = {
                ...state,
                connectModalOpen: action.payload,
            }
            break
        case SET_DISCONNECT_MODAL:
            state = {
                ...state,
                disconnectModalOpen: action.payload,
            }
            break
        case SET_MAGIC_MODAL:
            state = {
                ...state,
                magicModalOpen: action.payload,
            }
            break
        case SET_MAGIC_LOGIN:
            state = {
                ...state,
                magicLogin: action.payload,
            }
            break
        case SET_TRANSACTION_MODAL:
            state = {
                ...state,
                transactionModalOpen: action.payload,
            }
            break
        case SET_WALLET_UNSUPPORTED_CHAIN:
            state = {
                ...state,
                walletUnsupportedChain: action.payload,
            }
            break
        case SET_WRONG_NETWORK_MODAL:
            state = {
                ...state,
                wrongNetworkModalOpen: action.payload,
            }
            break
        case DARK_MODE:
            state = {
                ...state,
                darkMode: action.payload,
            }
            break
        default:
            state = { ...state }
    }
    return state
}

export default modals
