import {
  GET_ALLOWANCE,
  GET_LIQUIDITY,
  REFRESH_POOL_INFO,
  UPDATE_CALL_STATUS,
  ADD_NEW_TRANSACTION,
  UPDATE_TRANSACTION_STATUS,
  SUBMIT_TRANSACTION,
  SET_GRAPH,
  USDC_GRAPH,
  GET_GLOBAL_VALUES,
  SET_GLOBAL_VALUES,
  GET_VOLUME,
  SET_VOLUME,
  CLEAR_CHART_DATA,
  SET_POOL,
  GET_SPORTSBOOK_FUNDS,
  SET_SPORTSBOOK_FUNDS,
} from "./actionTypes"

export const getAllowance = (
  payload
) => {
  return {
    type: GET_ALLOWANCE,
    payload,
  }
}

export const getLiquidity = (
  payload
) => {
  return {
    type: GET_LIQUIDITY,
    payload,
  }
}

export const refreshPoolInfo = (data) => {
  return {
    type: REFRESH_POOL_INFO,
    payload: {
      data
    }
  }
}

export const updateCallStatus = (callStatusInfo) => {
  return {
    type: UPDATE_CALL_STATUS,
    payload: {
      callStatusInfo
    },
  }
}

export const addNewTransaction = (txnInfo) => {
  return {
    type: ADD_NEW_TRANSACTION,
    payload: {
      txnInfo
    },
  }
}

export const updateTransactionStatus = (txnInfo) => {
  return {
    type: UPDATE_TRANSACTION_STATUS,
    payload: {
      txnInfo
    },
  }
}

export const submitTransaction = (params) => {
  return {
    type: SUBMIT_TRANSACTION,
    params
  }
}

export const getChartData = (
  payload
) => {
  return {
    type: SET_GRAPH,
    payload,
  }
}

export const clearChartData = (
  payload
) => {
  return {
    type: CLEAR_CHART_DATA,
    payload,
  }
}

export const setPoolData = (data) => {
  return {
    type: SET_POOL,
    data,
  }
}

// USDC Graph Data
export const setUSDCGraph = (
  payload
) => {
  return {
    type: USDC_GRAPH,
    payload,
  }
}

// New action to get global values for all pools
export const _getGlobalValues = (
  payload
) => {
  return {
    type: GET_GLOBAL_VALUES,
    payload,
  }
}

// New action to SET global values for all pools
export const _setGlobalValues = (
  payload
) => {
  return {
    type: SET_GLOBAL_VALUES,
    payload,
  }
}

// New action to get volume for all pools
export const _getVolume = (
  payload
) => {
  return {
    type: GET_VOLUME,
    payload,
  }
}

// New action to SET volume for all pools
export const _setVolume = (
  payload
) => {
  return {
    type: SET_VOLUME,
    payload,
  }
}

// New action to get sportsbook funds
export const _getSportsbookFunds = (
  payload
) => {
  return {
    type: GET_SPORTSBOOK_FUNDS,
    payload,
  }
}

// New action to SET sportsbook funds
export const _setSportsbookFunds = (
  payload
) => {
  return {
    type: SET_SPORTSBOOK_FUNDS,
    payload,
  }
}
