import {
    fork,
    take,
    put,
    all,
    call
  } from "redux-saga/effects"
  
  import { getGovernanceData, getVlfiData } from '../../services/blockchainInterface'
  
  import { GET_GOVERNANCE_DATA } from "./actionTypes";
  
  import { governanceUpdateCallStatus } from "./actions";
  import ActivityStatus from "../../models/enums/ActivityStatus";
  
  function* GovernanceSaga() {
    yield all([
      fork(getGovernanceDataWatcher)
    ])
  }
  
  const createWatcher = (worker, type) => {
    return function* () {
      while (true) {
        const action = yield take(type)
        yield fork(worker, action)
      }
    }
  }
  
  function* getGovernanceDataWorker(payload) {
    try {
      yield put(governanceUpdateCallStatus({
        currentCall: 'GET_GOVERNANCE_DATA',
        callStatus: ActivityStatus.INITIATED
      }))
      let LFIToken = yield call(getVlfiData)
      
      if (LFIToken) {
        yield put(governanceUpdateCallStatus({
          currentCall: 'GET_GOVERNANCE_DATA',
          callStatus: ActivityStatus.COMPLETED,
          data: { LFIToken }
        }))
      }
      else {
        yield put(governanceUpdateCallStatus({
          currentCall: 'GET_GOVERNANCE_DATA',
          callStatus: ActivityStatus.FAILED
        }))
      }
    } catch (e) {
      yield put(governanceUpdateCallStatus({
        currentCall: 'GET_GOVERNANCE_DATA',
        callStatus: ActivityStatus.FAILED
      }))
    } finally {
      yield put(governanceUpdateCallStatus({
        currentCall: 'GET_GOVERNANCE_DATA',
        callStatus: ActivityStatus.NOT_INITIATED
      }))
    }
  }
  
  export const getGovernanceDataWatcher = createWatcher(
    getGovernanceDataWorker,
    GET_GOVERNANCE_DATA
  )
  
 
  
  export default GovernanceSaga