import { ethers } from "ethers"
const DECIMALS: number = 8 // DECIMALS for LFi token
const BigNumber10 = ethers.BigNumber.from(10)
const maxApprovalAmount = ethers.BigNumber.from(10).pow(28)
const SECONDS_IN_YEAR = 365 * 24 * 60 * 60;
const sleep = (ms: number) => new Promise(res => setTimeout(res, ms));
const formatToLFi = (n: any) => Number(ethers.utils.formatUnits(n, DECIMALS))
const format4Decimals = (n: any) => Number(parseFloat(n).toFixed(4))
const formatToWalletBalance = (n: any) => Number(parseFloat(n).toFixed(DECIMALS))
const formatToLFiBaseUnit = (lfi: any) => parseFloat((lfi * 10 ** DECIMALS).toString()).toFixed(0).toString()
const formatToNumber = (n: any) => ethers.BigNumber.from(n).toNumber()
const formatUnit = (amount: string, decimals: number) => ethers.utils.formatUnits(ethers.BigNumber.from(amount), decimals)
// formats the number to base unit and returns a BigNumber
const formatToBaseUnit = (amount: any, decimals: any) => ethers.BigNumber.from(ethers.utils.parseUnits(amount.toString(), decimals))
const formatFromBaseUnit = (amount: any, decimals: any) => {
    let stringNum = ethers.utils.formatUnits(ethers.BigNumber.from(amount), decimals)
    stringNum = processDecimalPlaces(stringNum)
    if (stringNum < '0.000001') {
        return 0
    }
    return Number(stringNum)
}
const normaliseAllowance = (amount: any, decimals: any) => {
    const allowance = ethers.BigNumber.from(amount).div(BigNumber10.pow(decimals))
    return allowance.gt(ethers.BigNumber.from('1000000000')) ? 1000000000 : allowance.toNumber()
}

const calculateTotalPrice = (liquidity: any, price: any) => Number((liquidity * price).toFixed(2)).toLocaleString()
const calculatePercentageChange = (currentLiquidity: number, yesterdaysLiquidity: number) => Number(((currentLiquidity - yesterdaysLiquidity) / yesterdaysLiquidity) * 100)
const computeAPR = (rewardsPerSecond: number, liquidity: number) => {
    liquidity = liquidity === 0 ? 1 : liquidity;
    return Number(rewardsPerSecond * SECONDS_IN_YEAR / liquidity * 100).toFixed(2).toLocaleString()
}

const utils = {
    formatToNumber,
    formatToLFi,
    formatToLFiBaseUnit,
    format4Decimals,
    formatToWalletBalance,
    formatUnit,
    sleep,
    formatToBaseUnit,
    formatFromBaseUnit,
    maxApprovalAmount,
    normaliseAllowance,
    calculateTotalPrice,
    calculatePercentageChange,
    computeAPR
}

export default utils

const processDecimalPlaces = (str: string) => {
    const [whole, decimal] = str.split('.')
    const newDecimal = decimal.slice(0, 10)
    return `${whole}.${newDecimal}`
}