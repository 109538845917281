import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga'

import './index.css';
import './index.scss';
import 'lunafi-ui/dist/index.css'
import Buffer from 'buffer'
// window.Buffer = new Buffer

window.process = {} as any;
ReactGA.initialize('UA-224878571-2')

const renderRoot = ReactDOM.createRoot(document.getElementById('root')!);
renderRoot.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);

reportWebVitals();
