const ethers = require("ethers")

const { supportedWallets } = require('./supportedWallets')
const { contracts } = require("./constants/contracts")

const {
  ERC20_ABI,
  VLFI_ABI,
  FARM_ABI,
  HOUSE_POOL_ABI,
  TOKEN_UPGRADE_ABI,
  SPORTSBOOK_ABI
} = require('./ABI')

// Infura project id
const ALCHEMY_PROJ_ID = "DVLzBLMrkAS286-GBHjt4CzKlzcoSEFa"
const INFURA_PROJ_ID = "ebfd26bc90bc447c8b6e954a01f21914"
const POLYGON_MAINNET_PROJ_ID = 'jPUD1KtyOR8SAw7qNfkhEQTqH6CrbayO'

const blockchainConfig = {
  137: {
    blockchain: 'Polygon',
    tokenType: 'ERC20',
    rpcUrl: `https://polygon-mainnet.g.alchemy.com/v2/jPUD1KtyOR8SAw7qNfkhEQTqH6CrbayO`,
    blockExplorerURL: 'https://polygonscan.com/ ',
    dailyBlocks: 43200,
    dexRouter: contracts.dexRouter[137],
    OUTPUT_TOKEN: '0xcB1e72786A6eb3b44C2a2429e317c8a2462CFeb1',
    DEXURL: `https://quickswap.exchange/#/swap?outputCurrency=${contracts.LFI[137]}`,
    networkDetails: {
      chainId: ethers.utils.hexValue(137),
      blockExplorerUrls: ['https://polygonscan.com/'],
      chainName: 'Polygon',
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18
      },
      rpcUrls: ['https://rpc-mainnet.maticvigil.com']
    },
    FORTMATIC: {
      API_KEY: 'pk_live_E35386B1FA8D192E',
      fortmaticNodeOptions: {
        rpcUrl: 'https://rpc-mainnet.maticvigil.com',
        chainId: 137
      }
    },
    MAGIC_LINK: {
      API_KEY: 'pk_live_315731D739C072F1',
      magicLinkNodeOptions: {
        network: {
          rpcUrl: `https://polygon-mainnet.g.alchemy.com/v2/${POLYGON_MAINNET_PROJ_ID}`,
          chainId: 137
        }
      }
    },
    "FARM": {
      address: contracts.farms[137],
      ABI: FARM_ABI,
      LFIWETH: {
        FARM_ID: 1
      },
      DECIMALS: 18,
    },
    "TOKEN": {
      ABI: ERC20_ABI,
      USDC: {
        address: contracts.USDC[137],
        DECIMALS: 6,
      },
      LFI: {
        address: contracts.LFI[137],
        DECIMALS: 18,
      },
      WBTC: {
        address: contracts.WBTC[137],
        DECIMALS: 8,
      },
      WETH: {
        address: contracts.WETH[137],
        DECIMALS: 18,
      },
      LFIWETH: {
        address: contracts.LFIWETH[137],
        DECIMALS: 18,
        ABI: ERC20_ABI,
      }
    },
    "HOUSE_POOLS": {
      USDC: {
        address: contracts.USDCHousePool[137],
        ABI: HOUSE_POOL_ABI,
        DECIMALS: 18,
      },
      WBTC: {
        address: contracts.WBTCHousePool[137],
        ABI: HOUSE_POOL_ABI,
        DECIMALS: 18,
      },
      WETH: {
        address: contracts.WETHHousePool[137],
        ABI: HOUSE_POOL_ABI,
        DECIMALS: 18,
      },
      LFI: {
        address: contracts.VLFI[137],
        ABI: VLFI_ABI,
        DECIMALS: 18,
      }
    },
    "VLFI": {
      address: contracts.VLFI[137],
      ABI: VLFI_ABI,
    },
    "TOKEN_UPGRADE": {
      address: contracts.tokenUpgrade[137],
      ABI: TOKEN_UPGRADE_ABI,
      DECIMALS: 18,
    },
    SPORTSBOOK: {
      address: contracts.sportsbook[137],
      ABI: SPORTSBOOK_ABI,
    }
  },
  80001: {
    blockchain: 'Polygon Mumbai',
    tokenType: 'ERC20',
    rpcUrl: `https://polygon-mumbai.g.alchemy.com/v2/${ALCHEMY_PROJ_ID}`,
    blockExplorerURL: 'https://mumbai.polygonscan.com/',
    dailyBlocks: 6456,
    dexRouter: contracts.dexRouter[80001],
    WETH: '0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889',
    OUTPUT_TOKEN: '0xcB1e72786A6eb3b44C2a2429e317c8a2462CFeb1',
    DEXURL: `https://quickswap.exchange/#/swap?outputCurrency=${contracts.LFI[80001]}`,
    networkDetails: {
      chainId: ethers.utils.hexValue(80001),
      blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
      chainName: 'Polygon Mumbai',
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18
      },
      rpcUrls: ['https://matic-mumbai.chainstacklabs.com']
    },
    FORTMATIC: {
      API_KEY: 'pk_test_ED753E928F529E1E',
      fortmaticNodeOptions: {
        rpcUrl: 'https://rpc-mumbai.matic.today',
        chainId: 80001
      }
    },
    MAGIC_LINK: {
      API_KEY: 'pk_test_ED753E928F529E1E',
      magicLinkNodeOptions: {
        network: {
          rpcUrl: 'https://rpc.ankr.com/eth_ropsten',
          chainId: 3
        }
      }
    },
    "FARM": {
      address: contracts.farms[80001],
      ABI: FARM_ABI,
      LFIWETH: {
        FARM_ID: 0
      },
      DECIMALS: 18,
    },
    "TOKEN": {
      ABI: ERC20_ABI,
      USDC: {
        address: contracts.USDC[80001],
        DECIMALS: 6,
      },
      LFI: {
        address: contracts.LFI[80001],
        DECIMALS: 18,
      },
      WBTC: {
        address: contracts.WBTC[80001],
        DECIMALS: 8,
      },
      WETH: {
        address: contracts.WETH[80001],
        DECIMALS: 18,
      },
      LFIWETH: {
        address: contracts.LFIWETH[80001],
        DECIMALS: 18
      }
    },
    "HOUSE_POOLS": {
      USDC: {
        address: contracts.USDCHousePool[80001],
        ABI: HOUSE_POOL_ABI,
        DECIMALS: 18,
      },
      WBTC: {
        address: contracts.WBTCHousePool[80001],
        ABI: HOUSE_POOL_ABI,
        DECIMALS: 18,
      },
      WETH: {
        address: contracts.WETHHousePool[80001],
        ABI: HOUSE_POOL_ABI,
        DECIMALS: 18,
      },
      LFI: {
        address: contracts.VLFI[80001],
        ABI: VLFI_ABI,
        DECIMALS: 18,
      }
    },
    "VLFI": {
      address: contracts.VLFI[80001],
      ABI: VLFI_ABI,
      DECIMALS: 18,
    },
    "TOKEN_UPGRADE": {
      address: contracts.tokenUpgrade[80001],
      ABI: TOKEN_UPGRADE_ABI,
      DECIMALS: 18,
    },
    "SPORTSBOOK": {
      address: contracts.sportsbook[80001],
      ABI: SPORTSBOOK_ABI,
    }
  },
  // 416: {
  //   blockchain: 'SX Network',
  //   tokenType: 'ERC20',
  //   rpcUrl: `https://rpc.sx.technology`,
  //   blockExplorerURL: 'https://explorer.sx.technology/',
  //   dailyBlocks: 43200,
  //   dexRouter: '0x4de4d788d2AeFdf395c1766544F7092154E61ad2',
  //   rewarderContract: '0xF07F530C8431657e5E6B100c82028FCdb07F3bd1',
  //   networkDetails: {
  //     chainId: ethers.utils.hexValue(416),
  //     blockExplorerUrls: ['https://explorer.sx.technology/'],
  //     chainName: 'SX',
  //     nativeCurrency: {
  //       name: 'SX',
  //       symbol: 'SX',
  //       decimals: 18
  //     },
  //     rpcUrls: ['https://rpc.sx.technology']
  //   },
  //   "FARM": {
  //     address: '0x7Cb0E2e944069D6d70A6f470154C4EEF3eB67530',
  //     ABI: MINI_CHEF_ABI,
  //     LFISX: {
  //       FARM_ID: 8
  //     }
  //   },
  //   "CLAIM_TOKEN": {
  //     LFISX: {
  //       address: '0xb251c9b8712bd2253aefc422fbd370b236f7abde',
  //       ABI: CLAIM_TOKEN_ABI,
  //       DECIMALS: 18
  //     },
  //   },
  //   "TOKEN": {
  //     ABI: ERC20_ABI,
  //     SHARK: {
  //       address: '0xa0cB58E7F783fce0F4042C790ea3045c48CD51e8',
  //       DECIMALS: 18,
  //     },
  //     LFI: {
  //       address: '0x4C882ec256823eE773B25b414d36F92ef58a7c0C',
  //       DECIMALS: 18,
  //     },
  //     WSX: {
  //       address: '0x90d27E008d5Db7f0a3c90a15A8Dcc4Ca18cFc670',
  //       DECIMALS: 18,
  //     },
  //     USDC: {
  //       address: '0xe2aa35C2039Bd0Ff196A6Ef99523CC0D3972ae3e',
  //       DECIMALS: 18,
  //     }
  //   },
  // },
  // 647: {
  //   blockchain: 'Toronto',
  //   tokenType: 'ERC20',
  //   rpcUrl: `https://rpc.toronto.sx.technology`,
  //   blockExplorerURL: 'https://explorer.toronto.sx.technology/',
  //   dailyBlocks: 6456,
  //   dexRouter: TEST_TORONTO_DEX_ROUTER,
  //   WETH: '0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889',
  //   OUTPUT_TOKEN: TEST_TORONTO_OUTPUT_TOKEN,
  //   networkDetails: {
  //     chainId: ethers.utils.hexValue(647),
  //     blockExplorerUrls: ['https://explorer.toronto.sx.technology/'],
  //     chainName: 'Toronto',
  //     nativeCurrency: {
  //       name: 'SX',
  //       symbol: 'SX',
  //       decimals: 18
  //     },
  //     rpcUrls: ['https://rpc.toronto.sx.technology']
  //   },
  //   FORTMATIC: {
  //     API_KEY: 'pk_test_589545F6A4430E3B',
  //     fortmaticNodeOptions: {
  //       rpcUrl: 'https://rpc.toronto.sx.technology',
  //       chainId: 647
  //     }
  //   },
  //   MAGIC_LINK: {
  //     API_KEY: 'pk_live_315731D739C072F1',
  //     magicLinkNodeOptions: {
  //       network: {
  //         rpcUrl: `https://explorer.toronto.sx.technology/`,
  //         chainId: 647
  //       }
  //     }
  //   },
  //   "VLFI": {
  //     address: TEST_TORONTO_VLFI_TOKEN_ADDRESS,
  //     ABI: VLFI_ABI
  //   },
  //   "FARM": {
  //     address: TEST_TORONTO_FARM_CONTRACT,
  //     ABI: FARM_ABI,
  //     USDC: {
  //       FARM_ID: 0
  //     },
  //     WBTC: {
  //       FARM_ID: 1
  //     },
  //     WETH: {
  //       FARM_ID: 2
  //     },
  //     LFISX: {
  //       FARM_ID: 0
  //     }
  //   },
  //   "CLAIM_TOKEN": {
  //     USDC: {
  //       address: TEST_TORONTO_USDC_FARM_CLAIM_TOKEN_ADDRESS,
  //       ABI: CLAIM_TOKEN_ABI,
  //       DECIMALS: 18
  //     },
  //     LFI: {
  //       address: TEST_TORONTO_LFI_TOKEN_ADDRESS,
  //       ABI: CLAIM_TOKEN_ABI,
  //       DECIMALS: 18,
  //     },
  //     WBTC: {
  //       address: TEST_TORONTO_WBTC_FARM_CLAIM_TOKEN_ADDRESS,
  //       ABI: CLAIM_TOKEN_ABI,
  //       DECIMALS: 18
  //     },
  //     WETH: {
  //       address: TEST_TORONTO_WETH_FARM_CLAIM_TOKEN_ADDRESS,
  //       ABI: CLAIM_TOKEN_ABI,
  //       DECIMALS: 18
  //     },
  //     LFISX: {
  //       address: TEST_TORONTO_LP_PAIR,
  //       ABI: UNISWAPV2_PAIR_ABI,
  //       DECIMALS: 18
  //     }
  //   },
  //   "TOKEN": {
  //     ABI: ERC20_ABI,
  //     USDC: {
  //       address: TEST_TORONTO_USDC_TOKEN_ADDRESS,
  //       DECIMALS: 6,
  //     },
  //     LFI: {
  //       deprecated_address: DEPRECATED_TEST_TORONTO_LFI_TOKEN_ADDRESS,
  //       address: TEST_TORONTO_LFI_TOKEN_ADDRESS,
  //       DECIMALS: 18,
  //     },
  //     WBTC: {
  //       address: TEST_TORONTO_WBTC_TOKEN_ADDRESS,
  //       DECIMALS: 8,
  //     },
  //     WETH: {
  //       address: TEST_TORONTO_WETH_TOKEN_ADDRESS,
  //       DECIMALS: 18,
  //     },
  //     LFISX: {
  //       address: TEST_TORONTO_LP_PAIR,
  //       DECIMALS: 18
  //     }
  //   },
  //   "HOUSE_POOLS": {
  //     USDC: {
  //       address: TEST_TORONTO_USDC_HOUSE_POOL_ADDRESS,
  //       ABI: HOUSE_POOL_ABI,
  //       DEPLOYED_BLOCK: TEST_TORONTO_USDC_HOUSE_POOL_DEPLOYED_BLOCK,
  //       DECIMALS: 18,
  //     },
  //     WBTC: {
  //       address: TEST_TORONTO_WBTC_HOUSE_POOL_ADDRESS,
  //       ABI: HOUSE_POOL_ABI,
  //       DEPLOYED_BLOCK: TEST_TORONTO_WBTC_HOUSE_POOL_DEPLOYED_BLOCK,
  //       DECIMALS: 18,
  //     },
  //     WETH: {
  //       address: TEST_TORONTO_WETH_HOUSE_POOL_ADDRESS,
  //       ABI: HOUSE_POOL_ABI,
  //       DEPLOYED_BLOCK: TEST_TORONTO_WETH_HOUSE_POOL_DEPLOYED_BLOCK,
  //       DECIMALS: 18,
  //     },
  //   },
  //   "TOKEN_UPGRADE": {
  //     address: TEST_TORONTO_LFI_UPGRADE_ADDRESS,
  //     ABI: TOKEN_UPGRADE_ABI,
  //     DECIMALS: 18,
  //   }
  // }
}

const txnOperations = {
  UNSTAKEMAX: 'unstakeMax',
  UNSTAKE: 'unStake',
  WITHDRAW: 'withdraw',
  STAKE: 'stake',
  DEPOSIT: 'deposit',
  HARVEST_ALL: 'harvestAll',
  HARVEST: 'harvest',
  APPROVE: 'approve',
  WITHDRAW_AND_HARVEST: 'withdrawAndHarvest',
  DEPOSIT_LFI: 'stake',
  CLAIM_LFI: 'claimRewards',
  COOLDOWN: 'activateCooldown',
  PERMIT_AND_STAKE: 'permitAndStake',
  TRANSFER: 'transfer',
  SWAP: 'Swap',
  CLAIM_REWARDS: 'claimRewards',
}

const getDeployedEnv = () => {
  // possible substrings present in a test or dev environment url
  const testnet = ['dev-app', 'amplifyapp']
  return testnet.find(x => window.location.href.includes(x))
    ? 'TESTNET' : 'MAINNET'
}

const getChainIds = () => {
  const ethChainId = getDeployedEnv() === 'TESTNET' ? 3 : 1
  const polygonChainId = getDeployedEnv() === 'TESTNET' ? 80001 : 137
  const sxChainId = getDeployedEnv() === 'TESTNET' ? 647 : 416
  const supportedNetworks = getDeployedEnv() === 'TESTNET' ? [80001] : [137] // use this for actual on-chain operations
  const staticSupportedNetworks = getDeployedEnv() === 'TESTNET' ? [80001, 647] : [137, 416]
  // const staticSupportedNetworks = getDeployedEnv() === 'TESTNET' ? [647, 80001] : [416, 137] // use this for displaying chain data on the homepage
  return { ethChainId, polygonChainId, defaultChainId: polygonChainId, supportedNetworks, sxChainId, staticSupportedNetworks }
}

const getStaticSupportedNetworks = () => {
  const staticSupportedNetworks = getChainIds().staticSupportedNetworks
  let networkList = []
  staticSupportedNetworks.forEach((chainId) => {
    networkList.push(blockchainConfig[chainId])
  })
  return networkList
}

const walletOptions = supportedWallets()
const options = { gasLimit: 200000 }

const switchConfig = {
  nav: {
    home: false,
    pools: false,
    farms: false,
    governance: false,
    idogame: false
  },
  coins: {
    WBTC: false,
    WETH: false,
    USDC: false,
    LFI: false,
    LFIWETH: true,
    LFISX: false
  },
  betting: {
    bettingTurover: true,
  },
  chart: {
    pieChart: false,
    lineChart: false,
  },
  banner: {
    sportsbook: false,
  },
  LFI: {
    isListedOnExchange: true
  }
}

module.exports = {
  blockchainConfig, getDeployedEnv, getChainIds, options, walletOptions, txnOperations, ALCHEMY_PROJ_ID, INFURA_PROJ_ID, switchConfig, getStaticSupportedNetworks,
}