import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { setTransactionModal } from "../../store";
import { Typo } from '../../lunafi-uikit/components/Typo'
import { sliceAddress, TokenIcons } from "../../services/utils/commonUtils";
import transactionInfo from '../../config/transactionConfig.js';
import { CallStatusPercentage } from "../../services/utils/commonUtils";
import Fade from '@mui/material/Fade';
import ActivityStatus from "../../models/enums/ActivityStatus";
import { Divider } from '../../lunafi-uikit/components/Divider'
import { Player } from '@lottiefiles/react-lottie-player';
import success from '../../assets/lottie/success.json'
import loading from '../../assets/lottie/loading-anim.json'
import { FARM_HARVEST_ALL, FARM_HARVEST } from "../../store/farms/actionTypes";
import { POOL_HARVEST } from "../../store/pools/actionTypes";

const modalBoxStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '100%', sm: '580px' },
    color: '#FFFFFF',
    backgroundColor: '#090909',
    border: '1px solid #006868',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '20px',
    padding: '45px 55px 20px 55px'
};

const iconstyle = {
    height: '64px',
    left: '0px',
    display: 'flex'
}

export default function TransactionModal(props: any) {
    const dispatch = useDispatch()
    const { walletBalanceInfo } = useSelector((state: any) => state.Wallet)

    const handleClose = () => {
        dispatch(setTransactionModal(false))
    }

    const [text, setText] = useState('');
    const [progress, setProgress] = React.useState(0);
    const [completed, setCompleted] = useState(false)
    const [showProgress, setShowProgress] = useState(false)

    const getImg = () => {
        if (props.transaction.currentCall === FARM_HARVEST_ALL || props.transaction.currentCall === FARM_HARVEST || props.transaction.currentCall === POOL_HARVEST) {
            return TokenIcons['LFI']
        } else {
            return TokenIcons[props.transaction.token]
        }
    }

    useEffect(() => {
        setText(transactionInfo(props.transaction.currentCall, props.transaction.token))
        if (props.transaction.callStatus) {
            const timer = setInterval(() => {
                setProgress((oldProgress) => {
                    if (oldProgress === 100) {
                        return 100;
                    }
                    const diff = Math.floor(Math.random() * (CallStatusPercentage[props.transaction.callStatus] - oldProgress + 1)) + oldProgress;
                    // const newProgress = Math.min(oldProgress + diff, 100);
                    return diff;
                });
            }, 1500);
            if (props.open === false) {
                clearInterval(timer);
            }
            if ((progress === 100) && ((props.transaction.callStatus === ActivityStatus.COMPLETED) || (props.transaction.callStatus === ActivityStatus.FAILED))) {
                setCompleted(true)
            }
            return () => {
                clearInterval(timer);
            };
        }
    }, [props.transaction.callStatus])

    useEffect(() => {
        setText(transactionInfo(props.transaction.currentCall, props.transaction.token))
        if (props.transaction.callStatus === ActivityStatus.FAILED) {
            setProgress(100)
        }
        if (props.transaction.callStatus) {
            const timer = setInterval(() => {
                setProgress((oldProgress) => {
                    if (oldProgress === 100) {
                        return 100;
                    }
                    const diff = Math.floor(Math.random() * (CallStatusPercentage[props.transaction.callStatus] - oldProgress + 1)) + oldProgress;
                    // const newProgress = Math.min(oldProgress + diff, 100);
                    return diff;
                });
            }, 1500);
            return () => {
                clearInterval(timer);
            };
        }
    }, [props.transaction.callStatus])

    useEffect(() => {
        if (progress === 100 && ((props.transaction.callStatus === ActivityStatus.COMPLETED) || (props.transaction.callStatus === ActivityStatus.FAILED))) {
            setShowProgress(true)
            setTimeout(() => {
                setCompleted(true)
            }, 550);
        }
    }, [progress])

    useEffect(() => {
        setText('')
        setProgress(0)
        setCompleted(false)
        setShowProgress(false)

    }, [props.open]);

    return (
        <Box>
            <Modal
                open={props.open}
                onBackdropClick={handleClose}
                onClose={handleClose}
                disableAutoFocus={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ margin: { xs: '0px 16px 0px 16px', sm: '0px 0px 0px 0px' }, '& .MuiBackdrop-root': { opacity: 0, backgroundColor: 'rgba(0,0,0,0.8)' } }}
            >
                <Box sx={modalBoxStyle}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: '16px', alignItems: 'center', }}>
                        <Box className='joe'>
                            <embed src={getImg()} style={iconstyle} />
                        </Box>
                        <Box sx={{ marginLeft: '24px' }}>
                            <Typo white>{props.transaction.amount} {props.transaction.currentCall === FARM_HARVEST_ALL || props.transaction.currentCall === FARM_HARVEST ? "LFI" : props.transaction.token}</Typo>
                        </Box>
                    </Box>
                    <Box sx={{ marginBottom: '55px' }}>
                        <Typo sx={{ color: '#989898' }}>{text}</Typo>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            padding: '0px',
                            alignSelf: 'stretch',
                            marginBottom: '24px'
                        }}
                    >
                        <Box sx={{ width: '100%', alignItems: 'center' }}>
                            <Typo sx={{ color: '#989898' }}>Status</Typo>
                        </Box>
                        {!completed ?
                            <Fade in={!showProgress} timeout={props.transaction.callStatus === ActivityStatus.COMPLETED ? 500 : 0} >
                                <Box><Player
                                    autoplay
                                    src={loading}
                                    loop
                                    style={{ width: '200px', marginTop: '-40px', marginRight: '-40px' }}
                                /></Box>
                            </Fade>
                            :
                            <Box>
                                {props.transaction.callStatus === ActivityStatus.COMPLETED && <Typo white>completed</Typo>}
                                {props.transaction.callStatus === ActivityStatus.FAILED && <Typo white>failed</Typo>}
                            </Box>}
                    </Box>
                    {completed && props.transaction.callStatus !== ActivityStatus.FAILED && <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            padding: '0px',
                            alignSelf: 'stretch',
                            marginBottom: '24px'
                        }}
                    >
                        <Box sx={{ left: '0' }}>
                            <Typo sx={{ color: '#989898' }}>Transaction Hash</Typo>
                        </Box>

                        <Box sx={{ width: 'fit-content' }}>
                            <Typo sx={{ width: '100%' }} ><a href={walletBalanceInfo.blockExplorer + 'tx/' + props.transaction.data.depositTx} target="_blank" rel="noopener noreferrer">{sliceAddress(props.transaction.data.depositTx)}</a></Typo>
                        </Box>
                    </Box>}
                    {completed && <Divider margin='8px 0px 0px 0px' />}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            padding: '0px',
                            alignSelf: 'stretch',
                        }}
                    >
                        {completed && props.transaction.callStatus === ActivityStatus.COMPLETED && <Box sx={{ margin: 'auto auto auto 0px' }}>
                            <Typo sx={{ color: '#EFEFEF' }}>Transaction successfully submitted</Typo>
                        </Box>}
                        {completed && props.transaction.callStatus === ActivityStatus.FAILED && <Box sx={{ marginTop: '24px' }}>
                            <Typo sx={{ color: '#EFEFEF' }}>Transaction has failed</Typo>
                        </Box>}
                        {completed && props.transaction.callStatus === ActivityStatus.COMPLETED && <Box>
                            <Player
                                autoplay
                                src={success}
                                style={{ height: '81px', width: '81px' }}
                            />
                        </Box>}
                    </Box>

                </Box>
            </Modal>
        </Box>
    );
}
