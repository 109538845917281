import { useDispatch, useSelector } from 'react-redux';
import { RegularBox } from '../../lunafi-uikit/components/Box';
import { Button } from '../../lunafi-uikit/components/Button';
import { Typo } from '../../lunafi-uikit/components/Typo';
import { submitTransaction } from '../../store';
import QRCode from 'react-qr-code';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Tooltip } from '@mui/material';

function MagicDeposit() {
    const [open, setOpen] = useState(false);

    const { walletBalanceInfo } = useSelector((state: any) => state.Wallet)
    const dispatch = useDispatch()
    const dispatchSubmitTransaction = (e: any, currentCall: string) => {
        dispatch(submitTransaction({ currentCall: currentCall, poolType: 'LFI', amount: '' }))
    }
    const iconstyle = {
        fontSize: '15px',
        color: '#00FFFF',
        top: '-5px',
        marginLeft: '5px',
        cursor: 'pointer',
        position: 'relative',
    } as const
    function copyAddress() {
        navigator.clipboard.writeText(walletBalanceInfo.address);
        setOpen(true);
        setTimeout(function () {
            setOpen(false);
        }, 3000);
    }
    const style = {
        width: '100%',
        display: 'block',
        textAlign: 'center',
        padding: '10px',
        height: '100%'
    }

    return (
        <RegularBox sx={style}>
            {walletBalanceInfo?.address && <div>
                <QRCode value={walletBalanceInfo.address} />
                <div>
                    <Typo>{walletBalanceInfo.address}</Typo>
                    <ClickAwayListener onClickAway={() => setOpen(false)}>
						<Tooltip
							placement="right"
							onClose={() => setOpen(false)}
							open={open}
							disableFocusListener
							disableHoverListener
							disableTouchListener
							title="Copied"
						>
							<ContentCopyIcon
								style={iconstyle}
								onClick={copyAddress}
							/>
						</Tooltip>
					</ClickAwayListener>
                </div>
            </div>}
        </RegularBox>
    );

}
export default MagicDeposit;