import {
    GET_GOVERNANCE_DATA,
    GOVERNANCE_UPDATE_CALL_STATUS,
    SET_LFI_PRICE_CHART
} from "./actionTypes"

export const getGovernanceData = (
    payload
) => {
    return {
        type: GET_GOVERNANCE_DATA,
        payload,
    }
}
export const setLFIPriceChart = (
    payload
) => {
    return {
        type: SET_LFI_PRICE_CHART,
        payload,
    }
}
export const governanceUpdateCallStatus = (callStatusInfo) => {
    return {
        type: GOVERNANCE_UPDATE_CALL_STATUS,
        payload: {
            callStatusInfo
        },
    }
}

