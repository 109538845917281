import {
    GET_FARM_DATA,
    FARM_UPDATE_CALL_STATUS
} from "./actionTypes"

export const getFarmData = (
    payload
) => {
    return {
        type: GET_FARM_DATA,
        payload,
    }
}
export const farmUpdateCallStatus = (callStatusInfo) => {
    return {
        type: FARM_UPDATE_CALL_STATUS,
        payload: {
            callStatusInfo
        },
    }
}

