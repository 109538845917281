import {
  CONNECT_WALLET,
  DISCONNECT_WALLET,
  WALLET_CONNECTED,
  REFRESH_WALLET_INFO,
  WALLET_DISCONNECTED,
  WALLET_NOTIFICATION_SHOWN,
  WALLET_ON_LOAD,
  SET_LFI_PRICE,
  FORTMATIC_OPERATION,
  ADD_LFI_INJECTED
} from "./actionTypes"

export const connectWallet = (
  payload
) => {
  return {
    type: CONNECT_WALLET,
    params: payload
  }
}

export const disconnectWallet = (
  payload
) => {
  return {
    type: DISCONNECT_WALLET,
    payload,
  }
}

export const walletNotificationShown = (
  notificationShown
) => {
  return {
    type: WALLET_NOTIFICATION_SHOWN,
    payload: {
      notificationShown
    }
  }
}

export const walletConnected = (walletBalanceInfo) => {
  return {
    type: WALLET_CONNECTED,
    payload: {
      walletBalanceInfo
    },
  }
}


export const refreshWalletInfo = (walletBalanceInfo) => {
  return {
    type: REFRESH_WALLET_INFO,
    payload: {
      walletBalanceInfo
    },
  }
}

export const walletDisConnected = (walletBalanceInfo) => {
  return {
    type: WALLET_DISCONNECTED,
    payload: {
      walletBalanceInfo
    },
  }
}

export const walletOnLoad = (
  payload
) => {
  return {
    type: WALLET_ON_LOAD,
    params: payload
  }
}

export const setLFIPrice = (
  payload
) => {
  return {
    type: SET_LFI_PRICE,
    payload,
  }
}

export const fortmaticOperation = (
  payload
) => {
  return {
    type: FORTMATIC_OPERATION,
    params: payload
  }
}

export const addLfiInjected = () => {
  return {
    type: ADD_LFI_INJECTED
  }
}