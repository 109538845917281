import { Box } from "@mui/system";

export function BlueShadowBorder(props: any) {
    const sx = props.sx ? props.sx : {}
    const borderStyle = {
        backgroundColor: '#1A1D1F',
        color: 'white',
        margin: '20px',
        borderRadius: '20px',
        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
        boxShadow: '0px 4px 50px 4px rgba(103,255,255,0.25)',
        padding: '40px 30px 40px 30px',
        textAlign: 'left',
        display: 'inherit', 
        float: 'left'
    } as const
    const style = { ...borderStyle, ...sx }

    return (
        <Box sx={style}>
            {props.children}
        </Box>
    );
}

export default BlueShadowBorder;