import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { useDispatch } from "react-redux"
import { walletOnLoad } from '../store';
export function Web3Container(props: any) {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(walletOnLoad())
    },[])
    return ( <> {props.children} </>
    )
}

export default Web3Container;