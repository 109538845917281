import { DrawerNavigation } from '../components/drawer-navigation';
import React from 'react';
import { useSelector } from 'react-redux';
// import useStoreUpdate from '../services/hooks/useStoreUpdate';

function Navigation(props: any) {
	// useStoreUpdate();
	const { walletBalanceInfo, connected } = useSelector((state: any) => state.Wallet)
	const { connectModalOpen, disconnectModalOpen, transactionModalOpen, wrongNetworkModalOpen } = useSelector((state: any) => state.Modals)
	return (
		<DrawerNavigation switch={props.switch} isConnected={connected} walletBalanceInfo={walletBalanceInfo} isModalOpen={(connectModalOpen || disconnectModalOpen || transactionModalOpen || wrongNetworkModalOpen)}>
			{props.children}
		</DrawerNavigation>
	);

}
export default Navigation;