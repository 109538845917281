import { IFarmToken, ILFIToken, IPoolTokenV2 } from '../../models/tokenModel';
import { getChainIds, switchConfig } from '../../config/walletConfig';
import {farmsConfig} from '../../config/farmsConfig';
import poolConfigV2 from '../../config/poolConfigV2';
export const TokenIcons: any = {
    WBTC: '/assets/coin/wbtc.svg',
    WETH: '/assets/coin/weth.svg',
    USDC: '/assets/coin/usdc.svg',
    LFI: '/assets/coin/BETS.svg',
    LFIWETH: '/assets/coin/LFIWETH.svg',
    SX: '/assets/coin/SX.svg',

}

export const ChainNames: any = {
    80001: 'MATIC',
    137: 'MATIC',
    647: 'SX Network',
    416: 'SX Network'
}

export const ChainUrlNames: any = {
    80001: 'MATIC',
    137: 'MATIC',
    647: 'SX',
    416: 'SX'
}

export const CallStatusPercentage: any = {
    NOT_INITIATED: 5,
    INITIATED: 20,
    IN_PROGRESS: 85,
    COMPLETED: 100,
    FAILED: 100
}

export function flattenPoolData(poolInfo: any) {

    const supportedNetworks = getChainIds().supportedNetworks
    let mappedData: IPoolTokenV2[] = []
    if (!Object.keys(poolInfo).length) return getStaticPoolData();
    supportedNetworks.forEach((id) => {
        if (!poolInfo[id]) {
            return
        }
        const tickerData: any[] = Object.keys(poolInfo[id])
        const farmInfoArr: any[] = Object.values(poolInfo[id])
        farmInfoArr.forEach((value: any, index: any) => {

            const temp: IPoolTokenV2 = {
                name: tickerData[index],
                ticker: tickerData[index],
                liquidity: value?.liquidity || 0,
                myLiquidity: value?.myLiquidity || 0,
                allowance: value?.allowance || 0,
                walletBalance: value?.walletBalance || 0,
                tokenPrice: value?.tokenPrice || 0,
                yesterdaysLiquidity: value?.yesterdaysLiquidity || 0,
                yesterdaysTokenPrice: value?.yesterdaysTokenPrice || 0,
                virtualPrice: value?.virtualPrice || 0,
                chain: id,
                APR: 0

            };
            mappedData.push(temp)
        })
    })
    mappedData = sortItemArray(mappedData)
    return mappedData
}

interface IConfigMap {
    [key: number]: {};
}

interface ISwitchConfig {
    [key: string]: any;
}
export function combinePoolData(poolInfo: any, farmInfo: any, governanceInfo: any) {
    const supportedNetworks = getChainIds().supportedNetworks
    let combinedData: IPoolTokenV2[] = []
    supportedNetworks.forEach((id: number) => {
        let castedConfig: IConfigMap = poolConfigV2
        const poolsConfigArr: IConfigMap[] = Object.values(castedConfig[id])

        poolsConfigArr.forEach((value: any) => {
            const temp: IPoolTokenV2 = {
                name: value.name,
                ticker: value.ticker,
                liquidity: poolInfo[value.ticker]?.liquidity || 0,
                myLiquidity: poolInfo[value.ticker]?.myLiquidity || 0,
                allowance: poolInfo[value.ticker]?.allowance || 0,
                walletBalance: poolInfo[value.ticker]?.walletBalance || 0,
                tokenPrice: poolInfo[value.ticker]?.tokenPrice || 0,
                yesterdaysLiquidity: poolInfo[value.ticker]?.yesterdaysLiquidity || 0,
                yesterdaysTokenPrice: poolInfo[value.ticker]?.yesterdaysTokenPrice || 0,
                virtualPrice: poolInfo[value.ticker]?.virtualPrice || 0,
                chain: id,
                APR: 0


            };
            combinedData.push(temp)
        })
    })
    combinedData = mergePoolFarmGovData(combinedData, farmInfo, governanceInfo)
    let disabledConfig: ISwitchConfig = switchConfig.coins
    combinedData = combinedData.sort((a, b) => disabledConfig[a.ticker] ? 1 : -1);


    return combinedData;
}

export function mapFarmData(farmInfo: any) {
    const supportedNetworks = getChainIds().supportedNetworks
    let mappedData: IFarmToken[] = []
    if (!Object.keys(farmInfo).length) return getStaticFarmData();
    const castedConfig: IChainMap = farmsConfig


    supportedNetworks.forEach((id) => {
        const tickerData: any[] = Object.keys(farmInfo[id])
        const farmInfoArr: any[] = Object.values(farmInfo[id])
        farmInfoArr.forEach((value: any, index: any) => {

            const temp: IFarmToken = {
                ticker: tickerData[index],
                liquidity: value.liquidity || 0,
                myLiquidity: value.myLiquidity || 0,
                allowance: value.allowance || 0,
                walletBalance: value.walletBalance || 0,
                pendingHarvest: value.pendingHarvest || 0,
                dailyLfiRewards: value.dailyLfiRewardRate || 0,
                APR: value.APR || 0,
                chain: id,
                name: castedConfig[id][tickerData[index]].name || '',
                totalValueLocked: value.totalValueLocked || 0,
                lpTokenPrice: value.lpTokenPrice || 0,
                dailyChange: value.dailyChange || 0
            }
            mappedData.push(temp)
        })
    })
    mappedData = sortItemArray(mappedData)
    return mappedData
}

interface IChainMap {
    [key: number]: any;
}
interface IDisabledCoins {
    [key: string]: boolean;
}
const getStaticFarmData = () => {
    const supportedNetworks: number[] = getChainIds().supportedNetworks
    let mappedData: IFarmToken[] = []
    const disabledCoins: IDisabledCoins = switchConfig.coins
    const castedConfig: IChainMap = farmsConfig
    supportedNetworks.forEach((id: any) => {
        const farmInfoArr: any[] = Object.values(castedConfig[id])
        const filteredFarmInfo = farmInfoArr.filter(x => disabledCoins[x.ticker] === false)
        filteredFarmInfo.forEach((value: any, index: any) => {
            const temp: IFarmToken = {
                ticker: value.ticker,
                liquidity: 0,
                myLiquidity: 0,
                allowance: 1,
                walletBalance: 0,
                pendingHarvest: 0,
                dailyLfiRewards: 0,
                APR: 0,
                name: castedConfig[id][value.ticker].name || '',
                chain: id,
                totalValueLocked: 0,
                lpTokenPrice: 0,
                dailyChange: 0
            }
            mappedData.push(temp)
        })
    })
    mappedData = sortItemArray(mappedData)
    return mappedData
}

const getStaticPoolData = () => {
    const supportedNetworks: number[] = getChainIds().supportedNetworks
    let mappedData: IPoolTokenV2[] = []
    const disabledCoins: IDisabledCoins = switchConfig.coins
    const castedConfig: IChainMap = poolConfigV2
    supportedNetworks.forEach((id: any) => {
        const farmInfoArr: any[] = Object.values(castedConfig[id])
        const filteredFarmInfo = farmInfoArr.filter(x => disabledCoins[x.ticker] === false)
        filteredFarmInfo.forEach((value: any, index: any) => {
            const temp: IPoolTokenV2 = {
                name: value.name,
                ticker: value.ticker,
                liquidity: 0,
                myLiquidity: 0,
                allowance: 0,
                walletBalance: 0,
                tokenPrice: 0,
                yesterdaysLiquidity: 0,
                yesterdaysTokenPrice: 0,
                virtualPrice: 0,
                chain: id,
                APR: 0

            }
            mappedData.push(temp)
        })
    })
    mappedData = sortItemArray(mappedData)
    return mappedData
}

const sortItemArray = (data: any[]) => {
    return data.sort((a, b) => a.ticker > b.ticker ? 1 : -1);

}
export function calculateTotalPendingHarvest(farmTokens: IFarmToken[]) {
    return Number(farmTokens.map((token: IFarmToken) => token.pendingHarvest).reduce((total, pendingHarvest) => total + pendingHarvest).toFixed(2))
}

export function sliceAddress(address: string) {
    if (address) {
        return address.slice(0, 6) + '...' + address.slice(address.length - 4)
    } else {
        return ''
    }
}

export function getToolBarName(path: string) {
    if (path === '/') {
        return 'home'
    }
    if (path.includes('pool')) {
        return 'pools'
    }
    if (path.includes('farm')) {
        return 'farms'
    }
    if (path.includes('staking')) {
        return 'staking'
    }
    if (path.includes('game')) {
        return 'ido-game'
    }
    if (path.includes('token-upgrade')) {
        return 'token-upgrade'
    }
    return ''
}

export function isInputInValid(input: string, walletBalance: number) {
    if (Number(input) > 0 && Number(input) <= walletBalance) {
        return false
    } else {
        return true
    }
}

export function isMobileDevice() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
    }
    return false
}

export const castLfiToPoolToken = (LFIToken: ILFIToken, LFIPrice: number) => {
    const temp: IPoolTokenV2 = {
        name: 'LFI',
        ticker: 'LFI',
        liquidity: LFIToken.totalLiquidity || 0,
        myLiquidity: LFIToken.depositedLFI || 0,
        allowance: LFIToken.allowance || 0,
        walletBalance: LFIToken.LFIwalletBalance || 0,
        tokenPrice: LFIPrice,
        yesterdaysLiquidity: 0,
        yesterdaysTokenPrice: 0,
        virtualPrice: 0,
        chain: 0,
        APR: 0
    };
    return temp
}

export const buildChainUrl = (ticker: string, chainId: number) => {
    if (ticker && chainId) {
        return ticker.concat('-', ChainUrlNames[chainId])
    }
    return ''
}

export const breakDownChainUrl = (chainUrl: string) => {
    const temp = chainUrl.split('-')
    return {
        ticker: temp[0],
        chain: temp[1]
    }
}

const mergePoolFarmGovData = (combinedData: IPoolTokenV2[], farmInfo: any, governanceInfo: any) => {
    const vLfiIndex = combinedData.findIndex((pool: IPoolTokenV2) => pool.ticker === 'LFI' && pool.chain === getChainIds().polygonChainId)
    const farmIndex = combinedData.findIndex((pool: IPoolTokenV2) => pool.ticker === 'LFIETHLP' && pool.chain === getChainIds().polygonChainId)
    const sxFarmIndex = combinedData.findIndex((pool: IPoolTokenV2) => pool.ticker === 'SX/LFI' && pool.chain === getChainIds().sxChainId)

    let farmData, govData, sxFarmData
    if (farmInfo[getChainIds().polygonChainId]) {
        farmData = farmInfo[getChainIds().polygonChainId]['LFIWETH']
    }
    if (farmInfo[getChainIds().sxChainId]) {
        sxFarmData = farmInfo[getChainIds().sxChainId]['LFISX']
    }
    if(governanceInfo) {
        govData = governanceInfo
    }

    let tempCastedFarmData: IPoolTokenV2 = {
        name: 'LFIWETH',
        ticker: 'WETH/LFI',
        liquidity: farmData?.liquidity || 0,
        myLiquidity: 0,
        allowance: 0,
        walletBalance: 0,
        tokenPrice: farmData?.lpTokenPrice,
        yesterdaysLiquidity: 0,
        yesterdaysTokenPrice: 0,
        virtualPrice: farmData?.lpTokenPrice,
        chain: getChainIds().polygonChainId,
        APR: farmData?.APR
    }

    let tempCastedSXFarmData: IPoolTokenV2 = {
        name: 'LFISX',
        ticker: 'SX/LFI',
        liquidity: sxFarmData?.liquidity || 0,
        myLiquidity: 0,
        allowance: 0,
        walletBalance: 0,
        tokenPrice: sxFarmData?.lpTokenPrice,
        yesterdaysLiquidity: 0,
        yesterdaysTokenPrice: 0,
        virtualPrice: sxFarmData?.lpTokenPrice,
        chain: getChainIds().sxChainId,
        APR: sxFarmData?.APR
    }

    let tempCastedGovernanceData: IPoolTokenV2 = {
        name: 'LFI',
        ticker: 'LFI',
        liquidity: govData?.totalLiquidity || 0,
        myLiquidity: 0,
        allowance: 0,
        walletBalance: 0,
        tokenPrice: govData?.tokenPrice,
        yesterdaysLiquidity: 0,
        yesterdaysTokenPrice: 0,
        virtualPrice: 0,
        chain: getChainIds().polygonChainId,
        APR: govData?.APR
    }

    combinedData[vLfiIndex] = tempCastedGovernanceData
    combinedData[farmIndex] = tempCastedFarmData
    combinedData[sxFarmIndex] = tempCastedSXFarmData
    return combinedData

}