import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IFarmToken } from "../models/tokenModel";
import {
  calculateTotalPendingHarvest,
  mapFarmData,
} from "../services/utils/commonUtils";
import ReactGA from "react-ga";
import {
  BoxHeading,
  BannerMedia,
  FarmItem,
  BannerInfo,
  ConnectSubmit,
  Grid,
  LayoutWrapper,
} from "lunafi-ui";
import { farmsConfig, networks } from "../config/farmsConfig";
import {
  FARM_APPROVE,
  FARM_INVEST,
  FARM_HARVEST,
  FARM_WITHDRAW,
  DEPRECATED_FARM_HARVEST,
  DEPRECATED_FARM_WITHDRAW,
  DEPRECATED_FARM_APPROVE,
  DEPRECATED_FARM_INVEST,
} from "../store/farms/actionTypes";
import { submitTransaction } from "../store";
import { truncateNumberInString } from "../services/utils/floatDecimalHandler";
import { getChainIds } from "../config/walletConfig";

export function Farm(props: any) {
  ReactGA.pageview(window.location.pathname);
  const dispatch = useDispatch();

  const { connected } = useSelector((state: any) => state.Wallet);
  const { farmInfo, deprecatedFarmInfo } = useSelector(
    (state: any) => state.Farms
  );

  // const [chainId, setChainId] = useState(-1)
  const [farmTokens, setFarmTokens] = useState<IFarmToken[]>([]);
  const [deprecatedFarmTokens, setDeprecatedFarmTokens] = useState<
    IFarmToken[]
  >([]);
  // const [totalPendingHarvest, seyTotalPendingHarvest] = useState(0)

  // useEffect(() => {
  //   connected && getCurrentChain() && setChainId(getCurrentChain())
  // }, [getCurrentChain()])

  useEffect(() => {
    // if (data.length) seyTotalPendingHarvest(calculateTotalPendingHarvest(data))
    setFarmTokens(mapFarmData(farmInfo));
  }, [farmInfo]);

  // const getFarmData = (ticker: string) => {
  //   return farmTokens.find(farmToken => farmToken.ticker === ticker)
  // }

  const dispatchSubmitTransaction = (
    currentCall: string,
    ticker: string,
    amount: any,
    chainId: number
  ) => {
    dispatch(
      submitTransaction({
        currentCall: currentCall,
        poolType: ticker,
        amount: truncateNumberInString(amount, 6),
        chainId: chainId,
      })
    );
  };

  const onsubmit = (amount: any, data: any) => {
    if (data.allowance) {
      amount !== undefined &&
        amount > 0 &&
        amount <= data.walletBalance &&
        dispatchSubmitTransaction(
          FARM_INVEST,
          data.ticker,
          amount.toString(),
          data.chain
        );
    } else {
      dispatch(
        submitTransaction({
          currentCall: FARM_APPROVE,
          poolType: data.ticker,
          chainId: data.chain,
        })
      );
    }
  };

  const onsubmit_deprecated = (amount: any, data: any) => {
    if (data.allowance) {
      amount !== undefined &&
        amount > 0 &&
        amount <= data.walletBalance &&
        dispatchSubmitTransaction(
          DEPRECATED_FARM_INVEST,
          data.ticker,
          amount.toString(),
          data.chain
        );
    } else {
      dispatch(
        submitTransaction({
          currentCall: DEPRECATED_FARM_APPROVE,
          poolType: data.ticker,
          chainId: data.chain,
        })
      );
    }
  };
  return (
    <LayoutWrapper>
      <Grid container md={8} sm={7} spacing={9} xs={6}>
        <Grid item>
          <BannerMedia
            description="You can withdraw your LP tokens at anytime "
            header="Stake LP, earn rewards"
            href="https://docs.lunafi.io/lunafi/"
            media={{ img: "/img/general/farm_banner.jpg" }}
          />
          {!connected && (
            <>
              <br />
              <BannerInfo
                button={<ConnectSubmit text="Connect Wallet" />}
                text="Your wallet is not connected"
              />
            </>
          )}
        </Grid>

        <Grid item>
          <BoxHeading heading="Farm ETH / HBTS">
            {farmTokens.map((farmToken) => (
              <FarmItem
                name={farmToken.name}
                label={farmToken.ticker}
                network={networks.ethereum}
                deposited={
                  Math.round(
                    farmToken.myLiquidity * farmToken.lpTokenPrice * 100
                  ) / 100
                }
                tvl={Math.round(farmToken.totalValueLocked * 100) / 100}
                icon="/img/form/eth-luna.svg"
                dailyRewards={Math.round(farmToken.dailyLfiRewards * 100) / 100}
                apy={Math.round(farmToken.APR * 100) / 100}
                form={{
                  disabled: !connected || farmToken.walletBalance === 0,
                  balance: connected
                    ? Math.round(farmToken.walletBalance * 100) / 100
                    : 0,
                  max: farmToken.walletBalance,
                  min: 0,
                  onSubmit: (amount: any) =>
                    connected && onsubmit(amount, farmToken),
                  submitText: !connected
                    ? "Connect Wallet"
                    : farmToken.allowance
                    ? "Deposit"
                    : "Approve",
                }}
                withdraw={{
                  balance: connected
                    ? Math.round(farmToken.myLiquidity * 100) / 100
                    : 0,
                  max: farmToken.myLiquidity,
                  min: 0,
                  onWithdraw: (amount: any) =>
                    connected &&
                    dispatchSubmitTransaction(
                      FARM_WITHDRAW,
                      farmToken.ticker,
                      amount.toString(),
                      farmToken.chain
                    ),
                  // onWithdrawAll: () => dispatchSubmitTransaction(FARM_WITHDRAW_AND_HARVEST, farmToken.ticker, farmToken.myLiquidity.toString(), farmToken.chain)
                }}
                rewards={{
                  value: connected
                    ? Math.round(farmToken.pendingHarvest * 100) / 100
                    : 0,
                  onAddLiquidity: () =>
                    window.open(
                      farmsConfig[getChainIds().polygonChainId].LFIWETH
                        .addLiquidity,
                      "_blank",
                      "noopener,noreferrer"
                    ),
                  onClaimRewards: () =>
                    connected &&
                    dispatchSubmitTransaction(
                      FARM_HARVEST,
                      farmToken.ticker,
                      farmToken.pendingHarvest.toString(),
                      farmToken.chain
                    ),
                  onRemoveLiquidity: () =>
                    window.open(
                      farmsConfig[getChainIds().polygonChainId].LFIWETH
                        .removeLiquidity,
                      "_blank",
                      "noopener,noreferrer"
                    ),
                }}
              />
            ))}
          </BoxHeading>
        </Grid>

        {/* <Grid item>
        <BoxHeading heading="Old Farm ETH / LFI">
          {deprecatedFarmTokens.map((farmToken) => <FarmItem
            name={farmToken.name}
            label={farmToken.ticker}
            network={networks.matic}

            deposited={Math.round(farmToken.myLiquidity * farmToken.lpTokenPrice * 100) / 100}
            tvl={Math.round(farmToken.totalValueLocked * 100) / 100}

            icon="/img/form/eth-luna.svg"
            dailyRewards={Math.round(farmToken.dailyLfiRewards * 100) / 100}

            apy={Math.round(farmToken.APR * 100) / 100}

            form={{
              disabled: true,
              balance: connected ? Math.round(farmToken.walletBalance * 100) / 100 : 0,
              max: farmToken.walletBalance,
              min: 0,
              onSubmit: (amount: any) => connected && onsubmit_deprecated(amount, farmToken),
              submitText: !connected ? "Connect Wallet" : (farmToken.allowance ? 'Deposit' : 'Approve')
            }}

            withdraw={{
              balance: connected ? Math.round(farmToken.myLiquidity * 100) / 100 : 0,
              max: farmToken.myLiquidity,
              min: 0,
              onWithdraw: (amount: any) => connected && dispatchSubmitTransaction(DEPRECATED_FARM_WITHDRAW, farmToken.ticker, amount.toString(), farmToken.chain),
            }}

            rewards={{
              value: connected ? Math.round(farmToken.pendingHarvest * 100) / 100 : 0,
              onAddLiquidity: () => window.open(farmsConfig[getChainIds().polygonChainId].LFIWETH.deprecated_addLiquidity, '_blank', 'noopener,noreferrer'),
              onClaimRewards: () => connected && dispatchSubmitTransaction(DEPRECATED_FARM_HARVEST, farmToken.ticker, farmToken.pendingHarvest.toString(), farmToken.chain),
              onRemoveLiquidity: () => window.open(farmsConfig[getChainIds().polygonChainId].LFIWETH.deprecated_removeLiquidity, '_blank', 'noopener,noreferrer'),
            }}
          />)}
        </BoxHeading>
      </Grid> */}
      </Grid>
    </LayoutWrapper>
  );
}

export default Farm;
