import { Box } from "@mui/system";
import { useSelector } from 'react-redux'
import { lightColors, darkColors } from '../../theme/colors'

export function RegularBox(props: any) {
    const { darkMode } = useSelector((state: any) => state.Modals)
    const sx = props.sx ? props.sx : {}
    const borderStyle = {
        backgroundColor: darkMode ? darkColors.contentBackground : lightColors.contentBackground,
        border: '1px solid ' + (darkMode ? darkColors.border : lightColors.border),
        borderRadius: '8px',
        textAlign: 'left',
        display: 'inherit',
        float: 'left',
        position: 'relative',
        overflow: 'hidden',
    } as const
    const disableStyle = {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(9,9,9,0.5)',
        zIndex: '5',
        top: '0',
        left: '0',
        // cursor: 'not-allowed',
    } as const
    const style = { ...borderStyle, ...sx }

    return (
        <Box sx={style} onClick={props.disabled && props.onClick}>
            {props.disabled && <Box sx={disableStyle} />}
            {props.children}
        </Box>
    );
}

export default RegularBox;