import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga";
import { networks } from "../config/farmsConfig";
import {
  LayoutTwoColumnTabs,
  FormAmount,
  PoolFormHeader,
  BannerMedia,
  TokenReward,
  FinanceTabValue,
  FinanceTabChart,
  FinancePeriodsChart,
  Grid,
} from "lunafi-ui";
import { getChainIds } from "../config/walletConfig";
import { submitTransaction, _getGlobalValues, _getVolume } from "../store";
import { IProps as IFinancePeriodsChartProps } from "lunafi-ui/dist/components/composed/finance/periods-chart/types";
import {
  GOVERNANCE_INVEST,
  GOVERNANCE_APPROVE,
  GOVERNANCE_CLAIM,
  GOVERNANCE_START_COOLDOWN,
} from "../store/governance/actionTypes";
import { POOL_WITHDRAW, POOL_WITHDRAW_MAX } from "../store/pools/actionTypes";

function Governance(props: any) {
  ReactGA.pageview(window.location.pathname);
  const dispatch = useDispatch();
  const transactions = useSelector((state: any) => state.Pools.transactions);
  const globalPoolData = useSelector(
    (state: any) => state.Pools.globalValues.globalValues.LFI
  );
  // lets keep token and dollar values in a separate objects for readability
  const tokenAmounts = globalPoolData;
  const dollarValues = globalPoolData.Dollar;
  const {
    graphTVLData,
    graphPNL7DaysData,
    graphPNL30DaysData,
    graphLiquidityData,
    graphPendingStakesData,
  } = useSelector((state: any) => state.Pools.graph);
  const connected = useSelector((state: any) => state.Wallet.connected);
  const poolInfo = useSelector(
    (state: any) => state?.Pools?.poolInfo[getChainIds()?.polygonChainId]?.LFI
  );
  const cooldown = useSelector(
    (state: any) => state.Governance.LFIToken.cooldown
  );
  const pendingHarvest = useSelector(
    (state: any) => state.Governance.LFIToken.pendingHarvest
  );
  const tokenUSDPrice = tokenAmounts.TokenPrice;
  const [inputAmount, setInputAmount] = useState(0);
  const [netWithdrawalUSDAmount, setNetWithdrawalUSDAmount] = useState(0);
  const [userShare, setUserShare] = useState(0);
  const [temperaryDepositShare, setTemperaryDepositShare] = useState(0);
  const [lostShare, setLostShare] = useState(0);
  const [remainingShare, setRemainingShare] = useState(0);
  const [myDeposit, setMyDeposit] = useState(0);
  const [transactionType, setTransactionType] = useState<
    "approval" | "deposit" | "countdown" | "withdraw"
  >(poolInfo && poolInfo?.allowance !== 0 ? "deposit" : "approval");
  useEffect(() => {
    if (poolInfo) {
      if (poolInfo?.totalSupply === 0) {
        setUserShare(0);
      } else {
        setUserShare(poolInfo?.myLPTokenAmount / poolInfo?.totalSupply);
        setMyDeposit(
          Number(poolInfo?.myLPTokenAmount * tokenAmounts?.LPPrice) || 0
        );
      }
    }
  }, [poolInfo, connected]);
  useEffect(() => {
    if (poolInfo) {
      if (poolInfo?.totalSupply === 0) {
        setTemperaryDepositShare(0);
        setLostShare(0);
        setRemainingShare(0);
      } else {
        const tempLP = inputAmount / Number(tokenAmounts.LPPrice);
        setTemperaryDepositShare(
          (poolInfo?.myLPTokenAmount + tempLP) /
            (poolInfo?.totalSupply + tempLP)
        );
        setLostShare(tempLP / poolInfo?.totalSupply);
        setRemainingShare(
          (poolInfo?.myLPTokenAmount - tempLP) / poolInfo?.totalSupply
        );
        const adjustedLiquidity =
          tokenAmounts?.Liquidity -
          Number(tokenAmounts?.ME) -
          Number(tokenAmounts?.PendingStakes);
        const effectiveMaxWithdrawl = poolInfo
          ? Math.min(adjustedLiquidity, poolInfo?.maxWithdrawl)
          : 0;
        const netWithdrawalUSDAmount = poolInfo
          ? inputAmount < effectiveMaxWithdrawl
            ? inputAmount * tokenUSDPrice
            : effectiveMaxWithdrawl * tokenUSDPrice
          : 0;
        setNetWithdrawalUSDAmount(netWithdrawalUSDAmount);
      }
    }
  }, [inputAmount]);

  useEffect(() => {
    if (transactions && transactions.length > 0) {
      if (transactions[transactions.length - 1].callStatus === "COMPLETED") {
        console.log("catch COMPLETED");
        if (depositStatus === "pending") {
          setDepositStatus("success");
          setRefresh(true);
        }
        if (withdrawStatus === "pending") {
          setWithdrawStatus("success");
          setRefresh(true);
        }
      }
      if (transactions[transactions.length - 1].callStatus === "FAILED") {
        console.log("catch FAILED");
        if (depositStatus === "pending") {
          setDepositStatus("confirm");
        }
        if (withdrawStatus === "pending") {
          setUnstakeTransactionFailed(true);
          setWithdrawStatus("confirm");
        }
      }
    }
  }, [
    transactions,
    transactions.length,
    transactions.length > 0 && transactions[transactions.length - 1].callStatus,
  ]);

  const [allowance, setAllowance] = useState(false);
  const onsubmit = (actionType: string, amount?: number) => {
    if (actionType === "approve") {
      dispatch(
        submitTransaction({
          currentCall: GOVERNANCE_APPROVE,
          poolType: "HBTS",
          chainId: getChainIds().polygonChainId,
          disableModal: true,
        })
      );
      setAllowance(true);
    } else if (actionType === "deposit") {
      dispatch(
        submitTransaction({
          currentCall: GOVERNANCE_INVEST,
          poolType: "HBTS",
          amount: amount ?? 0,
          chainId: getChainIds().polygonChainId,
          disableModal: true,
        })
      );
      setAllowance(false);
    } else if (actionType === "claim") {
      dispatch(
        submitTransaction({
          currentCall: GOVERNANCE_CLAIM,
          poolType: "HBTS",
          chainId: getChainIds().polygonChainId,
          disableModal: true,
        })
      );
    } else if (actionType === "cooldown") {
      dispatch(
        submitTransaction({
          currentCall: GOVERNANCE_START_COOLDOWN,
          poolType: "HBTS",
          amount: "",
          chainId: getChainIds().polygonChainId,
          disableModal: true,
        })
      );
    } else if (actionType === "withdraw") {
      if (amount! >= poolInfo?.maxWithdrawl) {
        dispatch(
          submitTransaction({
            currentCall: POOL_WITHDRAW_MAX,
            poolType: "HBTS",
            chainId: getChainIds().polygonChainId,
            disableModal: true,
          })
        );
      } else {
        dispatch(
          submitTransaction({
            currentCall: POOL_WITHDRAW,
            poolType: "HBTS",
            amount: amount ?? 0,
            chainId: getChainIds().polygonChainId,
            disableModal: true,
          })
        );
      }
    }
  };

  const poolFormHeader = (
    <PoolFormHeader
      apy={{
        lfiValue: Math.round(tokenAmounts.APR * 100) / 100,
        value: Math.round(tokenAmounts.Earnings7Day * 100) / 100,
      }}
      img="/img/staking/lfi.svg"
      name="HBTS"
      network={networks.ethereum}
    />
  );
  const chart_color_yellow: IFinancePeriodsChartProps["chartProps"] = {
    axis: { x: false, y: false },
    grid: { x: true, y: false },
    bg: {
      colorEnd: "#F2B743",
      colorEndAlpha: 0,
      colorStart: "#F2B743",
      colorStartAlpha: 0,
    },
    line: {
      colorEnd: "#F2B743",
      colorEndAlpha: 1,
      colorStart: "#F2B743",
      colorStartAlpha: 1,
    },
  };
  const chart_color_pink = {
    bg: {
      colorEnd: "#F2B743",
      colorEndAlpha: 0,
      colorStart: "#F2B743",
      colorStartAlpha: 0,
    },
    dot: { fill: "#FFFFFF", r: 6, stroke: "#6C0959", strokeWidth: 3 },
    line: {
      colorEnd: "#2D27FF",
      colorEndAlpha: 1,
      colorStart: "#FF0A6C",
      colorStartAlpha: 1,
    },
  };

  const getButtonStatus = () => {
    if (!cooldown) return "";
    if (cooldown.cooldownType === "Cooldown") {
      return "cooldown";
    } else if (
      cooldown.cooldownType === "Unstake" ||
      cooldown.cooldownState === false
    ) {
      return "unstake";
    } else {
      return "active";
    }
  };
  const [countdown, setCountdown] = useState("");
  const [buttonStatus, setButtonStatus] = useState<
    "active" | "cooldown" | "unstake" | ""
  >(getButtonStatus());

  useEffect(() => {
    setButtonStatus(getButtonStatus());
  }, [cooldown || (cooldown && cooldown.cooldownType)]);

  const getCountDownInformation = () => {
    if (poolInfo.allowance === 0) {
      return "Approve on Stake Tab!";
    }
    return "Unstake";
    if (buttonStatus === "cooldown") {
      return "Cooldown " + countdown;
    } else if (buttonStatus === "unstake") {
      return "Unstake " + countdown;
    } else if (buttonStatus === "active") {
      return "Activate Cooldown";
    } else {
      return "Connect Wallet";
    }
  };

  const countDownString = (unixTimestamp: number) => {
    const difference = unixTimestamp - new Date().getTime();
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    const toDoubleDigit = (number: number) => String(number).padStart(2, "0");

    return `
  ${toDoubleDigit(days)} :
  ${toDoubleDigit(hours)} :
  ${toDoubleDigit(minutes)} :
  ${toDoubleDigit(seconds)}
  `;
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (cooldown && buttonStatus !== "active") {
        const timeDiffCooldown =
          new Date(cooldown.cooldownEndTimeStamp).getTime() -
          new Date().getTime();
        const timeDiffUnstake =
          new Date(cooldown.unstakeWindowEndTimeStamp).getTime() -
          new Date().getTime();
        if (timeDiffUnstake <= 0) {
          setButtonStatus("active");
        } else {
          if (timeDiffCooldown <= 0) setButtonStatus("unstake");
          setCountdown(
            timeDiffCooldown <= 0
              ? countDownString(cooldown.unstakeWindowEndTimeStamp)
              : countDownString(cooldown.cooldownEndTimeStamp)
          );
        }
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [buttonStatus]);

  const [depositStatus, setDepositStatus] = useState<
    "success" | "confirm" | "pending" | "default"
  >("default");
  const [withdrawStatus, setWithdrawStatus] = useState<
    "success" | "confirm" | "pending" | "default"
  >("default");

  const [unstakeTransactionFailed, setUnstakeTransactionFailed] =
    useState(false);
  const activeCooldownConfirm = {
    continueName: "Activate Cooldown",
    description: (
      <>
        Expected Value Lost is high. It is advised to return later once more
        bets have been settled. <a href="/">Learn More</a>
      </>
    ),
    onCancel: () => {
      setWithdrawStatus("default");
    },
    onContinue: () => {
      setWithdrawStatus("pending");
      setTransactionType("countdown");
      onsubmit("cooldown", inputAmount);
    },
    title: "Warning",
  };

  const withdrawConfirm = {
    continueName: "Confirm Withdrawal",
    description: (
      <>
        Please check Expected Value Lost carefully. If the figure is high, It's
        advised to return later once more bets have settled. Learn More{" "}
        <a href="https://docs.lunafi.io/lunafi/faqs-tutorials/house-pools">
          Learn More
        </a>
      </>
    ),
    onCancel: () => {
      setWithdrawStatus("default");
    },
    onContinue: () => {
      setWithdrawStatus("pending");
      setTransactionType("withdraw");
      onsubmit("withdraw", inputAmount);
    },
    title: "Warning",
  };

  const unstakeFailedConfirm = {
    continueName: "Plese Try again",
    description: <> </>,
    onCancel: () => {
      setWithdrawStatus("default");
      setUnstakeTransactionFailed(false);
    },
    onContinue: () => {
      setWithdrawStatus("default");
      setUnstakeTransactionFailed(false);
    },
    title: "Transaction Failed",
  };

  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    if (refresh) {
      dispatch(_getGlobalValues({ refresh: true }));
      dispatch(_getVolume());
      setRefresh(false);
    }
  }, [refresh]);

  return (
    <div>
      <LayoutTwoColumnTabs
        asideTabs={[
          {
            children: (
              <FormAmount
                description={`${
                  Number(tokenAmounts?.LPPrice ?? 0)?.toFixed(5) ?? 0
                } HBTS = 1 vHBTS`}
                header={poolFormHeader}
                info={[
                  {
                    name: "Liquidity",
                    tooltip:
                      "Net deposits + P&L of settled bets + pending stakes",
                    value: connected
                      ? Number(dollarValues.Liquidity) * userShare +
                        inputAmount * tokenUSDPrice
                      : 0,
                    numberFormat: { prefix: "$", rightFixed: 2 },
                    color: "green",
                  },
                  {
                    name: "Total Rewards",
                    tooltip: "Total rewards",
                    value: connected
                      ? Number(dollarValues.PendingStakes) *
                        temperaryDepositShare
                      : 0,
                    numberFormat: { prefix: "$", rightFixed: 2 },
                    color: "red",
                  },
                  {
                    name: "Total Value Locked",
                    tooltip: "Liquidity + EV - Pending Stakes",
                    value: connected
                      ? Number(dollarValues.TVL) * userShare +
                        inputAmount * tokenUSDPrice
                      : 0,
                    numberFormat: { prefix: "$", rightFixed: 2 },
                    color: "green",
                  },
                ]}
                input={
                  poolInfo?.allowance
                    ? {
                        icon: "/img/staking/lfi.svg",
                        label: "Amount to stake",
                        max: poolInfo?.walletBalance ?? 0,
                        min: 0,
                        onChange: (value: any) => {
                          if (value) {
                            setInputAmount(value);
                          } else {
                            setInputAmount(0);
                          }
                        },
                        value: inputAmount,
                      }
                    : undefined
                }
                status={depositStatus}
                submit={{
                  isDisabled:
                    !connected ||
                    (poolInfo?.allowance !== 0 &&
                      (inputAmount <= 0 ||
                        inputAmount > poolInfo?.walletBalance)),
                  name: connected
                    ? poolInfo
                      ? poolInfo?.allowance === 0
                        ? "Approve"
                        : "Deposit"
                      : "Loading"
                    : "Connect Wallet",
                  onSubmit: () => {
                    poolInfo?.allowance
                      ? onsubmit("deposit", inputAmount)
                      : onsubmit("approve");
                    setDepositStatus("pending");
                  },
                }}
                confirm={{
                  continueName: "Please Try Again",
                  description: <> </>,
                  onCancel: () => setDepositStatus("default"),
                  onContinue: () => setDepositStatus("default"),
                  title: "Transaction Failed",
                }}
                success={{
                  additionalText: (
                    <span>
                      {" "}
                      Your New Balance: <b>
                        {poolInfo?.walletBalance} HBTS
                      </b>{" "}
                    </span>
                  ),
                  amount: allowance
                    ? undefined
                    : {
                        numberFormat: {
                          suffix: " HBTS",
                          rightFixed: 2,
                        },
                        value: inputAmount,
                      },
                  description: allowance
                    ? "Your deposit has been processed."
                    : "Your approval has been processed.",
                  onDoneClick: () => {
                    setDepositStatus("default");
                  },
                  title: "Success!",
                }}
              />
            ),
            key: 3,
            name: "Stake",
          },
          {
            children: (
              <FormAmount
                description={`${
                  Number(tokenAmounts?.LPPrice)?.toFixed(5) ?? 0
                } LFI = 1 vLFI`}
                header={poolFormHeader}
                info={[
                  {
                    name: "Adjusted Liquidity",
                    tooltip:
                      "Liquidity that can be withdrawn less max exposure & pending stakes",
                    value:
                      dollarValues.Liquidity -
                      Number(dollarValues.ME) -
                      Number(dollarValues.PendingStakes),
                    numberFormat: { prefix: "$", rightFixed: 2 },
                    color: "green",
                  },
                  {
                    name: "Remaining TVL",
                    tooltip: "Your TVL left after entered withdrawal",
                    value:
                      dollarValues.TVL * remainingShare < 0
                        ? 0
                        : dollarValues.TVL * remainingShare,
                    numberFormat: { prefix: "$", rightFixed: 2 },
                    color: "green",
                  },
                  {
                    name: "Expected Value Lost",
                    tooltip:
                      "You lose any positive EV relative to your deposit EV when exiting the pool",
                    value: Number(dollarValues.EV) * lostShare,
                    numberFormat: { prefix: "$", rightFixed: 2 },
                    color: "red",
                  },
                  {
                    name: "Net Withdrawal Amount",
                    value: netWithdrawalUSDAmount,
                    numberFormat: { prefix: "$", rightFixed: 2 },
                  },
                ]}
                input={{
                  icon: "/img/staking/lfi.svg",
                  label: "Amount to unstake",
                  max: poolInfo ? poolInfo?.maxWithdrawl : 0,
                  min: 0,
                  onChange: (value: any) => {
                    if (value) {
                      setInputAmount(value);
                    } else {
                      setInputAmount(0);
                    }
                  },
                  value: inputAmount,
                }}
                status={withdrawStatus}
                submit={{
                  isDisabled:
                    !connected ||
                    poolInfo?.allowance <= 0 ||
                    !cooldown ||
                    poolInfo?.maxWithdrawl <= 0 ||
                    buttonStatus === "cooldown",
                  name: connected
                    ? getCountDownInformation()
                    : "Connect Wallet",
                  onSubmit: () => {
                    if (Number(dollarValues?.EV) <= 0) {
                      // if (cooldown.cooldownType === "") {
                      //   setWithdrawStatus("pending");
                      //   setTransactionType("countdown");
                      //   onsubmit("cooldown", inputAmount);
                      // }
                      // else {
                      setWithdrawStatus("pending");
                      setTransactionType("withdraw");
                      onsubmit("withdraw", inputAmount);
                      // }
                    } else {
                      setWithdrawStatus("confirm");
                    }
                  },
                }}
                confirm={
                  cooldown
                    ? unstakeTransactionFailed
                      ? unstakeFailedConfirm
                      : cooldown.cooldownType === ""
                      ? activeCooldownConfirm
                      : withdrawConfirm
                    : undefined
                }
                success={{
                  additionalText: (
                    <span>
                      Your Balance: <b>{poolInfo?.walletBalance} LFI</b>
                    </span>
                  ),
                  amount:
                    transactionType === "countdown"
                      ? undefined
                      : {
                          numberFormat: {
                            suffix: " LFI",
                            rightFixed: 2,
                          },
                          value: inputAmount,
                        },
                  description:
                    transactionType === "countdown"
                      ? "Your cooldown has been activated."
                      : "Your withdraw has been processed.",
                  onDoneClick: () => {
                    setWithdrawStatus("default");
                  },
                  title: "Success!",
                }}
              />
            ),
            key: 4,
            name: "Unstake",
          },
        ]}
        contentTabs={[
          {
            children: (
              <Grid container spacing={4}>
                <Grid item>
                  <BannerMedia
                    description={
                      <>
                        HBTS holders can stake their HBTS in the Safety Module to
                        add more security to the protocol and earn Safety
                        Incentives. This pool also accepts all bets in HBTS. In
                        the case of a shortfall event,{" "}
                        <b>up to 30% of your stake</b> can be slashed to cover
                        the deficit, providing an additional layer of protection
                        for the protocol.
                      </>
                    }
                    header="Stake HBTS, earn rewards"
                    href="https://docs.lunafi.io/lunafi/"
                    kind="large"
                    media={{ img: "/img/staking/banner.jpg" }}
                  />
                </Grid>
                <Grid item>
                  <FinancePeriodsChart
                    chartProps={{ tooltip: { fill: "#00FFF4", text: "HBTS" } }}
                    numberFormat={{ prefix: "$" }}
                    periods={[
                      {
                        data: graphTVLData.LFI ? graphTVLData.LFI.Day : [],
                        value: dollarValues.TVL,
                        key: "day",
                        name: "1D",
                      },
                      {
                        data: graphTVLData.LFI ? graphTVLData.LFI.Week : [],
                        value: dollarValues.TVL,
                        key: "week",
                        name: "1W",
                      },
                      {
                        data: graphTVLData.LFI ? graphTVLData.LFI.Month : [],
                        value: dollarValues.TVL,
                        key: "month",
                        name: "1M",
                      },
                      {
                        data: graphTVLData.LFI ? graphTVLData.LFI.Year : [],
                        value: dollarValues.TVL,
                        key: "year",
                        name: "1Y",
                      },
                    ]}
                    title="TVL"
                  />
                </Grid>
                <Grid item size={6} xs={12}>
                  <FinanceTabValue
                    info="My Current Deposit(Tokens)"
                    numberFormat={{ prefix: "", rightFixed: 4 }}
                    tabs={[
                      {
                        key: "Tokens",
                        name: "Tokens",
                        value: connected ? myDeposit : 0,
                      },
                    ]}
                    title="My Deposit"
                  />
                </Grid>
                <Grid item size={6} xs={12}>
                  <FinanceTabValue
                    info="My Current Deposit(USD)"
                    numberFormat={{ prefix: "$", rightFixed: 2 }}
                    tabs={[
                      {
                        key: "USD",
                        name: "USD",
                        value: connected ? myDeposit * tokenUSDPrice : 0,
                      },
                    ]}
                    title="My Deposit"
                  />
                </Grid>
                <Grid item size={6} xs={12}>
                  <FinanceTabValue
                    // info="Tooltip text"
                    numberFormat={{ suffix: " Days" }}
                    tabs={[{ key: "default", name: "default", value: 7 }]}
                    title="Cooldown Period"
                  />
                </Grid>
                <Grid item size={6} xs={12}>
                  <FinanceTabValue
                    info="Stakes from Housebets users on unsettled bets"
                    numberFormat={{ prefix: "$" }}
                    tabs={[
                      {
                        key: "default",
                        name: "default",
                        value: Number(dollarValues?.PendingStakes),
                      },
                    ]}
                    title="Total Rewards"
                  />
                </Grid>
                <Grid item size={6} xs={12}>
                  <FinanceTabChart
                    chartProps={chart_color_yellow}
                    info="Returns from settled bets"
                    numberFormat={{ suffix: "%" }}
                    tabs={[
                      {
                        data: graphPNL30DaysData.LFI,
                        key: "30 Days",
                        name: "30 Days",
                        value: Number(tokenAmounts.Earnings30Day),
                      },
                      {
                        data: graphPNL7DaysData.LFI,
                        key: "7 Days",
                        name: "7 Days",
                        value: Number(tokenAmounts.Earnings7Day),
                      },
                    ]}
                    title="APY"
                  />
                </Grid>
                <Grid item size={6} xs={12}>
                  <FinanceTabChart
                    chartProps={chart_color_yellow}
                    info="All-time rewards given to this pool"
                    numberFormat={{ prefix: "$" }}
                    tabs={[
                      {
                        data: graphPendingStakesData?.LFI
                          ? graphPendingStakesData?.LFI?.Month
                          : [],
                        key: "30 Days",
                        name: "30 Days",
                        value: Number(dollarValues?.PendingStakes),
                      },
                      {
                        data: graphPendingStakesData?.LFI
                          ? graphPendingStakesData?.LFI?.Week
                          : [],
                        key: "7 Days",
                        name: "7 Days",
                        value: Number(dollarValues?.PendingStakes),
                      },
                    ]}
                    title="Total Rewards"
                  />
                </Grid>
              </Grid>
            ),
            key: 0,
            name: "Overview",
          },
          {
            children: (
              <TokenReward
                buttons={{
                  onClick: () => {
                    if (Number(pendingHarvest) > 0) onsubmit("claim");
                  },
                }}
                numberFormat={{ rightFixed: 2, suffix: " HBTS" }}
                value={Number(pendingHarvest)}
              />
            ),
            key: 1,
            name: "Claimable HBTS *",
          },
        ]}
      />
    </div>
  );
}

export default Governance;
