export const FooterConfig = {
    menu: [
        {
            links: [
                {
                    href: '/',
                    name: 'Home'
                },
                {
                    href: '/pools',
                    name: 'Pools'
                },
                {
                    href: '/farms',
                    name: 'Farms'
                },
                {
                    href: '/staking',
                    name: 'Staking'
                }
            ],
            name: 'Product'
        },
        {
            links: [
                {
                    href: 'https://t.me/HousebetsOfficial',
                    isExternal: true,
                    name: 'Telegram'
                },
                {
                    href: 'https://twitter.com/Housebetscom',
                    isExternal: true,
                    name: 'Twitter'
                },
                {
                    href: 'https://discord.gg/j2wDzZCshq',
                    isExternal: true,
                    name: 'Discord'
                },
                {
                    href: 'https://www.linkedin.com/company/lunafi-project/',
                    isExternal: true,
                    name: 'LinkedIn'
                }
            ],
            name: 'Follow us'
        },
        {
            links: [
                {
                    href: 'https://docs.housebets.com/lunafi/',
                    isExternal: true,
                    name: 'Docs'
                },
                {
                    href: 'https://housebets.com/#home-faq',
                    isExternal: true,
                    name: 'FAQ\'s'
                }
            ],
            name: 'Support'
        }
    ],
    social: {
        discord: 'https://discord.gg/j2wDzZCshq',
        docs: 'https://docs.housebets.com/',
        linkedin: 'https://www.linkedin.com/company/lunafi-project/',
        telegram: 'https://t.me/HousebetsOfficial',
        twitter: 'https://twitter.com/Housebetscom'
    }
}
