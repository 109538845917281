import {
  fork,
  take,
  put,
  all,
  call
} from "redux-saga/effects"

import { walletConnectInit, disconnect, getPoolsItems, handleOnLoad, fortmaticOperations, addLfiToInjectedWallet } from '../../services/blockchainInterface'

import { DISCONNECT_WALLET, CONNECT_WALLET, WALLET_ON_LOAD, FORTMATIC_OPERATION, ADD_LFI_INJECTED } from "./actionTypes";

import { walletConnected, walletDisConnected } from "./actions";
import { refreshPoolInfo } from "../pools/actions"
import { setWalletUnsupportedChain } from "..";
import { getFarmData, } from "../farms/actions";
import { setConnectModal } from "../actions"
import { getGovernanceData } from "..";

function* WalletSaga() {
  yield all([
    fork(connectWalletWatcher),
    fork(disconnectWalletWatcher),
    fork(walletOnLoadWatcher),
    fork(fortmaticDepositWatcher),
    fork(addLfiInjectedWatcher)
  ])
}

const createWatcher = (worker, type) => {
  return function* () {
    while (true) {
      const action = yield take(type)
      yield fork(worker, action)
    }
  }
}

function* connectWalletWorker(payload) {
  try {
    yield put(setConnectModal(false))
    let walletBalanceInfo = yield call(walletConnectInit, payload.params.walletConnection, payload.params.creds)
    if (walletBalanceInfo) {
      if (walletBalanceInfo.connectedChain === "") {
        yield put(setWalletUnsupportedChain(true))
      }
      yield put(walletConnected(walletBalanceInfo))
      yield put(refreshPoolInfo({ poolInfo: yield call(getPoolsItems) }))
      yield put(getFarmData())
      yield put(getGovernanceData())
    }
    else {
      yield put(walletDisConnected())
    }
  } catch (e) {
    console.log(e)
  }
}

export const connectWalletWatcher = createWatcher(
  connectWalletWorker,
  CONNECT_WALLET
)

function* disconnectWalletWorker(payload) {
  yield call(disconnect)
  yield put(walletDisConnected())
}

export const disconnectWalletWatcher = createWatcher(
  disconnectWalletWorker,
  DISCONNECT_WALLET
)


function* walletOnLoadWorker(payload) {
  let walletBalanceInfo = yield call(handleOnLoad)
  if (walletBalanceInfo) {
    if (walletBalanceInfo.connectedChain === "") {
      yield put(setWalletUnsupportedChain(true))
    }
    yield put(walletConnected(walletBalanceInfo))
    yield put(refreshPoolInfo({ poolInfo: yield call(getPoolsItems)}))
  }
  else {
    yield put(walletDisConnected())
  }
}

export const walletOnLoadWatcher = createWatcher(
  walletOnLoadWorker,
  WALLET_ON_LOAD
)

function* fortmaticDepositWorker(payload) {
  yield call(fortmaticOperations(payload.params.type))
}

export const fortmaticDepositWatcher = createWatcher(
  fortmaticDepositWorker,
  FORTMATIC_OPERATION
)

function* addLfiInjectedWorker() {
  yield call(addLfiToInjectedWallet)
}

export const addLfiInjectedWatcher = createWatcher(
  addLfiInjectedWorker,
  ADD_LFI_INJECTED
)

export default WalletSaga