/* eslint-disable no-extend-native */
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers, BigNumber } from "ethers";
import utils from "./utils/utils";
import {
  blockchainConfig,
  getChainIds,
  txnOperations,
  switchConfig,
  getDeployedEnv,
} from "../config/walletConfig";
import WalletConnection from "../models/enums/WalletConnections";
import poolsConfig from "../config/poolsConfig";
import { farmsConfig } from "../config/farmsConfig";
import store from "../store";
import {
  connectWallet,
  setWalletUnsupportedChain,
  setWrongNetworkModal,
  setLFIPrice,
  refreshWalletInfo,
  setConnectModal,
  getFarmData,
  getGovernanceData,
  getLiquidity,
  setPoolData,
  getChartData,
  setLFIPriceChart,
} from "../store/actions";
import Fortmatic from "fortmatic";
import { Magic } from "magic-sdk";
import {
  POOL_INVEST,
  POOL_APPROVE,
  POOL_WITHDRAW,
  POOL_HARVEST,
  POOL_WITHDRAW_MAX,
} from "../store/pools/actionTypes";
import {
  FARM_APPROVE,
  FARM_INVEST,
  FARM_WITHDRAW,
  FARM_HARVEST,
  FARM_HARVEST_ALL,
  FARM_WITHDRAW_AND_HARVEST,
  DEPRECATED_FARM_APPROVE,
} from "../store/farms/actionTypes";
import {
  GOVERNANCE_APPROVE,
  GOVERNANCE_INVEST,
  GOVERNANCE_WITHDRAW,
  GOVERNANCE_CLAIM,
  GOVERNANCE_START_COOLDOWN,
} from "../store/governance/actionTypes";
import {
  TRANSFER_BASE_TOKEN,
  WALLET_TRANSFER,
  TOKEN_UPGRADE,
} from "../store/wallet/actionTypes";
import { fetchLFIPrice } from "./apiInterface";
import FortmaticOperations from "../models/enums/FortmaticOperations";
import Web3Modal from "web3modal";
import axios from "axios";
import { buildApiBaseUrl, getCurApiVer } from "../util/uri";

// creating async version of forEach
// important reference
// https://advancedweb.hu/how-to-use-async-functions-with-array-foreach-in-javascript/
Array.prototype.forEachAsync = async function (fn) {
  for (let t of this) {
    await fn(t);
  }
};
// Global values to use in all methods
let web3 = null;
let signer = null;
let accounts = null;
let chainId = null;
let connectedWallet = null;
export let magicInstance = null;
let provider;
let defaultChainId = getChainIds().defaultChainId;
let supportedNetworks = getChainIds().supportedNetworks;
let { ethereum } = window;
let globalLfiPrice = 0;
let globalEthPrice = 0;
let circulateSupply = 0;
const delay = ms => new Promise(res => setTimeout(res, ms));
const providerOptions = {
  walletconnect: {
    bridge: "https://bridge.walletconnect.org",

    package: WalletConnectProvider,
    options: {
      rpc: {
        // 1: blockchainConfig[1].rpcUrl,
        // 3: blockchainConfig[3].rpcUrl,
        137: blockchainConfig[137].rpcUrl,
        80001: blockchainConfig[80001].rpcUrl,
      },
    },
  },

  fortmatic: {
    package: Fortmatic,
    options: {
      key: "pk_test_ED753E928F529E1E",
      network: blockchainConfig[80001].FORTMATIC.fortmaticNodeOptions,
    },
  },
};
const web3Modal = new Web3Modal({
  cacheProvider: true,
  providerOptions,
});

let globalValues = null;

const handleChainChanged = async (chainID) => {
  // handle wallet connect network change
  try {
    if (web3) {
      if (chainID.toString().includes("0x")) {
        chainId = parseInt(chainID, 16);
      } else {
        chainId = chainID;
      }
      if (!isChainIdSupported(chainId)) {
        store.dispatch(setWalletUnsupportedChain(true));
      }
      if (isChainIdSupported(chainId)) {
        store.dispatch(getLiquidity());
        store.dispatch(setPoolData());
        store.dispatch(getChartData());
        store.dispatch(setWalletUnsupportedChain(false));
        store.dispatch(setWrongNetworkModal(false));
        store.dispatch(refreshWalletInfo(await getAssets()));
        store.dispatch(getGovernanceData());
        store.dispatch(getFarmData());
      } else {
      }
    }
  } catch (e) {
    console.log("unsupported network error 2");
  }
};

const handleAccountsChanged = async (account) => {
  try {
    if (web3) {
      store.dispatch(
        connectWallet({ walletConnection: connectedWallet, creds: "" })
      );
      // accounts = await web3.listAccounts()
      // signer = await web3.getSigner()
      // console.log(accounts)
      // store.dispatch(getLiquidity())
    }
  } catch (e) {
    console.log("unsupported network error 1");
  }
};

export const handleOnLoad = async () => {
  try {
    const lfiPrice = await getTokenPrice("LFI");
    store.dispatch(setLFIPrice({ price: lfiPrice }));
    store.dispatch(setLFIPriceChart(await fetchLFIPrice()));
    if (web3Modal.cachedProvider && web3Modal.cachedProvider === "injected") {
      provider = await web3Modal.connectTo("injected");
      connectedWallet = "injected";
      web3 = new ethers.providers.Web3Provider(provider, "any");
      accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const network = await web3.getNetwork();
      chainId = network.chainId;
      subscribeProviderEvents();
      await handleChainChanged(chainId);
      signer = await web3.getSigner();
      store.dispatch(setConnectModal(false));
      return getAssets();
    } else {
      store.dispatch(getGovernanceData());
      store.dispatch(getFarmData());
      store.dispatch(getLiquidity());
      store.dispatch(setPoolData());
      store.dispatch(getChartData());
    }
    return "";
  } catch (e) {
    console.log(e);
    return "";
  }
};

export const getCirculatingSupply = async () => {
  try {
    axios
      .get(
        "https://xdg9n4zuel.execute-api.us-east-1.amazonaws.com/dev/api/v1/circulatingSupply"
      )
      .then((res) => {
        circulateSupply = res.data;
        return circulateSupply;
      });
  } catch (e) {
    console.error(e);
    return 0;
  }
};

export const getCoingeckoForLunaFi = async () => {
  try {
    let data;
    await axios
      .get(
        "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=lunafi&order=market_cap_desc&per_page=100&page=1&sparkline=false"
      )
      .then((res) => {
        data = res.data[0];
      });
    return data;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export const getTokenPrice = async (token = "LFI") => {
  if (globalValues?.Pools?.token?.TokenPrice) {
    return globalValues["Pools"][token]["TokenPrice"];
  } else {
    try {
      const res = await axios
        .get(
          "https://l9s0nukb8b.execute-api.us-east-2.amazonaws.com/v1/housepool/pools"
        )
      globalValues = res.data;
      globalLfiPrice = res.data["Pools"]["LFI"]["TokenPrice"];
      globalEthPrice = res.data["Pools"]["WETH"]["TokenPrice"];
      const tokenPrice = res.data["Pools"][token]["TokenPrice"];
      return tokenPrice;
    } catch (e) {
      console.error(e);
      console.log("error getting token price");
    }
  }
};

const isChainIdSupported = (chainId) => {
  if (getChainIds().supportedNetworks.includes(chainId)) {
    return true;
  }
  return false;
};

const subscribeProviderEvents = () => {
  // console.log(connectedWallet)
  try {
    // Subscribe to accounts change
    provider.on("accountsChanged", (accounts) => {
      handleAccountsChanged();
    });

    // Subscribe to chainId change
    provider.on("chainChanged", (chainID) => {
      handleChainChanged(chainID);
    });
  } catch (e) {
    console.log(e);
  }
};

export const disconnect = async () => {
  store.dispatch(setWalletUnsupportedChain(false));
  window.localStorage.removeItem("walletconnect");
  if (provider & provider?.fm) {
    provider.fm.user.logout();
  }
  web3 = null;
  if (web3Modal.cachedProvider) {
    web3Modal.clearCachedProvider();
  }
  if (provider && provider.connected) {
    await provider.disconnect();
  }
  window.localStorage.clear();
};

export const walletConnectInit = async (walletConnection, creds) => {
  try {
    connectedWallet = walletConnection;
    if (connectedWallet === WalletConnection.WALLET_CONNECT) {
      provider = await web3Modal.connectTo("walletconnect");
      web3 = new ethers.providers.Web3Provider(provider, "any");
    }
    if (connectedWallet === WalletConnection.BROWSER_EXTENSION) {
      provider = await web3Modal.connectTo("injected");
      // await window.ethereum.enable()
      // web3 = new ethers.providers.Web3Provider(window.ethereum, "any");
      web3 = new ethers.providers.Web3Provider(provider, "any");
    }
    if (connectedWallet === WalletConnection.FORTMATIC) {
      provider = await web3Modal.connectTo("fortmatic");
      web3 = new ethers.providers.Web3Provider(provider, "any");
    }
    if (connectedWallet === WalletConnection.MAGIC_LINK) {
      magicInstance = new Magic(
        blockchainConfig[defaultChainId].MAGIC_LINK.API_KEY,
        blockchainConfig[defaultChainId].MAGIC_LINK.magicLinkNodeOptions
      );
      await magicInstance.auth.loginWithMagicLink({ email: creds });
      web3 = new ethers.providers.Web3Provider(magicInstance.rpcProvider);
      // meta data for the user in case required later
      // const userMetadata = await magicInstance.user.getMetadata();
    }
    subscribeProviderEvents();
    const network = await web3.getNetwork();
    chainId = network.chainId;
    await handleChainChanged(chainId);
    signer = await web3.getSigner();

    // retrieving accounts an
    if (connectedWallet === WalletConnection.MAGIC_LINK) {
      accounts = [await signer.getAddress()];

      // eth balance mechanism for magic link, for later use
      // console.log({ ethBalance })
    } else {
      accounts = await web3.listAccounts();
    }
    return getAssets();
  } catch (e) {
    // force a disconnect to clear out the corrupt connnection
    disconnect();
    // attempt to initialise the provider to make a new connection
  }
};

export const getAccount = async () => {
  if (!!!web3) {
    walletConnectInit();
  }
  return accounts[0];
};

export const getAssets = async () => {
  let LfiContract;
  let walletBalanceInfo = {
    address: "",
    connectedChain: "",
    connectedChainBalance: 0,
    blockExplorer: "",
    connectedWallet: "",
    LfiBalance: {},
    LfiPrice: 0,
    total: 0,
    ERC20: 0,
    PEP20: 0,
  };
  try {
    // currently this only gets ERC20 or PEP20 balance
    // this can be enhanced to retrieve balance for other tokens
    if (!!!web3) {
      walletConnectInit();
    }

    walletBalanceInfo.address = accounts[0];
    walletBalanceInfo.connectedChain = blockchainConfig[chainId].blockchain;
    web3.getBalance(accounts[0]).then((balance) => {
      // convert a currency unit from wei to ether
      walletBalanceInfo.connectedChainBalance =
        ethers.utils.formatEther(balance);
    });

    let total = 0;
    let chainBalance = 0;
    let rpcProvider;
    await supportedNetworks.forEachAsync(async (id) => {
      rpcProvider = new ethers.providers.JsonRpcProvider(
        blockchainConfig[id].rpcUrl
      );

      LfiContract = new ethers.Contract(
        blockchainConfig[id]["TOKEN"]["LFI"].address,
        blockchainConfig[id]["TOKEN"].ABI,
        rpcProvider
      );

      chainBalance = utils.formatFromBaseUnit(
        await LfiContract.balanceOf(accounts[0]),
        blockchainConfig[id]["TOKEN"]["LFI"].DECIMALS
      );
      total += chainBalance;
      walletBalanceInfo.LfiBalance = {
        ...walletBalanceInfo.LfiBalance,
        [id]: chainBalance,
        total: total,
      };
    });
    walletBalanceInfo.blockExplorer =
      blockchainConfig[chainId].blockExplorerURL.trim();

    if (connectedWallet) {
      walletBalanceInfo.connectedWallet = connectedWallet;
    }
    return walletBalanceInfo;
  } catch (e) {
    return walletBalanceInfo;
  }
};

export const getConnectionStatus = () => {
  if (provider) return provider.connected;
  return false;
};

// export const walletConnectSign = async () => {
//   if (!!!web3) {
//     walletConnectInit()
//   }

//   const nonce = (Date.now() + '').toString(16)
//   const sign = await web3.eth.personal.sign(nonce, accounts[0])

//   const res = await userservice.upgradeUser({ params: { sign, nonce } })
//   return res.data
// }

export const writeTransaction = async (
  contractAddress,
  args,
  ABI,
  operation
) => {
  try {
    // ensure we use the correct decimals for the token as well as eliminate additonal decimal place values
    let contract = new ethers.Contract(contractAddress, ABI, signer);

    const receipt = await contract[operation](...args);
    return { receipt };
  } catch (e) {
    console.log({ e });
    throw e;
  }
};

export const deployTransaction = async (params) => {
  let amount = 0;
  try {
    if (params.chainId && params.chainId !== chainId) {
      await switchToSelectedNetwork(params.chainId);
    }
    chainId = getChainIds().polygonChainId
    // temp check to ensure we have a valid connection
    // this is happening in some edge cases
    if (!!!accounts) { await delay(5000) }
    if (!!!accounts) { await delay(3000) }
    if (params.currentCall === POOL_APPROVE) {
      const contractAddress =
        blockchainConfig[chainId]["TOKEN"][params.poolType].address;
      const ABI = blockchainConfig[chainId]["TOKEN"].ABI;
      const args = [
        blockchainConfig[chainId]["HOUSE_POOLS"][params.poolType].address,
        utils.maxApprovalAmount,
      ];
      return writeTransaction(
        contractAddress,
        args,
        ABI,
        txnOperations.APPROVE
      );
    }
    if (params.currentCall === POOL_INVEST) {
      amount = utils.formatToBaseUnit(
        params.amount,
        blockchainConfig[chainId].TOKEN[params.poolType].DECIMALS
      );
      const contractAddress =
        blockchainConfig[chainId]["HOUSE_POOLS"][params.poolType].address;
      const ABI = blockchainConfig[chainId]["HOUSE_POOLS"][params.poolType].ABI;
      const args = [accounts[0], amount];
      return writeTransaction(contractAddress, args, ABI, txnOperations.STAKE);
    }
    if (params.currentCall === POOL_WITHDRAW) {
      amount = utils.formatToBaseUnit(
        params.amount,
        blockchainConfig[chainId].TOKEN[params.poolType].DECIMALS
      );
      const contractAddress =
        blockchainConfig[chainId]["HOUSE_POOLS"][params.poolType].address;
      const ABI = blockchainConfig[chainId]["HOUSE_POOLS"][params.poolType].ABI;
      const args = [accounts[0], amount];
      return writeTransaction(
        contractAddress,
        args,
        ABI,
        txnOperations.UNSTAKE
      );
    }
    if (params.currentCall === POOL_WITHDRAW_MAX) {
      const contractAddress =
        blockchainConfig[chainId]["HOUSE_POOLS"][params.poolType].address;
      const ABI = blockchainConfig[chainId]["HOUSE_POOLS"][params.poolType].ABI;
      const args = [];
      return writeTransaction(
        contractAddress,
        args,
        ABI,
        txnOperations.UNSTAKEMAX
      );
    }
    if (params.currentCall === POOL_HARVEST) {
      console.log({ chainId, accounts })
      const contractAddress =
        blockchainConfig?.[chainId]?.["HOUSE_POOLS"]?.[params.poolType]?.address;
      const ABI = blockchainConfig?.[chainId]?.["HOUSE_POOLS"]?.[params.poolType]?.ABI;
      const args = [accounts[0]];
      return writeTransaction(
        contractAddress,
        args,
        ABI,
        txnOperations.CLAIM_REWARDS
      );
    }
    if (params.currentCall === FARM_INVEST) {
      amount = utils.formatToBaseUnit(
        params.amount,
        blockchainConfig[chainId]["TOKEN"][params.poolType].DECIMALS
      );
      const contractAddress = blockchainConfig[chainId]["FARM"].address;
      const ABI = blockchainConfig[chainId]["FARM"].ABI;
      const farmId = blockchainConfig[chainId]["FARM"][params.poolType].FARM_ID;
      const args = [farmId, amount, accounts[0]];
      return writeTransaction(
        contractAddress,
        args,
        ABI,
        txnOperations.DEPOSIT
      );
    }
    if (params.currentCall === FARM_APPROVE) {
      const contractAddress =
        blockchainConfig[chainId]["TOKEN"][params.poolType].address;
      const ABI = blockchainConfig[chainId]["TOKEN"][params.poolType].ABI;
      const args = [
        blockchainConfig[chainId]["FARM"].address,
        utils.maxApprovalAmount,
      ];
      return writeTransaction(
        contractAddress,
        args,
        ABI,
        txnOperations.APPROVE
      );
    }
    if (params.currentCall === FARM_WITHDRAW) {
      amount = utils.formatToBaseUnit(
        params.amount,
        blockchainConfig[chainId]["TOKEN"][params.poolType].DECIMALS
      );
      const contractAddress = blockchainConfig[chainId]["FARM"].address;
      const ABI = blockchainConfig[chainId]["FARM"].ABI;
      const farmId = blockchainConfig[chainId]["FARM"][params.poolType].FARM_ID;
      const args = [farmId, amount, accounts[0]];
      return writeTransaction(
        contractAddress,
        args,
        ABI,
        txnOperations.WITHDRAW
      );
    }
    if (params.currentCall === FARM_HARVEST) {
      const contractAddress = blockchainConfig[chainId]["FARM"].address;
      const ABI = blockchainConfig[chainId]["FARM"].ABI;
      const farmId = blockchainConfig[chainId]["FARM"][params.poolType].FARM_ID;
      const args = [farmId, accounts[0]];
      return writeTransaction(
        contractAddress,
        args,
        ABI,
        txnOperations.HARVEST
      );
    }
    if (params.currentCall === FARM_HARVEST_ALL) {
      const contractAddress = blockchainConfig[chainId]["FARM"].address;
      const ABI = blockchainConfig[chainId]["FARM"].ABI;
      const args = [accounts[0]];
      return writeTransaction(
        contractAddress,
        args,
        ABI,
        txnOperations.HARVEST_ALL
      );
    }
    if (params.currentCall === FARM_WITHDRAW_AND_HARVEST) {
      amount = utils.formatToBaseUnit(
        params.amount,
        blockchainConfig[chainId]["TOKEN"][params.poolType].DECIMALS
      );
      const contractAddress = blockchainConfig[chainId]["FARM"].address;
      const ABI = blockchainConfig[chainId]["FARM"].ABI;
      const farmId = blockchainConfig[chainId]["FARM"][params.poolType].FARM_ID;
      const args = [farmId, amount, accounts[0]];
      return writeTransaction(
        contractAddress,
        args,
        ABI,
        txnOperations.WITHDRAW_AND_HARVEST
      );
    }
    if (params.currentCall === GOVERNANCE_APPROVE) {
      const contractAddress = blockchainConfig[chainId].TOKEN["LFI"].address;
      const ABI = blockchainConfig[chainId].TOKEN.ABI;
      const args = [
        blockchainConfig[chainId]["VLFI"].address,
        utils.maxApprovalAmount,
      ];
      return writeTransaction(
        contractAddress,
        args,
        ABI,
        txnOperations.APPROVE
      );
    }
    if (params.currentCall === GOVERNANCE_INVEST) {
      amount = utils.formatToBaseUnit(
        params.amount,
        blockchainConfig[chainId].TOKEN["LFI"].DECIMALS
      );
      const contractAddress = blockchainConfig[chainId]["VLFI"].address;
      const ABI = blockchainConfig[chainId]["VLFI"].ABI;
      let args = [accounts[0], amount];
      return writeTransaction(contractAddress, args, ABI, txnOperations.STAKE);
    }
    if (params.currentCall === GOVERNANCE_WITHDRAW) {
      const contractAddress = blockchainConfig[chainId]["VLFI"].address;
      const ABI = blockchainConfig[chainId]["VLFI"].ABI;
      const args = [];
      return writeTransaction(
        contractAddress,
        args,
        ABI,
        txnOperations.UNSTAKEMAX
      );
    }
    if (params.currentCall === GOVERNANCE_CLAIM) {
      console.log("claiming", { chainId, params })
      const contractAddress = blockchainConfig?.[chainId]?.["VLFI"]?.address;
      const ABI = blockchainConfig?.[chainId]?.["VLFI"]?.ABI;
      const args = [accounts[0]];
      return writeTransaction(
        contractAddress,
        args,
        ABI,
        txnOperations.CLAIM_LFI
      );
    }
    if (params.currentCall === GOVERNANCE_START_COOLDOWN) {
      const contractAddress = blockchainConfig[chainId]["VLFI"].address;
      const ABI = blockchainConfig[chainId]["VLFI"].ABI;
      const args = [];
      return writeTransaction(
        contractAddress,
        args,
        ABI,
        txnOperations.COOLDOWN
      );
    }
    if (params.currentCall === WALLET_TRANSFER) {
      amount = utils.formatToBaseUnit(
        params.amount,
        blockchainConfig[chainId].TOKEN[params.poolType].DECIMALS
      );

      const contractAddress =
        blockchainConfig[chainId]["TOKEN"][params.poolType].address;
      const ABI = blockchainConfig[chainId]["TOKEN"].ABI;
      const args = [params.destination, amount];
      return writeTransaction(
        contractAddress,
        args,
        ABI,
        txnOperations.TRANSFER
      );
    }
    if (params.currentCall === TRANSFER_BASE_TOKEN) {
      amount = params.amount.toString();
      return transferBaseToken(params.destination, amount);
    }
    if (params.currentCall === TOKEN_UPGRADE) {
      const contractAddress =
        blockchainConfig[chainId]["TOKEN_UPGRADE"].address;
      const ABI = blockchainConfig[chainId]["TOKEN_UPGRADE"].ABI;
      const args = [];
      return writeTransaction(contractAddress, args, ABI, txnOperations.SWAP);
    }
  } catch (e) {
    throw e;
  }
};

export const transferBaseToken = async (destination, amount) => {
  try {
    let tx = {
      to: destination,
      // Convert currency unit from ether to wei
      value: ethers.utils.parseEther(amount).toHexString(),
    };

    const receipt = await web3.sendTransaction(tx);

    return { receipt };
  } catch (e) {
    console.log({ e });
    return {};
  }
};

export const verifyAllowance = async (token, amount, contractAddress, Abi) => {
  const tokenContract = new ethers.Contract(
    blockchainConfig[chainId].TOKEN[token].address,
    blockchainConfig[chainId].TOKEN.ABI,
    web3
  );

  const allowance = utils.normaliseAllowance(
    await tokenContract.allowance(accounts[0], contractAddress),
    blockchainConfig[chainId].TOKEN[token].DECIMALS
  );
  if (allowance >= amount) {
    return true;
  }
  return false;
};

export const switchNetwork = async () => {
  const defaultChainIdHexValue = ethers.utils.hexValue(
    getChainIds().polygonChainId
  );
  try {
    await ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: defaultChainIdHexValue }],
    });
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        await ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            blockchainConfig[getChainIds().polygonChainId].networkDetails,
          ],
        });
      } catch (addError) {
        console.error(addError + "2222");
      }
    }
  }
};

export const switchToSelectedNetwork = async (chain) => {
  try {
    await ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: ethers.utils.hexValue(chain) }],
    });
    chainId = chain;
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902 || switchError.code === -32603) {
      try {
        await ethereum.request({
          method: "wallet_addEthereumChain",
          params: [blockchainConfig[chain].networkDetails],
        });
        chainId = chain;
      } catch (addError) {
        console.error(addError + "2222");
      }
    }
  }
};

export const getFarmItems = async () => {
  let rpcProvider;
  let globalFarmInfo = {};
  let farmItems = [];
  try {
    await supportedNetworks.forEachAsync(async (id) => {
      const farms = Object.keys(farmsConfig[id]);
      let filteredPools = []
      farms.filter((farm) => filteredPools.push(farm));
      rpcProvider = new ethers.providers.JsonRpcProvider(
        blockchainConfig[id].rpcUrl
      );
      let farmInfo = {};

      const farmContract = new ethers.Contract(
        blockchainConfig[id]["FARM"].address,
        blockchainConfig[id]["FARM"].ABI,
        rpcProvider
      );

      const globalRewardPerSecond = utils.formatFromBaseUnit(
        await farmContract.rewardPerSecond(),
        blockchainConfig[id]["FARM"].DECIMALS
      );

      await filteredPools.forEachAsync(async (pool) => {
        let liquidity = 0,
          myLiquidity = 0,
          allowance,
          walletBalance = 0,
          pendingHarvest = 0,
          rewardsPerSecond = 0,
          APR = 0,
          dailyLfiRewardRate = 0,
          lpTokenPrice = 0,
          totalValueLocked = 0,
          dailyChange = 0;

        const tokenContract = new ethers.Contract(
          blockchainConfig[id]["TOKEN"][pool].address,
          blockchainConfig[id]["TOKEN"].ABI,
          rpcProvider
        );

        liquidity = utils.formatFromBaseUnit(
          await tokenContract.balanceOf(farmContract.address),
          blockchainConfig[id]["TOKEN"][pool].DECIMALS
        );
        lpTokenPrice = await getLpTokenPrice(
          pool,
          id,
          tokenContract,
          rpcProvider
        );
        totalValueLocked = lpTokenPrice * liquidity;

        const farmid = blockchainConfig[id]["FARM"][pool].FARM_ID;

        if (getChainIds().polygonChainId === id) {
          let tempInfo = await farmContract.getFarmInfo(farmid);
          let allocPoints = utils.formatToNumber(tempInfo[2]);
          rewardsPerSecond = globalRewardPerSecond * (allocPoints / 100);
          dailyLfiRewardRate = rewardsPerSecond * 86400;
          APR = utils.computeAPR(
            rewardsPerSecond * globalLfiPrice,
            liquidity * lpTokenPrice
          );
        }

        if (accounts && getChainIds().polygonChainId === id) {
          const [
            balanceBU,
            allowanceBU,
            pendingHarvestBU,
            myLiquidityBU
          ] = await Promise.all([
            tokenContract.balanceOf(accounts[0]),
            tokenContract.allowance(accounts[0], farmContract.address),
            farmContract.pendingReward(farmid, accounts[0]),
            farmContract.getUserAmount(accounts[0], farmid)
          ]);
          walletBalance = utils.formatFromBaseUnit(
            balanceBU, blockchainConfig[id]["TOKEN"][pool].DECIMALS
          );
          allowance = utils.normaliseAllowance(
            allowanceBU, blockchainConfig[id].TOKEN[pool].DECIMALS
          );
          pendingHarvest = utils.formatFromBaseUnit(
            pendingHarvestBU, blockchainConfig[id]["TOKEN"][pool].DECIMALS
          );
          myLiquidity = utils.formatFromBaseUnit(
            myLiquidityBU, blockchainConfig[id]["TOKEN"][pool].DECIMALS
          );
        }
        farmInfo = {
          [pool]: {
            liquidity,
            myLiquidity,
            allowance,
            walletBalance,
            pendingHarvest,
            dailyLfiRewardRate,
            APR,
            lpTokenPrice,
            totalValueLocked,
            dailyChange,
          },
          ...farmInfo,
        };
      });
      farmItems.push(farmInfo);
    });
  } catch (e) {
    console.log(e);
    return {};
  }
  globalFarmInfo = buildMultiChainObject(farmItems);
  return globalFarmInfo;
};

export const getPoolsItems = async () => {
  let globalPoolInfo = {};
  let poolItems = [];

  try {
    const pools = Object.keys(poolsConfig);
    const id = getChainIds().polygonChainId;
    let rpcProvider = new ethers.providers.JsonRpcProvider(
      blockchainConfig[id].rpcUrl
    );
    let poolInfo = {};
    await pools.forEachAsync(async (pool) => {
      let walletBalance = 0,
        myLiquidity = 0,
        allowance = 0,
        reward = 0,
        totalSupply = 0,
        myLPTokenAmount = 0,
        maxWithdrawl = 0;

      const contractCalls = [];
      const poolContract = new ethers.Contract(
        blockchainConfig[id]["HOUSE_POOLS"][pool].address,
        blockchainConfig[id]["HOUSE_POOLS"][pool].ABI,
        rpcProvider
      );

      const tokenContract = new ethers.Contract(
        blockchainConfig[id]["TOKEN"][pool].address,
        blockchainConfig[id]["TOKEN"].ABI,
        rpcProvider
      );

      contractCalls.push(poolContract.totalSupply());
      if (accounts) {
        contractCalls.push(
          tokenContract.allowance(accounts[0], poolContract.address)
        );
        contractCalls.push(tokenContract.balanceOf(accounts[0]));
        contractCalls.push(poolContract.getMyLiquidity(accounts[0]));
        contractCalls.push(poolContract.getRewards(accounts[0]));
        contractCalls.push(poolContract.balanceOf(accounts[0]));
        contractCalls.push(poolContract.getMaxWithdrawal(accounts[0]));
      }

      const results = await Promise.all(contractCalls);
      totalSupply = utils.formatFromBaseUnit(
        results[0],
        blockchainConfig[id]["HOUSE_POOLS"][pool].DECIMALS
      );


      // get allowance for house pool
      if (accounts && results[1]) {
        allowance = utils.normaliseAllowance(
          results[1],
          blockchainConfig[id]["TOKEN"][pool].DECIMALS
        );

        walletBalance = utils.formatFromBaseUnit(
          results[2],
          blockchainConfig[id]["TOKEN"][pool].DECIMALS
        );

        myLiquidity = utils.formatFromBaseUnit(
          results[3],
          blockchainConfig[id]["TOKEN"][pool].DECIMALS
        );

        reward = utils.formatFromBaseUnit(
          results[4],
          blockchainConfig[id]["HOUSE_POOLS"][pool].DECIMALS
        );

        myLPTokenAmount = utils.formatFromBaseUnit(
          results[5],
          blockchainConfig[id]["HOUSE_POOLS"][pool].DECIMALS
        );

        maxWithdrawl = utils.formatFromBaseUnit(
          results[6],
          blockchainConfig[id]["TOKEN"][pool].DECIMALS
        );
      }
      poolInfo = {
        [pool]: {
          walletBalance,
          myLiquidity,
          allowance,
          reward,
          totalSupply,
          myLPTokenAmount,
          maxWithdrawl,
        },
        ...poolInfo,
      };
    });
    poolItems.push(poolInfo);

    globalPoolInfo = buildMultiChainObject(poolItems);
    return globalPoolInfo;
  } catch (e) {
    console.log({ e });
    console.log("pools items error");
  }
};

export const getGlobalValues = async (refresh) => {
  // let globalPoolData = { "USDC": {}, "LFI": {}, "WBTC": {}, "WETH": {}, }
  let newGlobalValues = { USDC: {}, WETH: {}, WBTC: {} };

  const api = buildApiBaseUrl(getCurApiVer(), "pools")
  try {
    const res = await axios.get(`${api}${refresh ? `?refresh=1` : ``}`)
    newGlobalValues = res.data.Pools;
    globalValues = newGlobalValues;
    return { globalValues: newGlobalValues };
  } catch (e) {
    console.log({ e });
    console.log("global pools items error");
  }
};

export const getVolume = async (refresh) => {
  // let globalPoolData = { "USDC": {}, "LFI": {}, "WBTC": {}, "WETH": {}, }
  let newVolume = { USDC: {}, WETH: {}, WBTC: {}, LFI: {} };
  let totalVolume = 0
  let total24Hour = 0
  const api = buildApiBaseUrl(getCurApiVer(), "housepool/volume")
  try {
    await axios.get(`${api}${refresh ? `?refresh=1` : ``}`).then((res) => {
      newVolume = res.data;
      totalVolume = newVolume["USDC"]?.total + newVolume["WETH"]?.total + newVolume["LFI"]?.total + newVolume["WBTC"]?.total;
      total24Hour = newVolume["USDC"]?.["24hour"] + newVolume["WETH"]?.["24hour"] + newVolume["LFI"]?.["24hour"] + newVolume["WBTC"]?.["24hour"];
      newVolume["total"] = totalVolume;
      newVolume["24hour"] = total24Hour;
    });
    return { volume: newVolume };
  } catch (e) {
    console.log({ e });
    console.log("global volume items error");
  }
};

export const getSportsbookFunds = async (refresh) => {

  const networkChainId = getChainIds().staticSupportedNetworks.includes(chainId)
    ? chainId
    : defaultChainId;
  const rpcProvider = new ethers.providers.JsonRpcProvider(
    blockchainConfig[defaultChainId].rpcUrl
  );
  const contractCalls = []
  const usdcContract = new ethers.Contract(
    blockchainConfig[networkChainId]["TOKEN"]["USDC"].address,
    blockchainConfig[networkChainId]["TOKEN"].ABI,
    rpcProvider
  );
  contractCalls.push(usdcContract.balanceOf(blockchainConfig[networkChainId]["SPORTSBOOK"].address))

  const wethContract = new ethers.Contract(
    blockchainConfig[networkChainId]["TOKEN"]["WETH"].address,
    blockchainConfig[networkChainId]["TOKEN"].ABI,
    rpcProvider
  );
  contractCalls.push(wethContract.balanceOf(blockchainConfig[networkChainId]["SPORTSBOOK"].address))

  const wbtcContract = new ethers.Contract(
    blockchainConfig[networkChainId]["TOKEN"]["WBTC"].address,
    blockchainConfig[networkChainId]["TOKEN"].ABI,
    rpcProvider
  );
  contractCalls.push(wbtcContract.balanceOf(blockchainConfig[networkChainId]["SPORTSBOOK"].address))

  const lfiContract = new ethers.Contract(
    blockchainConfig[networkChainId]["TOKEN"]["LFI"].address,
    blockchainConfig[networkChainId]["TOKEN"].ABI,
    rpcProvider
  );
  contractCalls.push(lfiContract.balanceOf(blockchainConfig[networkChainId]["SPORTSBOOK"].address))

  const results = await Promise.all(contractCalls);


  const amounts = [utils.formatFromBaseUnit(
    results[0],
    blockchainConfig[networkChainId]["TOKEN"]["USDC"].DECIMALS
  )
    , utils.formatFromBaseUnit(
      results[1],
      blockchainConfig[networkChainId]["TOKEN"]["WETH"].DECIMALS
    ), utils.formatFromBaseUnit(
      results[2],
      blockchainConfig[networkChainId]["TOKEN"]["WBTC"].DECIMALS
    ), utils.formatFromBaseUnit(
      results[3],
      blockchainConfig[networkChainId]["TOKEN"]["LFI"].DECIMALS
    )]

  const usdcPrice = await getTokenPrice("USDC");
  const wethPrice = await getTokenPrice("WETH");
  const wbtcPrice = await getTokenPrice("WBTC");
  const lfiPrice = await getTokenPrice("LFI");
  const dollar = [amounts[0] * usdcPrice, amounts[1] * wethPrice, amounts[2] * wbtcPrice, amounts[3] * lfiPrice]
  return {
    sportsbookFunds: {
      "USDC": {
        amount: amounts[0],
        Dollar: {
          amount: dollar[0]
        }
      },
      "WETH": {
        amount: amounts[1],
        Dollar: {
          amount: dollar[1]
        }
      },
      "WBTC": {
        amount: amounts[2],
        Dollar: {
          amount: dollar[2]
        }
      },
      "LFI": {
        amount: amounts[3],
        Dollar: {
          amount: dollar[3]
        }
      },
      "total": dollar[0] + dollar[1] + dollar[2] + dollar[3]
    }
  };
}

export const getPoolGraphData = async (
  graphToken = "USDC",
  graphIndicator = "liquidity",
  timeSeries
) => {
  const api = buildApiBaseUrl(getCurApiVer(), "housepool")
  const duration = ["Day", "Week", "Month", "Year"];
  let graphDataPoints;
  try {
    if (timeSeries) {
      graphDataPoints = { Day: [], Week: [], Month: [], Year: [] };
      await duration.forEachAsync(async (time) => {
        await axios
          .get(
            `${api}?pool=${graphToken}&duration=${time}&attribute=${graphIndicator}`
          )
          .then(async (res) => {
            const data = res.data.data;
            graphDataPoints[time] = data;
          });
      });
    } else {
      await axios
        .get(
          `${api}?pool=${graphToken}&duration=week&attribute=${graphIndicator}`
        )
        .then((res) => {
          graphDataPoints = res.data.data;
        });
    }
  } catch (e) {
    console.error({ e });
    console.log("graph pools graphTVLData error");
  }
  return graphDataPoints;
};

const getLpTokenPrice = async (pool, id, lpPairContract, rpcProvider) => {
  try {
    if (pool === "LFIWETH" && id === getChainIds().polygonChainId) {
      if (globalLfiPrice === 0 || globalEthPrice === 0) {
        await getTokenPrice("LFI");
      }
      const lfiContract = new ethers.Contract(
        blockchainConfig[id]["TOKEN"]["LFI"].address,
        blockchainConfig[id]["TOKEN"].ABI,
        rpcProvider
      );

      const wethContract = new ethers.Contract(
        blockchainConfig[id]["TOKEN"]["WETH"].address,
        blockchainConfig[id]["TOKEN"].ABI,
        rpcProvider
      );

      let lfiBalance = utils.formatFromBaseUnit(
        await lfiContract.balanceOf(lpPairContract.address),
        blockchainConfig[id]["TOKEN"]["LFI"].DECIMALS
      );
      let wethBalance = utils.formatFromBaseUnit(
        await wethContract.balanceOf(lpPairContract.address),
        blockchainConfig[id]["TOKEN"]["WETH"].DECIMALS
      );

      let totalSupply = utils.formatFromBaseUnit(
        await lpPairContract.totalSupply(),
        blockchainConfig[id]["TOKEN"][pool].DECIMALS
      );
      let totalValueLocked =
        lfiBalance * globalLfiPrice + wethBalance * globalEthPrice;
      let lpTokenPrice = totalValueLocked / totalSupply;
      return lpTokenPrice;
    }
    return {};
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const getVlfiData = async () => {
  let vLFIBalance = 0,
    allowance = 0,
    LFIwalletBalance = 0,
    pendingHarvest = 0,
    cooldown = {},
    cooldownState = false,
    depositedLFI = 0,
    cooldownType = "",
    APR = 0,
    totalLiquidity = 0,
    cooldownInSeconds = 0,
    dailyChange = 0;

  const networkChainId = getChainIds().staticSupportedNetworks.includes(chainId)
    ? chainId
    : defaultChainId;
  const rpcProvider = new ethers.providers.JsonRpcProvider(
    blockchainConfig[defaultChainId].rpcUrl
  );

  const LfiContract = new ethers.Contract(
    blockchainConfig[networkChainId]["TOKEN"]["LFI"].address,
    blockchainConfig[networkChainId]["TOKEN"].ABI,
    rpcProvider
  );

  const vLfiContract = new ethers.Contract(
    blockchainConfig[networkChainId]["VLFI"].address,
    blockchainConfig[networkChainId]["VLFI"].ABI,
    rpcProvider
  );

  try {
    const rewardsPerSecond = utils.formatFromBaseUnit(
      await vLfiContract.getRewardPerSecond(),
      blockchainConfig[networkChainId]["TOKEN"]["LFI"].DECIMALS
    );

    totalLiquidity = utils.formatFromBaseUnit(
      await vLfiContract.getLiquidity(),
      blockchainConfig[networkChainId]["TOKEN"]["LFI"].DECIMALS
    );

    APR = utils.computeAPR(rewardsPerSecond, totalLiquidity);

    cooldownInSeconds = utils.formatToNumber(
      await vLfiContract.getCooldownSeconds()
    );

    if (globalLfiPrice === 0) {
      await getTokenPrice("LFI");
    }

    if (accounts) {
      LFIwalletBalance = utils.formatFromBaseUnit(
        await LfiContract.balanceOf(accounts[0]),
        blockchainConfig[networkChainId]["TOKEN"]["LFI"].DECIMALS
      );

      allowance = utils.normaliseAllowance(
        await LfiContract.allowance(
          accounts[0],
          blockchainConfig[networkChainId]["VLFI"].address
        ),
        blockchainConfig[networkChainId]["TOKEN"]["LFI"].DECIMALS
      );

      pendingHarvest = utils.formatFromBaseUnit(
        await vLfiContract.getRewards(accounts[0]),
        blockchainConfig[networkChainId]["TOKEN"]["LFI"].DECIMALS
      );

      vLFIBalance = utils.formatFromBaseUnit(
        await vLfiContract.balanceOf(accounts[0]),
        blockchainConfig[networkChainId]["TOKEN"]["LFI"].DECIMALS
      );

      depositedLFI = 0;//utils.formatFromBaseUnit(
      //   await vLfiContract.getUserLFIDeposits(accounts[0]),
      //   blockchainConfig[networkChainId]["TOKEN"]["LFI"].DECIMALS
      // );
      cooldownState = await vLfiContract.getCoolDownActiveState();
      const cooldownStartTimeStamp = utils.formatToNumber(
        await vLfiContract.getUserCooldown(accounts[0])
      );
      const unstakeWindowInSeconds = utils.formatToNumber(
        await vLfiContract.getUnstakeWindowTime()
      );

      const cooldownEndTimeStamp =
        Math.floor(new Date(cooldownStartTimeStamp * 1000).getTime()) +
        cooldownInSeconds * 1000;
      const unstakeWindowEndTimeStamp =
        cooldownEndTimeStamp + unstakeWindowInSeconds * 1000;
      const currentTime = Date.now();

      if (
        currentTime <= cooldownEndTimeStamp &&
        currentTime <= unstakeWindowEndTimeStamp
      ) {
        cooldownType = "Cooldown";
      } else if (
        currentTime > cooldownEndTimeStamp &&
        currentTime <= unstakeWindowEndTimeStamp
      ) {
        cooldownType = "Unstake";
      } else {
        cooldownType = "";
      }

      cooldown = {
        cooldownState,
        cooldownEndTimeStamp,
        unstakeWindowEndTimeStamp,
        cooldownType,
      };
    }
  } catch (e) {
    console.error(e);
  }

  const LFIToken = {
    vLFIBalance,
    allowance,
    LFIwalletBalance,
    pendingHarvest,
    dailyLfiRewards: 0,
    cooldown,
    depositedLFI,
    APR,
    totalLiquidity,
    cooldownInSeconds,
    tokenPrice: globalLfiPrice,
    dailyChange,
  };
  return LFIToken;
};

export const fortmaticOperations = async (operation) => {
  if (provider && provider.fm) {
    try {
      if (operation === FortmaticOperations.DEPOSIT) {
        await provider.fm.user.deposit();
      }
      if (operation === FortmaticOperations.TRANSFER) {
        await provider.fm.transactions.send({
          amount: "56.10003",
        });
      }
    } catch (e) {
      console.log(e);
    }
  }
};

export const addLfiToInjectedWallet = async () => {
  const tokenAddress =
    blockchainConfig[getChainIds().polygonChainId]["TOKEN"]["LFI"].address;
  const tokenSymbol = "LFI";
  const tokenDecimals =
    blockchainConfig[getChainIds().polygonChainId]["TOKEN"]["LFI"].DECIMALS;
  await ethereum.request({
    method: "wallet_watchAsset",
    params: {
      type: "ERC20", // Initially only supports ERC20, but eventually more!
      options: {
        address: tokenAddress, // The address that the token is at.
        symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
        decimals: tokenDecimals, // The number of decimals in the token
      },
    },
  });
  return;
  // try {
  //   // wasAdded is a boolean. Like any RPC method, an error may be thrown
  // } catch (error) {
  //   console.log(error);
  // }
};

export const getBlockExplorer = () => {
  return blockchainConfig[chainId].blockExplorerURL.trim();
};

export const getCurrentChainId = () => {
  return chainId && isChainIdSupported(chainId) ? chainId : defaultChainId;
};

export const getCurrentChain = () => {
  return chainId || 137;
};

const buildMultiChainObject = (chainDataArray) => {
  let multiChainObject = {};
  try {
    supportedNetworks.forEach((id, index) => {
      multiChainObject = Object.assign(
        { [id]: chainDataArray[index] },
        multiChainObject
      );
    });
  } catch (e) {
    console.log({ e });
  }
  return multiChainObject;
};

export const getAddLpUrl = (id) => {
  return blockchainConfig[id]["FARM"].ADD_LP_URL;
};

export const getRemoveLpUrl = (id) => {
  return blockchainConfig[id]["FARM"].REMOVE_LP_URL;
};

export const getDexUrl = () => {
  return blockchainConfig[getChainIds().polygonChainId].DEXURL;
};

export const ifTokenUpgradeApproved = async () => {
  try {
    return false;
  } catch (e) {
    console.error({ e });
    return null;
  }
};

