import {
    FARM_UPDATE_CALL_STATUS
  } from "./actionTypes"
  
  import ActivityStatus from "../../models/enums/ActivityStatus"
  
  const initialState = {
    currentCall: 'null',
    callStatus: ActivityStatus.NOT_INITIATED,
  
    farmInfo: {

    },
    
    
    depositTx: '',
    withdrawTx: '',
    approveTx: ''
  
  }
  
  const farms = (state = initialState, action) => {
    switch (action.type) {
      case FARM_UPDATE_CALL_STATUS:
        state = {
          ...state,
          currentCall: action.payload.callStatusInfo.currentCall,
          callStatus: action.payload.callStatusInfo.callStatus,
          ...action.payload.callStatusInfo.data
        }
        break
      default:
        state = { ...state }
    }
    return state
  }
  
  export default farms
  