import * as React from 'react';

export function Divider(props:any) {

    const hrStyle = {
        color: '#ffffff',
        opacity: '10%',
        width: '100%',
        margin: props.margin?props.margin: '0',
    }

    return (
        <hr style={hrStyle} />
    );
}
export default Divider;