import { gradients, lightColors, darkColors, baseColors } from '../../theme/colors'
import { Typo } from '../Typo'
import { useSelector } from 'react-redux'

export function Btn(props: any) {
	const { darkMode } = useSelector((state: any) => state.Modals)
	const iconSize = props.iconSize ? props.iconSize : '24px'
	const sx = props.sx ? props.sx : {}

	const fontSize = () => {
		if (props.fontSize) {
			return props.fontSize}
		if (props.sm) {
			return '14px';
		} else if (props.md) {
			return '14px';
		} else if (props.lg) {
			return '16px';
		} else {
			return '16px';
		}
	};
	const getBorderColor = () => {
		if (props.disabled) { return (darkMode ? darkColors.buttonBorderDisabled : lightColors.buttonBorderDisabled) }
		return (darkMode ? darkColors.buttonBorder : lightColors.buttonBorder)
	}
	const getbackgroundColor = () => {
		if (darkMode) { return darkColors.contentBackground }
		return lightColors.contentBackground
	}

	const btnstyle = {
		width: props.gradient ? 'calc(100% - 4px)' : '100%',
		height: props.sm ? '34px' : (props.lg ? '44px' : '40px'),
		padding: props.sm ? '4px' : (props.lg ? '10px' : '8px'),
		textAlign: 'center',
		cursor: props.disabled ? 'not-allowed' : 'pointer',
		display: props.icon ? 'grid' : "default",
		gridTemplateColumns: 'auto auto'
	} as const

	const style = { ...btnstyle, ...sx }
	const iconstyle = {
		height: iconSize,
		weight: iconSize,
		// marginBottom: '-5px',
		display: 'inline-block',
	};
	const contentStyle = {
		width: '100%', 
		textTransform: 'capitalize',
		textDecoration: 'none',
		fontSize: fontSize(),
		cursor: props.disabled ? 'not-allowed' : 'pointer',
		marginLeft: props.icon && props.children ? '5px' : '0px',
		color: props.disabled ? (darkMode ? darkColors.textDisabled : lightColors.textDisabled) : (props.fill ? baseColors.black : (darkMode ? darkColors.textPrimary : lightColors.textPrimary)),
		marginTop:props.gradient?'5px':'0px',
	}
	const gradientStyle = {
		padding: '2px',
		backgroundImage: props.fill ? gradients.buttonBlue+','+gradients.buttonBlue : ("linear-gradient(to bottom, " + getbackgroundColor() + " 100%, " + getbackgroundColor() + " 100%)," + (props.gradient ? gradients.buttonBlue : " linear-gradient(92.12deg, " + getBorderColor() + " 4.49%, " + getBorderColor() + " 108.15%)")),
		backgroundClip: "content-box, padding-box",
		cursor: 'pointer',
		borderRadius: props.gradient ? '6px' : '8px',
		height:'fit-content',
		width: props.width ? props.width : '100%',
		margin: props.margin ? props.margin : '0',
	}

	return (
		<div style={gradientStyle}>
			<div style={style} onClick={!props.disabled && props.onClick} >
				{props.icon ? <img src={props.icon} style={iconstyle} alt='icon'/> : <div />}
				<Typo sx={contentStyle}>{props.children}</Typo>
			</div>
		</div>
	);
}

export default Btn;
