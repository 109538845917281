import { TokenUpgrade } from 'lunafi-ui'
import { useState } from 'react'
import { getCurrentChain } from '../services/blockchainInterface'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { ifTokenUpgradeApproved } from '../services/blockchainInterface'
import ReactGA from 'react-ga'
import { TOKEN_UPGRADE, TOKEN_UPGRADE_APPROVE } from '../store/wallet/actionTypes';
import { submitTransaction, getGovernanceData } from '../store';
import { getChainIds } from '../config/walletConfig';

export default function TokenSwapContainer(props: any) {
    ReactGA.pageview(window.location.pathname);
    const dispatch = useDispatch()

    const [tokenAmount, setTokenAmount] = useState(0)
    const [allowance, setAllowance] = useState<any>(0)
    const { connected } = useSelector((state: any) => state.Wallet)
    const wallet = useSelector((state: any) => state.Wallet)
    const { LFIToken } = useSelector((state: any) => state.Governance)


    useEffect(() => {
        // console.log("get governance data")
        dispatch(getGovernanceData())
    }, [getCurrentChain()])

    return (<TokenUpgrade
        description={<>Clicking swap will result in your old LFI tokens being swapped for new LFI tokens.{' '}<a href="/" rel="noopener noreferrer" target="_blank">Learn more</a></>}
        newAmount={tokenAmount}
        oldAmount={tokenAmount}
        submit={{
            name: !connected ? "Connect Wallet" : (getChainIds().staticSupportedNetworks.includes(getCurrentChain()) ? (!allowance ? "Approve" : "Swap"): 'Wrong Network'),
            onSubmit: () => {
                !allowance ?
                    dispatch(submitTransaction({ currentCall: TOKEN_UPGRADE_APPROVE, poolType: 'LFI', chainId: getCurrentChain() })) :
                    dispatch(submitTransaction({ currentCall: TOKEN_UPGRADE, poolType: "LFI", amount: tokenAmount, chainId: getCurrentChain() })); dispatch(getGovernanceData());
            },
            disabled: !(connected && getChainIds().staticSupportedNetworks.includes(getCurrentChain()))
        }}
        title="TOKEN UPGRADE"
        tooltip={<>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</>}
    />)
}
