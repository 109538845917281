import { Routes, Route, BrowserRouter } from 'react-router-dom';
import './App.css';
import Navigation from './containers/navbar-container';
import Home from './containers/home-container';
import Pool from './containers/pool-container';
import { PoolDetails } from './components/pool/pool-details';
import TokenSwapContainer from './containers/token-swap-container';
import { Farm } from './containers/farm-container';
import { Provider } from 'react-redux'
import { store, persistor } from "./store"
import Web3Container from './containers/web3-container';
import Governance from './containers/governance-container';
import { switchConfig } from './config/walletConfig'
import IdoGameContainer from './containers/ido-game-container';
import MagicWalletContainer from './containers/magic-wallet.container';
import CssBaseline from '@mui/material/CssBaseline';
import { PersistGate } from 'redux-persist/integration/react'

function App() {
	return (
		<div className="App">
			<CssBaseline />
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<Web3Container>
						<BrowserRouter>
							<Navigation switch={switchConfig}>
								<Routes>
									<Route path="/" element={<Home switch={switchConfig} />} />
									<Route path="/pools" element={<Pool />} />
									<Route path='/pools/:ticker/pool' element={<PoolDetails />} />
									<Route path="/farms" element={<Farm />} />
									<Route path="/staking" element={<Governance switch={switchConfig} />} />
									<Route path="/ido-game" element={<IdoGameContainer />} />
									<Route path="/wallet" element={<MagicWalletContainer />} />
									<Route path="/token-upgrade" element={<TokenSwapContainer />} />
								</Routes>
							</Navigation>
						</BrowserRouter>
					</Web3Container>
				</PersistGate>
			</Provider>
		</div>
	);
}

export default App;
