import axios from "axios";
import { IChartData } from "lunafi-ui/dist/components/composed/finance/periods-price-chart/types";

export interface LFIPriceChartProps {
  day: IChartData[];
  week: IChartData[];
  month: IChartData[];
  year: IChartData[];
}

export const fetchLFIPrice = async () => {
  try {
    const yearPrice = await axios
      .get(
        `https://l9s0nukb8b.execute-api.us-east-2.amazonaws.com/v1/lfitoken/lfiPriceChart?duration=year`
      )
      .then((res) => {
        return res.data;
      });
    const monthPrice = await axios
      .get(
        `https://l9s0nukb8b.execute-api.us-east-2.amazonaws.com/v1/lfitoken/lfiPriceChart?duration=month`
      )
      .then((res) => {
        return res.data;
      });
    const weekPrice = await axios
      .get(
        `https://l9s0nukb8b.execute-api.us-east-2.amazonaws.com/v1/lfitoken/lfiPriceChart?duration=week`
      )
      .then((res) => {
        return res.data;
      });
    const dayPrice = await axios
      .get(
        `https://l9s0nukb8b.execute-api.us-east-2.amazonaws.com/v1/lfitoken/lfiPriceChart?duration=day`
      )
      .then((res) => {
        return res.data;
      });
    const yearPrices = formatLineChartData(yearPrice, "year");
    const monthPrices = formatLineChartData(monthPrice, "month");
    const weekPrices = formatLineChartData(weekPrice, "week");
    const dayPrices = formatLineChartData(dayPrice, "day");

    return {
      day: dayPrices,
      week: weekPrices,
      month: monthPrices,
      year: yearPrices,
    };
  } catch (e) {
    console.error({ e });
    console.log("get lfi price from coingecko failed");
    return {
      day: [],
      week: [],
      month: [],
      year: [],
    };
  }
};

const getDateInTimeRage = (
  data: [time: number, value: number][],
  initialTime: number
) => {
  return data.filter((item) => item[0] >= initialTime);
};

const formatLineChartData = (
  data: [time: number, value: number][],
  type: "day" | "week" | "month" | "year"
) => {
  const initialPrice = data[0][1] ?? 0;
  return data.map((item, index) => ({
    label: getLabels(item[0], type),
    percent: initialPrice === 0 ? 0 : (item[1] - initialPrice) / initialPrice,
    tooltipKey:
      type === "day"
        ? new Date(item[0]).toLocaleTimeString()
        : new Date(item[0]).toLocaleDateString(),
    value: item[1],
  }));
};

const getLabels = (
  dateInNumber: number,
  type: "day" | "week" | "month" | "year"
) => {
  const date = new Date(dateInNumber).toUTCString().split(" ");
  if (type === "day") {
    const time = date[4].split(":");
    return `${time[0]}:${time[1]}`;
  } else if (type === "week") {
    return `${date[2]} ${date[1]}`;
  } else if (type === "month") {
    return `${date[2]} ${date[1]}`;
  } else {
    return `${date[2]} ${date[3]}`;
  }
  // const getHHMM = (dateInNumber: number): string => {
  //   const date = new Date(dateInNumber)
  //   return `${date.getHours()}:${date.getMinutes()}`
  // }

  // const getMMDD = (dateInNumber: number): string => {
  //   const date = new Date(dateInNumber)
  //   return `${date.toLocaleString('default', { month: 'short' })} ${date.getDay()}`
};
