import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { ReactComponent as ExitIcon } from '../../assets/icons/x.svg'
import { connectWallet, walletNotificationShown } from '../../store/wallet/actions'
import { setMagicModal, setMagicLogin } from "../../store";
import { CustomInput } from "../../lunafi-uikit/components/Input"
import { Button } from '../../lunafi-uikit/components/Button';
import WalletConnection from "../../models/enums/WalletConnections";
import { Typo } from '../../lunafi-uikit/components/Typo'

const modalBoxStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '100%', sm: '640px' },
    color: '#FFFFFF',
    background: 'linear-gradient(180deg, #1E1F23 0%, #141517 100%)',
    border: '1px solid rgba(64, 65, 66, 0.3)',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '20px',
};
const innerModalStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '35px',
    position: 'static',
    width: '100%',
    height: '100%',
} as const

const headerBoxStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px',
    width: '100%'
} as const

const headerTextStyle = {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '120%',
    position: 'static',
    width: '172px',
    height: '29px',
    left: '0px',
    top: '0px',
} as const

const exitIconStyle = {
    border: '1px solid #FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '12px',
    minWidth: '24px',
    height: '24px',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px',
    position: 'static',
} as const

const hrStyle = {
    display: 'block',
    height: '2px',
    color: '#404142',
    opacity: '30%',
    width: '100%',
    margin: '24px 0px'
} as const


export default function MagicLoginModal() {
    const dispatch = useDispatch()
    const { walletBalanceInfo, connected } = useSelector((state: any) => state.Wallet)
    const { magicModalOpen, magicLogin } = useSelector((state: any) => state.Modals)
    useEffect(() => {
        if (connected) {
            dispatch(setMagicModal(false))
            dispatch(walletNotificationShown())
        }
    }, [connected])

    const handleClose = () => dispatch(setMagicModal(false))

    const handleChange = (input: string) => {
        dispatch(setMagicLogin(input))
    }

    const connectMe = () => {
        dispatch(connectWallet({ walletConnection: WalletConnection.MAGIC_LINK, creds: magicLogin }));
    }
    return (
        <div>
            <Modal
                open={magicModalOpen}
                onClose={handleClose}
                disableAutoFocus={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ margin: { xs: '0px 16px 0px 16px', sm: '0px 0px 0px 0px' } }}
            >
                <Box sx={modalBoxStyle}>
                    <Box sx={innerModalStyle}>
                        <Box sx={headerBoxStyle}>
                            <Typography id="modal-modal-title" variant="h4" component="h4" sx={headerTextStyle}>
                                Input Email
                            </Typography>
                            <Button sx={exitIconStyle} disableRipple={true} onClick={handleClose}>
                                <ExitIcon />
                            </Button>
                        </Box>
                        <hr style={hrStyle} />
                        <Box sx={{ marginBottom: '10px', textAlign:'left', width:'100%' }}>
                            <Typo white>Enter email</Typo>
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent:'space-between' }}>
                            <Box sx={{width:'400px'}}>
                                <CustomInput handleChange={handleChange} placeHolder='example@gmail.com' type='email' />
                            </Box>
                            <Box sx={{width:'115px', height:'53px'}}>
                                <Button filled onClick={connectMe}>Submit</Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
