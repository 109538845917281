enum WalletConnection {
    WALLET_CONNECT = 'walletconnect',
  
    BROWSER_EXTENSION = 'injected',

    MAGIC_LINK = 'MAGIC_LINK',

    LEDGER = 'LEDGER',

    FORTMATIC = 'fortmatic'
  }
  
  export default WalletConnection
  