import { QUICKSWAP_ADD_LP_PAIR, QUICKSWAP_REMOVE_LP_PAIR } from '../config/constants/endpoints'
import { contracts } from '../config/constants/contracts'

interface config {
    [key: string]: any
}

export const farmsConfig: config = {
    80001: {
        LFIWETH: {
            name: "WETH/HBTS",
            ticker: "HBTSWETH",
            network: "matic",
            rewardIcon: "/img/form/eth-luna.svg",
            addLiquidity: `${QUICKSWAP_ADD_LP_PAIR}/ETH/${contracts.LFI[80001]}`,
            removeLiquidity: `${QUICKSWAP_REMOVE_LP_PAIR}/ETH/${contracts.LFI[80001]}`,
        },
    },
    137: {
        LFIWETH: {
            name: "WETH/HBTS",
            ticker: "HBTSWETH",
            network: "matic",
            rewardIcon: "/img/form/eth-luna.svg",
            addLiquidity: `${QUICKSWAP_ADD_LP_PAIR}/ETH/${contracts.LFI[137]}`,
            removeLiquidity: `${QUICKSWAP_REMOVE_LP_PAIR}/ETH/${contracts.LFI[137]}`,
        },
    },
    647: {
        // LFISX: {
        //     name: "LFI/SX LP",
        //     ticker: "LFISX",
        // },
    },
    416: {
        // LFISX: {
        //     name: "LFI/SX LP",
        //     ticker: "LFISX",
        // },
    }
}

export const networks = {
    ethereum: {
        color: '#627EEA',
        img: '/img/network-label/ethereum.svg',
        name: 'Ethereum',
        value: 'ethereum'
    },
}
