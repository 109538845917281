import { select, fork, take, put, all, call, delay } from "redux-saga/effects";

import {
  getPoolsItems,
  getAssets,
  deployTransaction,
  getPoolGraphData,
  getGlobalValues,
  getVolume,
  getSportsbookFunds,
} from "../../services/blockchainInterface";

import {
  GET_LIQUIDITY,
  SUBMIT_TRANSACTION,
  SET_GRAPH,
  SET_POOL,
  USDC_GRAPH,
  GET_GLOBAL_VALUES,
  GET_VOLUME,
  GET_SPORTSBOOK_FUNDS,
} from "./actionTypes";

import { updateCallStatus, refreshPoolInfo } from "./actions";
import { setTransactionModal } from "../modals/actions";
import { refreshWalletInfo } from "../wallet/actions";
import { getFarmData } from "../farms/actions";
import { setUSDCGraph, _setGlobalValues, _setVolume, _setSportsbookFunds } from "../pools/actions";

import ActivityStatus from "../../models/enums/ActivityStatus";
import {
  addNewTransaction,
  getGovernanceData,
  updateTransactionStatus,
  setPoolData,
} from "..";
import _ from "lodash";

const defaultData = [
  { tooltipKey: "00:00", label: "00", value: 0, tValue: "0" },
  { tooltipKey: "00:00", label: "01", value: 0, tValue: "0" },
];

const defaultGraphInfo = {
  USDC: {
    Day: defaultData,
    Week: defaultData,
    Month: defaultData,
    Year: defaultData,
  },
  LFI: {
    Day: defaultData,
    Week: defaultData,
    Month: defaultData,
    Year: defaultData,
  },
  WBTC: {
    Day: defaultData,
    Week: defaultData,
    Month: defaultData,
    Year: defaultData,
  },
  WETH: {
    Day: defaultData,
    Week: defaultData,
    Month: defaultData,
    Year: defaultData,
  },
};
const defaultCoinInfo = {
  USDC: defaultData,
  LFI: defaultData,
  WBTC: defaultData,
  WETH: defaultData,
};

const defaultGraphData = {
  graph: {
    graphTVLData: defaultGraphInfo,
    graphMEData: defaultGraphInfo,
    graphPNL7DaysData: defaultCoinInfo,
    graphPNL30DaysData: defaultCoinInfo,
    graphLiquidityData: defaultGraphInfo,
    graphPendingStakesData: defaultGraphInfo,
  },
};

function* PoolsSaga() {
  yield all([
    fork(getLiquidityWatcher),
    fork(addTransactionWatcher),
    fork(getGraphWatcher),
    fork(getPoolsWatcher),
    fork(getGlobalValuesWatcher),
    fork(getVolumeWatcher),
    fork(getSportsbookFundsWatcher),
  ]);
}

const createWatcher = (worker, type) => {
  return function* () {
    while (true) {
      const action = yield take(type);
      yield fork(worker, action);
    }
  };
};

function* getLiquidityWorker(payload) {
  try {
    yield put(
      updateCallStatus({
        currentCall: "GET_LIQUIDITY",
        callStatus: ActivityStatus.INITIATED,
      })
    );
    let poolInfo = yield call(getPoolsItems);
    if (poolInfo && !_.isEqual(poolInfo, {})) {
      yield put(
        updateCallStatus({
          currentCall: "GET_LIQUIDITY",
          callStatus: ActivityStatus.COMPLETED,
          data: { poolInfo },
        })
      );
    } else {
      yield put(
        updateCallStatus({
          currentCall: "GET_LIQUIDITY",
          callStatus: ActivityStatus.FAILED,
        })
      );
    }
  } catch (e) {
    yield put(
      updateCallStatus({
        currentCall: "GET_LIQUIDITY",
        callStatus: ActivityStatus.FAILED,
      })
    );
  } finally {
    yield put(
      updateCallStatus({
        currentCall: "GET_LIQUIDITY",
        callStatus: ActivityStatus.NOT_INITIATED,
      })
    );
  }
}

export const getLiquidityWatcher = createWatcher(
  getLiquidityWorker,
  GET_LIQUIDITY
);

function* getPoolsWorker(payload) {
  // Temperory fix to avoid pools page crash
  const { graph } = yield select((state) => state.Pools);

  // Temperory fix to avoid pools page crash
  if (!graph) {
    yield put(
      updateCallStatus({
        currentCall: "SET_GRAPH",
        callStatus: ActivityStatus.COMPLETED,
        data: defaultGraphData,
      })
    );
  }
}

export const getPoolsWatcher = createWatcher(getPoolsWorker, SET_POOL);

// modified SET_GRAPH to call setUSDCGraphWorker instead of getGraphWorker
export const getGraphWatcher = createWatcher(getUSDCGraphWorker, SET_GRAPH);

export const addTransactionWatcher = createWatcher(
  addTransactionWorker,
  SUBMIT_TRANSACTION
);

function* addTransactionWorker(payload) {
  const txnIndex = yield select(getTxnIndex);

  yield put(
    addNewTransaction({
      currentCall: payload.params.currentCall,
      callStatus: ActivityStatus.INITIATED,
      token: payload.params.poolType,
      amount: payload.params.amount,
      data: { depositTx: null },
      id: txnIndex,
    })
  );

  if (!payload.params.disableModal) {
    yield put(setTransactionModal(true));
  }

  const { walletBalanceInfo } = yield select((state) => state.Wallet);
  payload.params.ownerAddress = walletBalanceInfo.address;

  try {
    const { receipt } = yield call(deployTransaction, payload.params);

    yield delay(10);
    yield put(
      updateTransactionStatus({
        currentCall: payload.params.currentCall,
        callStatus: ActivityStatus.IN_PROGRESS,
        token: payload.params.poolType,
        amount: payload.params.amount,
        data: { depositTx: receipt.hash },
        id: txnIndex,
      })
    );
    yield delay(10);
    const confirmation = yield call(receipt.wait);
    if (confirmation.status === 0) {
      yield delay(10);
      yield put(
        updateTransactionStatus({
          currentCall: payload.params.currentCall,
          callStatus: ActivityStatus.FAILED,
          token: payload.params.poolType,
          amount: payload.params.amount,
          data: { depositTx: receipt.hash },
          id: txnIndex,
        })
      );
      yield delay(10);
    } else if (confirmation.status === 1) {
      yield delay(10);
      yield put(
        updateTransactionStatus({
          currentCall: payload.params.currentCall,
          callStatus: ActivityStatus.COMPLETED,
          token: payload.params.poolType,
          amount: payload.params.amount,
          data: { depositTx: receipt.hash },
          id: txnIndex,
        })
      );
      yield delay(10);
      // Update balance here as transaction was successfully mined hence balance would have changed
      yield put(refreshWalletInfo(yield call(getAssets)));
      yield put(refreshPoolInfo({ poolInfo: yield call(getPoolsItems) }));
      yield put(getFarmData());
      yield put(getGovernanceData());
      yield put(setPoolData(true));
    }
  } catch (e) {
    console.log({ e });
    yield delay(10);
    yield put(
      updateTransactionStatus({
        currentCall: payload.params.currentCall,
        callStatus: ActivityStatus.FAILED,
        token: payload.params.poolType,
        amount: payload.params.amount,
        data: { depositTx: null },
        id: txnIndex,
      })
    );
    yield delay(10);
  }
}

function* getUSDCGraphWorker(payload) {
  const allIndicators = [
    {
      timeSeries: true,
      graphIndicator: "tvl",
      graphName: "graphTVLData",
      token: "LFI",
    },
    {
      timeSeries: false,
      graphIndicator: "Earnings7Day",
      graphName: "graphPNL7DaysData",
      token: "LFI",
    },
    {
      timeSeries: false,
      graphIndicator: "Earnings30Day",
      graphName: "graphPNL30DaysData",
      token: "LFI",
    },
    {
      timeSeries: true,
      graphIndicator: "liquidity",
      graphName: "graphLiquidityData",
      token: "LFI",
    },
    {
      timeSeries: true,
      graphIndicator: "tvl",
      graphName: "graphTVLData",
      token: "USDC",
    },
    {
      timeSeries: false,
      graphIndicator: "Earnings7Day",
      graphName: "graphPNL7DaysData",
      token: "USDC",
    },
    {
      timeSeries: false,
      graphIndicator: "Earnings30Day",
      graphName: "graphPNL30DaysData",
      token: "USDC",
    },
    {
      timeSeries: true,
      graphIndicator: "liquidity",
      graphName: "graphLiquidityData",
      token: "USDC",
    },
    {
      timeSeries: true,
      graphIndicator: "tvl",
      graphName: "graphTVLData",
      token: "WBTC",
    },
    {
      timeSeries: false,
      graphIndicator: "Earnings7Day",
      graphName: "graphPNL7DaysData",
      token: "WBTC",
    },
    {
      timeSeries: false,
      graphIndicator: "Earnings30Day",
      graphName: "graphPNL30DaysData",
      token: "WBTC",
    },
    {
      timeSeries: true,
      graphIndicator: "liquidity",
      graphName: "graphLiquidityData",
      token: "WBTC",
    },
    {
      timeSeries: true,
      graphIndicator: "tvl",
      graphName: "graphTVLData",
      token: "WETH",
    },
    {
      timeSeries: false,
      graphIndicator: "Earnings7Day",
      graphName: "graphPNL7DaysData",
      token: "WETH",
    },
    {
      timeSeries: false,
      graphIndicator: "Earnings30Day",
      graphName: "graphPNL30DaysData",
      token: "WETH",
    },
    {
      timeSeries: true,
      graphIndicator: "liquidity",
      graphName: "graphLiquidityData",
      token: "WETH",
    },
    // {
    //   timeSeries: true,
    //   graphIndicator: 'me',
    //   graphName: 'graphMEData',
    //   token: 'LFI',
    // }
    // ,
    {
      timeSeries: true,
      graphIndicator: 'PendingStakes',
      graphName: 'graphPendingStakesData',
      token: 'LFI',
    },
    {
      timeSeries: true,
      graphIndicator: "me",
      graphName: "graphMEData",
      token: "USDC",
    },
    {
      timeSeries: true,
      graphIndicator: "PendingStakes",
      graphName: "graphPendingStakesData",
      token: "USDC",
    },
    {
      timeSeries: true,
      graphIndicator: "me",
      graphName: "graphMEData",
      token: "WBTC",
    },
    {
      timeSeries: true,
      graphIndicator: "PendingStakes",
      graphName: "graphPendingStakesData",
      token: "WBTC",
    },
    {
      timeSeries: true,
      graphIndicator: "me",
      graphName: "graphMEData",
      token: "WETH",
    },
    {
      timeSeries: true,
      graphIndicator: "PendingStakes",
      graphName: "graphPendingStakesData",
      token: "WETH",
    },
  ];
  // Loop to get one indicator data at a time.
  // State is updated for each indicator
  // this ensures the graph for each indicator is displayed as soon
  // as the data is available, without waiting for all indicators to be fetched
  for (let i = 0; i < allIndicators.length; i++) {
    const graphDataPoints = yield call(
      getPoolGraphData,
      allIndicators[i].token,
      allIndicators[i].graphIndicator,
      allIndicators[i].timeSeries
    );
    if (graphDataPoints) {
      yield put(
        setUSDCGraph({
          data: {
            graphToken: allIndicators[i].token,
            graphDataPoints,
            graphIndicator: allIndicators[i].graphName,
          },
        })
      );
    }
  }
}

export const getUSDCGraphWatcher = createWatcher(
  getUSDCGraphWorker,
  USDC_GRAPH
);

function* getGlobalValuesWorker(payload) {
  const globalValues = yield call(getGlobalValues, payload && payload.refresh);
  if (globalValues) {
    yield put(
      _setGlobalValues({
        data: { globalValues },
      })
    );
  }
}

export const getGlobalValuesWatcher = createWatcher(
  getGlobalValuesWorker,
  GET_GLOBAL_VALUES
);


function* getVolumeWorker(payload) {
  const volume = yield call(getVolume)
  if (volume) {
    yield put(
      _setVolume({
        data: volume,
      })
    );
  }
}

export const getVolumeWatcher = createWatcher(getVolumeWorker, GET_VOLUME);

function* getSportsbookFundsWorker(payload) {
  const funds = yield call(getSportsbookFunds)
  if (funds) {
    yield put(
      _setSportsbookFunds({
        data: funds,
      })
    );
  }
}

export const getSportsbookFundsWatcher = createWatcher(
  getSportsbookFundsWorker,
  GET_SPORTSBOOK_FUNDS
)


const getTxnIndex = (state) => state.Pools.txnIndex;


export default PoolsSaga;
