import { POOL_INVEST, POOL_APPROVE, POOL_WITHDRAW, POOL_HARVEST } from '../store/pools/actionTypes';
import { FARM_INVEST, FARM_APPROVE, FARM_WITHDRAW, FARM_HARVEST, FARM_WITHDRAW_AND_HARVEST, FARM_HARVEST_ALL } from '../store/farms/actionTypes';
import { GOVERNANCE_APPROVE, GOVERNANCE_INVEST, GOVERNANCE_WITHDRAW, GOVERNANCE_CLAIM } from '../store/governance/actionTypes';

const transactionInfo = (callStatus, token) => {
    let msg = ''
    switch (callStatus) {
        case POOL_HARVEST:
            msg = 'Claiming LFI rewards from ' + token + ' House Pool'
            break;
        case POOL_INVEST:
            msg = 'Deposit to ' + token + ' House Pool'
            break;
        case POOL_WITHDRAW:
            msg = 'Withdraw from ' + token + ' House Pool'
            break;
        case POOL_APPROVE:
            msg = 'Approve ' + token + ' for House Pool'
            break;
        case FARM_INVEST:
            msg = 'Deposit to ' + token + ' Farm'
            break;
        case FARM_APPROVE:
            msg = 'Approve ' + token + ' Farm'
            break;
        case FARM_WITHDRAW:
            msg = 'Withdraw from ' + token + ' Farm'
            break;
        case FARM_HARVEST:
            msg = 'Harvest LFI from ' + token + ' Farm'
            break;
        case FARM_HARVEST_ALL:
            msg = 'Harvesting all LFI'
            break;
        case FARM_WITHDRAW_AND_HARVEST:
            msg = 'Withdraw And Harvest from ' + token + ' Farm'
            break;
        case GOVERNANCE_CLAIM:
            msg = 'Claiming ' + token
            break;
        case GOVERNANCE_INVEST:
            msg = 'Deposit to ' + token + ' Vault'
            break;
        case GOVERNANCE_WITHDRAW:
            msg = 'Withdraw from ' + token + ' Vault'
            break;
        case GOVERNANCE_APPROVE:
            msg = 'Approve ' + token + ' Vault'
            break;
        default:
            msg = 'Transaction Deployed'
    }
    return msg
}

export default transactionInfo