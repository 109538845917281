import Box from '@mui/material/Box';
import { useSelector } from 'react-redux'
import { lightColors, darkColors, baseColors, gray } from '../../theme/colors'

export default function Typo(props: any) {
    const { darkMode } = useSelector((state: any) => state.Modals)
    const getFontColor = () => {
        if (props.black) {
            return baseColors.black
        } else if (props.white) {
            return baseColors.white
        } else if (props.aqua) {
            return baseColors.aqua
        } else if (props.success) {
            return baseColors.success
        } else if (props.error) {
            return baseColors.error
        } else if (props.even) {
            return baseColors.even
        } else if (props.grey) {
            return baseColors.grey
        } else if (props.g600) {
            return gray.g600
        } else if (props.positive) {
            return baseColors.positive
        } else if (props.blue) {
            return baseColors.blue
        } else if (props.accessible) {
            return baseColors.accessible
        } else if (props.primary) {
            return darkMode ? darkColors.textPrimary : lightColors.textPrimary
        } else if (props.secondary) {
            return darkMode ? darkColors.textSecondary : lightColors.textSecondary
        } else {
            return darkMode ? darkColors.textPrimary : lightColors.textPrimary
        }
    }

    const getFontSize = () => {
        if (props.headline800) {
            return { xl: '36px', lg: '32px', md: '32px', sm: '32px', xs: '30px' }
        } else if (props.headline700) {
            return { xl: '28px', lg: '28px', md: '28px', sm: '28px', xs: '20px' }
        } else if (props.headline600) {
            return { xl: '20px', lg: '20px', md: '20px', sm: '20px', xs: '16px' }
        } else if (props.cardHeadline500) {
            return { xl: '18px', lg: '18px', md: '18px', sm: '18px', xs: '16px' }
        } else if (props.headline500) {
            return { xl: '18px', lg: '18px', md: '18px', sm: '18px', xs: '14px' }
        } else if (props.headline400) {
            return { xl: '16px', lg: '16px', md: '16px', sm: '16px', xs: '14px' }
        } else if (props.headline300) {
            return { xl: '16px', lg: '14px', md: '14px', sm: '14px', xs: '12px' }
        } else if (props.headline200) {
            return { xl: '14px', lg: '14px', md: '14px', sm: '14px', xs: '12px' }
        } else if (props.headline100) {
            return { xl: '12px', lg: '12px', md: '12px', sm: '12px', xs: '10px' }
        } else if (props.body300) {
            return { xl: '16px', lg: '16px', md: '16px', sm: '16px', xs: '16px' }
        } else if (props.body200) {
            return { xl: '14px', lg: '14px', md: '14px', sm: '14px', xs: '14px' }
        } else if (props.body100) {
            return { xl: '12px', lg: '12px', md: '12px', sm: '12px', xs: '12px' }
        } else if (props.CTALg) {
            return '26px'
        } else if (props.CTAmd) {
            return '24px'
        } else if (props.CTAsm) {
            return '22px'
        } else if (props.nav) {
            return '14px'
        } else {
            return '16px'
        }
    }

    const getFontWeight = () => {
        if (props.headline800) {
            return '600'
        } else if (props.headline700) {
            return '600'
        } else if (props.headline600) {
            return '600'
        } else if (props.cardHeadline500) {
            return '400'
        } else if (props.headline500) {
            return '600'
        } else if (props.headline400) {
            return '500'
        } else if (props.headline300) {
            return '500'
        } else if (props.headline200) {
            return '600'
        } else if (props.headline100) {
            return '500'
        } else if (props.body300) {
            return '400'
        } else if (props.body200) {
            return '400'
        } else if (props.body100) {
            return '400'
        } else if (props.CTALg) {
            return '26px'
        } else if (props.CTAmd) {
            return '500'
        } else if (props.CTAsm) {
            return '500'
        } else if (props.nav) {
            return '700'
        } else {
            return '400'
        }
    }

    const getLineHeight = () => {
        if (props.headline800) {
            return { xl: '48px', lg: '44px', md: '44px', sm: '44px', xs: '42px' }
        } else if (props.headline700) {
            return { xl: '42px', lg: '42px', md: '42px', sm: '42px', xs: '30px' }
        } else if (props.headline600) {
            return { xl: '24px', lg: '28px', md: '28px', sm: '28px', xs: '24px' }
        } else if (props.cardHeadline500) {
            return { xl: '18px', lg: '24px', md: '24px', sm: '24px', xs: '24px' }
        } else if (props.headline500) {
            return { xl: '24px', lg: '24px', md: '24px', sm: '24px', xs: '22px' }
        } else if (props.headline400) {
            return { xl: '18px', lg: '24px', md: '24px', sm: '24px', xs: '22px' }
        } else if (props.headline300) {
            return { xl: '24px', lg: '22px', md: '22px', sm: '22px', xs: '20px' }
        } else if (props.headline200) {
            return { xl: '22px', lg: '22px', md: '22px', sm: '22px', xs: '20px' }
        } else if (props.headline100) {
            return { xl: '20px', lg: '20px', md: '20px', sm: '20px', xs: '18px' }
        } else if (props.body300) {
            return { xl: '24px', lg: '24px', md: '24px', sm: '24px', xs: '24px' }
        } else if (props.body200) {
            return { xl: '22px', lg: '22px', md: '22px', sm: '22px', xs: '22px' }
        } else if (props.body100) {
            return { xl: '20px', lg: '20px', md: '20px', sm: '20px', xs: '20px' }
        } else if (props.CTALg) {
            return '26px'
        } else if (props.CTAmd) {
            return '24px'
        } else if (props.CTAsm) {
            return '22px'
        } else if (props.nav) {
            return '20px'
        } else {
            return '16px'
        }
    }

    const getFontFamily = () => {
        if (props.cardHeadline500 || props.nav) {
            return 'NB Architekt R'
        } else {
            return 'Inter'
        }
    }

    const sx = props.sx ? props.sx : {}

    const typoStyle = {
        fontSize: getFontSize(),
        color: getFontColor(),
        fontWeight: getFontWeight(),
        lineHeight: getLineHeight(),
        display: 'inline-block',
        overflowWrap: "break-word",
        fontFamily: getFontFamily(),
    } as const

    const style = { ...typoStyle, ...sx }

    return (
        <Box sx={style} onClick={props.onClick}>
            {props.children}
        </Box>
    );
}
