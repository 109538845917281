enum ActivityStatus {
    /**
     * This has not been initiated yet or user has not clicked the button
     */
    NOT_INITIATED = 'NOT_INITIATED',
  
    /**
     * User has clicked the button, or we have started the call process.
     */
    INITIATED = 'INITIATED',
  
    /**
     * User has confirmed the transaction on the wallet.
     * For non payable calls, the call transaction has been sent to the blockchain
     * for API calls, the request has been sent to the API
     */
     IN_PROGRESS = 'IN_PROGRESS',

    /**
     * Call, API call or transaction completed successfully.
     */
    COMPLETED = 'COMPLETED',

    /**
     * Operation failed.
     */
     FAILED = 'FAILED',
  }
  
  export default ActivityStatus
  